import {
    ACL_USERS,
    ACL_USERS_DATA,
    ACL_USERS_UPDATE,
    ACL_USERS_ADD,
    ACL_USERS_DELETE,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/util';

const INIT_STATE = {
    data: []
}

const aclUsers = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ACL_USERS:
        case ACL_USERS_UPDATE:
        case ACL_USERS_ADD:
        case ACL_USERS_DELETE:
            return { ...state }
        case ACL_USERS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data'])
            }
    }

    return { ...state }
}

export default aclUsers