import moment from 'moment';
import queryString from 'query-string'
import {
    allRoutes, authProtectedRoutes
} from '../../routes'
import axios from 'axios'

const DEFAULT_ERROR_MSG = 'There\'s a problem processing your request, please try again later.';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            return response.json().then(data => {
                if (response.ok) {
                    return data;
                } else {
                    return Promise.reject({status: response.status, data});
                }
            });
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            throw error;
        });
}

const axiosJSON = (url, options = {}) => {
    return axios.get(url, options)
        .then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        })
}

// const getCsrf = () => {
//     return document.querySelector("meta[name='csrf-token']").getAttribute("content");
// };
//
// const setCsrf = (csrf) => {
//     return document.querySelector("meta[name='csrf-token']").setAttribute("content", csrf);
// };

const formatFormData = (data) => {
    return  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const formArgsExcludeEmpty = (args) => {
    Object.keys(args).map((key) => {
        if (args[key] == null || (args[key] != null && args[key].length == 0)) {
            delete args[key];
        }
    });

    return args;
}

const getPropTraverse = (data, props) => {
    if (typeof(data) == 'object' && data != null && Array.isArray(props) && props.length > 0) {
        if (props.length > 1) {
            var propKey = props[0];
            if (data.hasOwnProperty(propKey)) {
                props.shift();
                return getPropTraverse(data[propKey], props);
            } else {
                return null;
            }
        } else {
            return (data.hasOwnProperty(props[0])) ? data[props[0]] : null;
        }
    }
    return null;
};

const defaultGetOptions = () => {
    return {
        method: 'GET',
        headers: {
            'Content-Type'     : 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt'),
        },
        credentials: 'same-origin',
    };
}

const defaultPostBloblOptions = () => {
    return {
        method: 'POST',
        headers: new Headers({
            'Content-Type'     : 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
        credentials: 'same-origin',
        responseType: 'blob',
    };
}

const defaultPostOptions = () => {
    return {
        method: 'POST',
        headers: new Headers({
            'Content-Type'     : 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
        credentials: 'same-origin',
    };
};

const defaultPutOptions = () => {
    return {
        method: 'PUT',
        headers: new Headers({
            'Content-Type'     : 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
        credentials: 'same-origin',
    };
};

const defaultDeleteOptions = () => {
    return {
        method: 'DELETE',
        headers: new Headers({
            'Content-Type'     : 'application/x-www-form-urlencoded',
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
        credentials: 'same-origin',
    };
};

const mutateToNewObject = (data) => {
    let newData = data;

    try {
        newData = JSON.parse(JSON.stringify(data));
    } catch (e) {
        newData = data
    }

    return newData;
};

function * foreachGenerator (arr, fn) {
    var i;
    for (i = 0; i < arr.length; i++) {
        yield * fn(arr[i])
    }
};

function timeFormat(pTime, pFormatTime) {
    let hours   = '00';
    let minutes = '00';
    let formatTime = 'h:mm A';
    let formattedTime = null;

    if (typeof pTime.hours != 'undefined' && pTime.minutes != 'undefined') {
        hours   = (typeof pTime.hours != 'undefined') ? pTime.hours : hours;
        minutes = (typeof pTime.minutes != 'undefined') ? pTime.minutes : minutes;

        formatTime    = (typeof pFormatTime == 'undefined') ? formatTime : pFormatTime;
        formattedTime = moment(hours + ':' + minutes, 'HH:mm').format(formatTime);
    }

    return formattedTime;
}

function dateFormat(pDate, pFormatDate) {
    let formatDate = (typeof pFormatDate == 'undefined') ? 'MMM D, YYYY' : pFormatDate

    return (typeof pDate.month != 'undefined' && pDate.day != 'undefined' && pDate.year != 'undefined')
        ? moment(pDate.month + '-' + pDate.day +  '-' + pDate.year).format(formatDate)
        : moment(pDate).format(formatDate)
}

function snakeToWords(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, ' ').toLowerCase();
}

function objToUrlParams(obj) {
    return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&')
}

function buildUrlParams(options) {
    return (typeof options != 'undefined') ? '?' + objToUrlParams(options) : '';
}

function numberFormatComma(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function urlBaseDomainOnly(str) {
    return str.replace('www.', '');
}

function urlAppendProtocol(link) {
    return ((link.indexOf('://') === -1) && (link.indexOf('mailto:') === -1) ) ? 'http://' + link : link
}

function pluckRouteFromName(routeName) {
    const arrRoute = [...allRoutes]
    const routes = arrRoute.find(obj => obj.name === routeName);

    if (typeof routes != 'undefined') {
        return routes.path
    }
    return '#'
}

function pluckRouteFromPath(routeName) {
    const arrRoute = [...allRoutes]
    const routes = arrRoute.find(obj => obj.path === routeName);

    if (typeof routes != 'undefined') {
        return routes.path
    }
    return '#'
}

function pluckGatedRouteFromName(routeName) {
    const arrRoute = [...authProtectedRoutes]
    const routes = arrRoute.find(obj => obj.name === routeName);

    if (typeof routes != 'undefined') {
        return routes.path
    }
    return '#'
}

function pluckGatedRouteFromPath(routePath) {
    const arrRoute = [...authProtectedRoutes]
    const routes = arrRoute.find(obj => obj.path === routePath);

    if (typeof routes != 'undefined') {
        return routes.path
    }
    return '#'
}

function parseStringVariable(str) {
    let args = [].slice.call(arguments, 1),
        i = 0;

    return str.replace(/%s/g, () => args[i++]);
}

function ddlbParser(obj, needle) {
    let ddlbObj = []

    Object.keys(obj).map((key) => {
        ddlbObj.push({
            label: obj[key][needle.label],
            value: obj[key][needle.value],
        })
    });

    return ddlbObj;
}

function formatFormDataArray(haystack, needle) {
    let arr = []

    if (typeof haystack != 'undefined' && haystack != null) {
        Object.entries(haystack).map(([objKey, objValue]) => {
            arr.push(objValue[needle])
        })

        arr = JSON.stringify(arr)
    }

    return arr;
}

function getUrlQuery() {
    return queryString.parse(window.location.search)
}

function generateHexColor(stringValue, withHash) {
    const hash = (typeof withHash == 'undefined') ? '#' : withHash

    function hashCode(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    function intToRGB(i){
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    return hash + intToRGB(hashCode(stringValue))
}

function getUrlPathParameter(idx) {
    const args = window.location.pathname.split('/')

    const filtered = args.filter(function (el) {
        return el != ''
    })

    if (typeof idx == 'undefined') {
        return filtered
    } else {
        return filtered[idx]
    }
}

/**
 *
 * @param dividend (remaining)
 * @param divisor (total)
 */
function toPercentage(dividend, divisor) {
    return (dividend / divisor) * 100
}

function titleCase(string) {
    return string.replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase())
}

export {
    DEFAULT_ERROR_MSG,
    fetchJSON,
    axiosJSON,
    formatFormData,
    getPropTraverse,
    defaultPutOptions,
    defaultPostOptions,
    defaultGetOptions,
    defaultPostBloblOptions,
    defaultDeleteOptions,
    mutateToNewObject,
    foreachGenerator,
    timeFormat,
    dateFormat,
    snakeToWords,
    objToUrlParams,
    buildUrlParams,
    formArgsExcludeEmpty,
    numberFormatComma,
    urlBaseDomainOnly,
    urlAppendProtocol,
    pluckRouteFromName,
    pluckRouteFromPath,
    pluckGatedRouteFromName,
    pluckGatedRouteFromPath,
    parseStringVariable,
    ddlbParser,
    formatFormDataArray,
    getUrlQuery,
    generateHexColor,
    getUrlPathParameter,
    toPercentage,
    titleCase,
};