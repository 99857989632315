import { takeEvery, put, call } from 'redux-saga/effects'

import {
    formatFormData,
    defaultPutOptions,
    fetchJSON,
    getPropTraverse,
} from 'app.cms.react/helpers/util';

import {
    PASSWORD_RESET,
} from './actionTypes'

import {
    AJAX_URL_PASSWORD_RESET
} from 'app.cms.react/store/actionTypes'
import {
    componentFormOnSubmitDoneAction,
} from '../../../../store/actions'

function* passwordReset({ payload: { params, meta } }) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        password: params.password,
        password_confirmation: params.passwordConfirmation,
        token: params.token,
        email: params.email,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_PASSWORD_RESET, options);
        const statusCode = getPropTraverse(response, ['statusCode']);

        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* PasswordResetSaga() {
    yield takeEvery(PASSWORD_RESET, passwordReset)
}

export default PasswordResetSaga