import {
    JWT_SESSION,
    JWT_SESSION_EXPIRED,
} from './actionTypes'

const INIT_STATE = {
    isExpired: false,
    alertType: '',
    response: '',
}

const jwtSession = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case JWT_SESSION:
            return { ...state }
        case JWT_SESSION_EXPIRED:
            return {
                ...state,
                isExpired: true,
                alertType: 'danger',
                response: 'Your session has expired or no longer active. For security purposes, you have been logged out. Please re-login to your account to continue.'
            }
    }

    return { ...state }
}

export default jwtSession;