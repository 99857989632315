import {
    SUBSCRIPTION_MODULE,
    SUBSCRIPTION_MODULE_DATA,
} from './actionTypes'

export const subscriptionModuleAction = (params) => ({
    type: SUBSCRIPTION_MODULE,
    payload: { params }
})

export const subscriptionModuleDataAction = (data) => ({
    type: SUBSCRIPTION_MODULE_DATA,
    payload: { data },
})