import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReactApexChart from "react-apexcharts"
import ReportIcon from '../../../../components/Common/ReportIcon'
import PieChart   from '../../../../components/Common/Charts/Pie'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class PageLoadSpeed extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []

        this.state = {
            sectionData       : sectionData,
            desktopSpeedScore : getPropTraverse(sectionData, ['pageLoadSpeedDesktop', 'rule_groups', 'speed', 'score']) || 0,
            mobileSpeedScore  : getPropTraverse(sectionData, ['pageLoadSpeedMobile', 'rule_groups', 'speed', 'score']) || 0,
            desktopStatus     : 'warning',
            desktopMessage    : 'Error: An error occurred while calling the endpoint service',
            mobileStatus      : 'warning',
            mobileMessage     : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let desktopStatus  = 'danger'
            let mobileStatus   = 'danger'
            let desktopMessage = 'pageLoadSpeedDesktopDanger'
            let mobileMessage  = 'pageLoadSpeedMobileDanger'

            if (this.state.desktopSpeedScore >= 90) {
                desktopStatus  = 'success'
                desktopMessage = 'pageLoadSpeedDesktopGood'
            } else if (this.state.desktopSpeedScore < 90 && this.state.desktopSpeedScore >= 50) {
                desktopStatus  = 'warning'
                desktopMessage = 'pageLoadSpeedDesktopWarning'
            }

            if (this.state.mobileSpeedScore >= 90) {
                mobileStatus  = 'success'
                mobileMessage = 'pageLoadSpeedMobileGood'
            } else if (this.state.mobileSpeedScore < 90 && this.state.mobileSpeedScore >= 50) {
                mobileStatus  = 'warning'
                mobileMessage = 'pageLoadSpeedMobileWarning'
            }

            this.setState({
                desktopStatus  : desktopStatus,
                desktopMessage : desktopMessage,
                mobileStatus   : mobileStatus,
                mobileMessage  : mobileMessage
            })

            if (typeof reportSummary[desktopStatus] == 'undefined') {
                reportSummary[desktopStatus] = { page_load_speed_desktop : desktopMessage }
            } else {
                reportSummary[desktopStatus]['page_load_speed_desktop'] = desktopMessage
            }

            if (typeof reportSummary[mobileStatus] == 'undefined') {
                reportSummary[mobileStatus] = { page_load_speed_desktop : mobileMessage }
            } else {
                reportSummary[mobileStatus]['page_load_speed_desktop'] = mobileMessage
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="pageLoadSpeedSection" className='mt-4'>
                    <h4>{ this.props.t('Page Load Speed') }</h4>
                    <p>{ this.props.t('pageLoadSpeedDescription') }</p>

                    <div className='text-center mx-4'>
                        <h6>Scale: <span className='text-success'>{ this.props.t('90-100 (fast)') }</span> <span className='text-warning'>{ this.props.t('50-89 (average)') }</span> <span className='text-danger'>{ this.props.t('0-49 (slow)') }</span></h6>
                    </div>
                    <div className="pagespeed-chart-container">
                        <Row>
                            <Col xs={6}>
                                <div className='text-center'>
                                    <PieChart score={this.state.desktopSpeedScore} title={ this.props.t('Desktop Speed') } animation={ false } />
                                    <p>{ this.props.t('Desktop Speed') }</p>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='text-center'>
                                    <PieChart score={this.state.mobileSpeedScore} title={ this.props.t('Mobile Speed') } animation={ false } />
                                    <p>{ this.props.t('Mobile Speed') }</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.desktopStatus } /></th>
                                    <td>{ this.props.t(this.state.desktopMessage) }</td>
                                </tr>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.mobileStatus } /></th>
                                    <td>{ this.props.t(this.state.mobileMessage) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    { (this.state.desktopStatus != 'success' || this.state.mobileStatus != 'success') && (
                        <React.Fragment>
                            <h4 className="my-3">{ this.props.t('pageLoadSpeedPossibilities') }</h4>
                            <ul>
                                <li>Serve Images in next-gen formats</li>
                                <li>Reduce initial server response time</li>
                                <li>Defer offscreen images</li>
                                <li>Eliminate render-blocking resources</li>
                                <li>Remove unused JavaScript</li>
                                <li>Remove unused CSS</li>
                                <li>Efficiently encode images</li>
                                <li>Avoid service legacy JavaScript to modern browsers</li>
                            </ul>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(PageLoadSpeed));
