import React, {Component}      from 'react'
import { Provider } from "react-redux"
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Cover                   from '../../components/Report/Sections/Cover'
import IndexingAndCrawlability from '../../components/Report/Sections/IndexingAndCrawlability/Main'
import store                   from "../../store"
import { withTranslation } from "react-i18next"

import coverImage from '../../assets/images/report/cover.jpg'

// Create styles
const styles = StyleSheet.create({
  page: {
      margin:0,
      padding:0,
      position:'relative'
  },
  section: {
      padding: 30,
  },
  image: {
      width:'100%',
      marginVertical: 15,
      marginHorizontal: 100,
  },
  cover: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
  }
});

const ReportViewDoc = () => (
    <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.page}>
              <Image
                    style={styles.cover}
                    src={coverImage}
                    alt="Cover"
               />
               <Text>SEO Site Audit Report</Text>
          </Page>
        </Document>
    </Provider>
)

export default withTranslation()(ReportViewDoc)
