import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Alert} from 'reactstrap'

const FormAlertNotification = props => {
    let alertMessage = []

    if (props.response != null && typeof props.response == 'object') {
        Object.entries(props.response).map(([objKey, objValue]) => {
            if (typeof objValue == 'object' && Array.isArray(objValue) == false) {
                Object.entries(objValue).map(([key, value]) => {
                    Object.entries(value).map(([k, v]) => {
                        alertMessage.push(<li key={key + '-' + k}>{ v }</li>)
                    })
                })
            } else if (typeof objValue == 'object' && Array.isArray(objValue) == true) {
                Object.entries(objValue).map(([key, value]) => {
                    alertMessage.push(<li key={value}>{ value }</li>)
                })
            } else {
                alertMessage = objValue
            }
        })
    } else {
        alertMessage = props.response
    }

    switch (props.alertType) {
        case 'danger':
            return (
                <Alert color={props.alertType} className="fade show" role="alert">
                    <i className="mdi mdi-block-helper me-2"></i>
                    {typeof props.response == 'object' ? (
                        <React.Fragment>
                            Errors found:
                            <ul className="m-0">{ alertMessage }</ul>
                        </React.Fragment>
                    ) : <React.Fragment>{ alertMessage }</React.Fragment> }
                </Alert>
            )
        case 'success':
            return (
                <Alert color={props.alertType} className="fade show" role="alert">
                    <i className="mdi mdi-check-all me-2"></i>{ alertMessage }
                </Alert>
            )
        case 'warning':
            return (
                <Alert color={props.alertType} className="fade show" role="alert">
                    <i className="mdi mdi-alert-outline me-2"></i>{ alertMessage }
                </Alert>
            )
        case 'info':
            return (
                <Alert color={props.alertType} className="fade show" role="alert">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>{ alertMessage }
                </Alert>
            )
    }

    return ''
}

const FormAlertInline = props => {
    let alertMessage = []

    if (typeof props.response == 'object') {
        Object.entries(props.response).map(([objKey, objValue]) => {
            Object.entries(objValue[props.field]).map(([key, value]) => {
                alertMessage.push(<li key={key}>{ value }</li>)
            })
        })
    } else {
        alertMessage = props.response
    }


    switch (props.alertType) {
        case 'danger':
            return (
                <div className="invalid-feedback">
                    { alertMessage }
                </div>
            )
    }

    return ''
}

const ElFormAlert = props => {
    switch (props.validationType) {
        case 'notification':
            return <FormAlertNotification {...props} />
        case 'inline':
            return <FormAlertInline {...props} />
    }

    return ''
}

class FormAlert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <ElFormAlert {...this.props} />
            </React.Fragment>
        )
    }
}

FormAlert.propTypes = {
    alertType: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(FormAlert)