import {
    SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE,
    SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_DATA
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/util'

const INIT_STATE = {
    data: [],
    activeSubscriptions: [],
    isDataTableLoading: false,
}

const subscriptionPlanStripe = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE:
            return {
                ...state,
                isDataTableLoading: true,
            }
        case SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_DATA:
            let arrData = getPropTraverse(payload, ['data'])

            Object.entries(arrData).map(([key, value]) => {
                if (value.is_subscribed) {
                    state.activeSubscriptions.push(value)
                }
            })

            if (getPropTraverse(payload, ['attributes', 'asRecurringOrder'])) {
                arrData = renderAsRecurring(payload)
            }

            return {
                ...state,
                data: arrData,
                isDataTableLoading: false,
            }

    }

    return { ...state }
}

const renderAsRecurring = (payload) => {
    const arrData = getPropTraverse(payload, ['data'])
    let dataMonthly = [],
        dataYearly = []

    Object.entries(arrData).map(([key, value]) => {
        if (value.recurring != null) {
            switch (value.recurring.interval) {
                case 'month':
                    dataMonthly.push(value)
                    break
                default:
                    dataYearly.push(value)
                    break
            }
        }
    })

    return {
        month: dataMonthly,
        year: dataYearly,
    }
}

export default subscriptionPlanStripe