import {
    COMPONENT_MODAL_INIT,
    COMPONENT_MODAL_TOGGLE,
} from './actionTypes'

export const componentModalInitAction = (params) => ({
    type: COMPONENT_MODAL_INIT,
    payload: { params }
})

export const componentModalToggleAction = (params) => ({
    type: COMPONENT_MODAL_TOGGLE,
    payload: params
})