import { takeEvery, put, call } from 'redux-saga/effects'

import {
    formatFormData,
    defaultPostOptions,
    fetchJSON,
    getPropTraverse,
    pluckRouteFromName,
} from 'app.cms.react/helpers/util';

import {
    FORGOT_PASSWORD,
} from './actionTypes'

import {
    AJAX_URL_SEND_PASSWORD_RESET_LINK
} from 'app.cms.react/store/actionTypes'

import {
    componentFormOnSubmitDoneAction,
} from '../../../../store/actions';

function* forgotPassword({ payload: { params, history } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        email: params.email,
        password_reset_url: process.env.REACT_APP_BASE_URL + pluckRouteFromName('password-reset'),
    });

    try {
        const response = yield call(fetchJSON, AJAX_URL_SEND_PASSWORD_RESET_LINK, options);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* ForgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export default ForgotPasswordSaga