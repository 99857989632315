import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class GoogleAnalytics extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []

        this.state = {
            sectionData                 : sectionData,
            code                        : getPropTraverse(sectionData, ['googleAnalytics', 'code']) || null,
            status                      : 'warning',
            message                     : 'Error: An error occurred while calling the endpoint service',
            hasGoogleAnalyticsAccess    : (getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == true),
            hasGoogleAnalyticsInstalled : (getPropTraverse(this.props.reportSettings, ['googleAnalyticsInstalled']) == 'true' || getPropTraverse(this.props.reportSettings, ['googleAnalyticsInstalled']) == true)
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            const reportSettings = this.props.reportSettings;
            let gaIsInstalled = this.state.code;
            const hasGoogleAnalyticsAccess = getPropTraverse(reportSettings, ['hasGoogleAnalyticsAccess']) == 'true' || getPropTraverse(reportSettings, ['hasGoogleAnalyticsAccess']) == true
            let message = this.props.t('Needs installation and we need to be given full access to your Google Analytics account.')
            let status  = 'danger'

            if (typeof reportSettings.googleAnalyticsInstalled !== 'undefined') {
                gaIsInstalled = reportSettings.googleAnalyticsInstalled;
            }

            if (gaIsInstalled && hasGoogleAnalyticsAccess) {
                status  = 'success'
                message = this.props.t('Installed and we have been given full access to your Google Analytics account.')
            } else if (gaIsInstalled && !hasGoogleAnalyticsAccess) {
                status  = 'warning'
                message = this.props.t('Installed but we will require full access to your Google Analytics account.')
            }

            this.setState({
                status                   : status,
                message                  : message,
                hasGoogleAnalyticsAccess : reportSettings.hasGoogleAnalyticsAccess,
                hasGoogleAnalyticsInstalled: reportSettings.googleAnalyticsInstalled
            })

            // remove from summary first
            if (typeof reportSummary['success'] != 'undefined') {
                delete reportSummary['success']['google_analytics']
            }
            if (typeof reportSummary['warning'] != 'undefined') {
                delete reportSummary['warning']['google_analytics']
            }
            if (typeof reportSummary['danger'] != 'undefined') {
                delete reportSummary['danger']['google_analytics']
            }

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { google_analytics : message }
            } else {
                reportSummary[status]['google_analytics'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.reportSettings.hasGoogleAnalyticsAccess != this.state.hasGoogleAnalyticsAccess ||
            prevProps.reportSettings.googleAnalyticsInstalled != this.state.hasGoogleAnalyticsInstalled
        ) {
            this.updateReportSummary()
        }
    }

    render() {
        let status  = this.state.status
        let message = this.state.message

        return (
            <React.Fragment>
                <div id="googleAnalyticsSection" className='mt-4'>
                    <h4>{ this.props.t('Google Analytics') }</h4>
                    <p>Google Analytics (GA) tracks and reports website traffic. Integrating GA insights in our report will provide real-time, key website performance metrics like visitors, devices used in visiting your website (mobile or desktop), where users are geographically located, and from which traffic source they came from (paid ads or organic). The data will also be included in the monthly Executive Summary Report.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={status} /></th>
                                    <td>{message}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(GoogleAnalytics));
