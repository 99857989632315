import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import lcpImage from "../../../../assets/images/report/lcp-display.jpg"
import clsImage from "../../../../assets/images/report/cls-display.jpg"
import fidImage from "../../../../assets/images/report/fid-display.jpg"
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'
import { toNumber } from 'lodash';

class WebCoreVitals extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo', 'dataForSeoSpeedDesktop']) || false

        this.state = {
            sectionData                   : sectionData,
            cumulativeLayoutShift         : getPropTraverse(sectionData, ['cumulative_layout_shift', 'numeric_value']) || 0,
            cumulativeLayoutShiftDisplay  : getPropTraverse(sectionData, ['cumulative_layout_shift', 'display_value']) || 0,
            firstContentfulPaint          : getPropTraverse(sectionData, ['first_contentful_paint', 'numeric_value']) || 0,
            firstContentfulPaintDisplay   : getPropTraverse(sectionData, ['first_contentful_paint', 'display_value']) || 0,
            largestContentfulPaint        : getPropTraverse(sectionData, ['largest_contentful_paint', 'numeric_value']) || 0,
            largestContentfulPaintDisplay : getPropTraverse(sectionData, ['largest_contentful_paint', 'display_value']) || 0,
            lcpStatus                     : 'warning',
            lcpMessage                    : 'Error: An error occurred while calling the endpoint service',
            clsStatus                     : 'warning',
            clsMessage                    : 'Error: An error occurred while calling the endpoint service',
            fidStatus                     : 'warning',
            fidMessage                    : 'Error: An error occurred while calling the endpoint service',
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let lcpStatus  = 'danger'
            let clsStatus  = 'danger'
            let fidStatus  = 'danger'
            let lcpMessage = 'Not good! Your website’s LCP is at ' + (this.state.largestContentfulPaint / 1000).toFixed(1) + ' sec. The recommended LCP is between 0 to 2.5 seconds'
            let clsMessage = 'Not good! Your website’s CLS is at ' + toNumber(this.state.cumulativeLayoutShiftDisplay).toFixed(2) + '. The recommended CLS is between 0 to 2.1 seconds'
            let fidMessage = 'Not good! Your website’s TBT is at ' + Math.floor(this.state.firstContentfulPaint) + ' ms. The recommended TBT is between 0 to 100 milliseconds'

            if (this.state.largestContentfulPaint >= 0 && this.state.largestContentfulPaint <= 2500) {
                lcpStatus  = 'success'
                lcpMessage = 'Good job! Your website’s LCP is at ' + (this.state.largestContentfulPaint / 1000).toFixed(1) + ' sec. The recommended LCP is between 0 to 2.5 seconds'
            } else if (this.state.largestContentfulPaint > 2500 && this.state.largestContentfulPaint <= 4000) {
                lcpStatus  = 'warning'
                lcpMessage = 'Not bad! Your website’s LCP is at ' + (this.state.largestContentfulPaint / 1000).toFixed(1) + ' sec. The recommended LCP is between 0 to 2.5 seconds'
            }

            if (this.state.cumulativeLayoutShift >= 0 && this.state.cumulativeLayoutShift <= 0.1) {
                clsStatus  = 'success'
                clsMessage = 'Good job! Your website’s CLS is at ' + toNumber(this.state.cumulativeLayoutShiftDisplay).toFixed(2) + '. The recommended CLS is between 0 to 0.1'
            } else if (this.state.cumulativeLayoutShift > 0.1 && this.state.cumulativeLayoutShift <= 0.25) {
                clsStatus  = 'warning'
                clsMessage = 'Not bad! Your website’s CLS is at ' + toNumber(this.state.cumulativeLayoutShiftDisplay).toFixed(2) + '. The recommended CLS is between 0 to 0.1'
            }

            if (this.state.firstContentfulPaint >= 0 && this.state.firstContentfulPaint <= 100) {
                fidStatus  = 'success'
                fidMessage = 'Good job! Your website’s TBT is at ' + Math.floor(this.state.firstContentfulPaint) + ' ms. The recommended TBT is between 0 to 100 milliseconds'
            } else if (this.state.firstContentfulPaint > 100 && this.state.firstContentfulPaint <= 300) {
                fidStatus  = 'warning'
                fidMessage = 'Not bad! Your website’s TBT is at ' + Math.floor(this.state.firstContentfulPaint) + ' ms. The recommended TBT is between 0 to 100 milliseconds'
            }

            this.setState({
                lcpStatus   : lcpStatus,
                lcpMessage  : lcpMessage,
                clsStatus   : clsStatus,
                clsMessage  : clsMessage,
                fidStatus   : fidStatus,
                fidMessage  : fidMessage,
            })

            if (typeof reportSummary[lcpStatus] == 'undefined') {
                reportSummary[lcpStatus] = { lcp : lcpMessage }
            } else {
                reportSummary[lcpStatus]['lcp'] = lcpMessage
            }

            if (typeof reportSummary[clsStatus] == 'undefined') {
                reportSummary[clsStatus] = { cls : clsMessage }
            } else {
                reportSummary[clsStatus]['cls'] = clsMessage
            }

            if (typeof reportSummary[fidStatus] == 'undefined') {
                reportSummary[fidStatus] = { fid : fidMessage }
            } else {
                reportSummary[fidStatus]['fid'] = fidMessage
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="webCoreVitalsSection" className='mt-4'>
                    <h4>{ this.props.t('Web Core Vitals')}</h4>
                    <p>Core Web Vitals are three metrics that score a user's experience loading a webpage. These metrics are LCP, CLS, and FID. </p>

                    <h5>{ this.props.t('Largest Contentful Paint') }</h5>
                    <div className='text-center mb-3'>
                        <img
                          src={lcpImage}
                          alt="LCP Guide"
                          className="img-fluid"
                        />
                    </div>
                    <div className='p-3 mb-5'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.lcpStatus } /></th>
                                    <td>{ this.state.lcpMessage }</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <h5>{ this.props.t('Cumulative Layout Shift') }</h5>
                    <div className='text-center mb-3'>
                        <img
                          src={clsImage}
                          alt="CLS Guide"
                          className="img-fluid"
                        />
                    </div>
                    <div className='p-3 mb-5'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.clsStatus } /></th>
                                    <td>{ this.state.clsMessage }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5>{ this.props.t('First Input Delay or Total Blocking Time') }</h5>
                    <div className='text-center mb-3'>
                        <img
                          src={fidImage}
                          alt="FID Guide"
                          className="img-fluid"
                        />
                    </div>
                    <div className='p-3 mb-5'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.fidStatus } /></th>
                                    <td>{ this.state.fidMessage }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(WebCoreVitals))
