import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import FormAlert from '../../FormAlert'
import ButtonLoading from '../../ButtonLoading'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {formatFormDataArray} from 'app.cms.react/helpers/util'
import {aclRolesUpdateAction, componentFormInitAction, componentFormOnSubmitAction, componentModalToggleAction, componentDdlbMultiOnChangeAction} from 'app.cms.react/store/actions'


const animatedComponents = makeAnimated()

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalEditRole extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.toggleModal       = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleDdlbChange = (value) => {
        this.props.componentDdlbMultiOnChangeAction(value)
    };

    handleValidSubmit(event, values) {
        this.props.aclRolesUpdateAction({
            id: this.props.id,
            name: values.name,
            permissions: formatFormDataArray(this.props.ddlb, 'value'),
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'edit') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <ModalHeader>Edit</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <div className="mb-3">
                                <AvField
                                    label="Role Name"
                                    name="name"
                                    value={this.props.name}
                                    className="form-control"
                                    placeholder="Role Name"
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div>
                                <label>Permissions</label>
                                <Select
                                    name="permissions"
                                    value={this.props.ddlb}
                                    options={this.props.ddlbPermissions}
                                    onChange={this.handleDdlbChange}
                                    classNamePrefix="select2-selection"
                                    isClearable={true}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        ddlb
    } = state.ComponentsDdlb

    return {
        ddlb,
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclRolesUpdateAction,
    componentDdlbMultiOnChangeAction,
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalEditRole))