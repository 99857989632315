import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Card, CardBody, Col, Container, List, ListInlineItem, Row} from 'reactstrap'
import DataTable from '../../DataTable'
import {ddlbParser, getPropTraverse} from '../../../helpers/util'
import ModalAddUser from './ModalAddUser'
import ModalEditUser from './ModalEditUser'
import ModalDelete from '../../ModalDelete'
import {aclUsersAction, aclUsersDeleteAction, aclRolesAction, componentModalInitAction, componentModalToggleAction, componentDdlbOnChangeAction} from 'app.cms.react/store/actions'
import DataTableLoader from '../../DataTableLoader'

const ColFormatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <List type="inline" className="p-0 m-0">
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleEdit(row, e)
                }}><i className="bx bx bx-pencil text-primary" /></ListInlineItem>
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleDelete(row, e)
                }}><i className="bx bx-trash text-danger" /></ListInlineItem>
            </List>
        </React.Fragment>
    )
}

const ColFormatRole = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            { row.roles[0].name }
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'full_name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true,
    },
    {
        dataField: 'role',
        text: 'Role',
        sort: true,
        formatter: ColFormatRole,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        formatter: ColFormatActions,
        formatExtraData: {
            toggleEdit: props.toggleEdit,
            toggleDelete: props.toggleDelete,
        }
    },
];

class TableUsers extends Component {
    constructor(props) {
        super(props);

        this.props.aclUsersAction()
        this.props.aclRolesAction()

        this.toggleEdit = this.toggleEdit.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)

        this.state = {
            selected: {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                fullName: '',
                roles: [],
            }
        }
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
                email: (typeof row != 'undefined') ? row.email : '',
                firstName: (typeof row != 'undefined') ? row.first_name : '',
                lastName: (typeof row != 'undefined') ? row.last_name : '',
                fullName: (typeof row != 'undefined') ? row.full_name : '',
                roles: (typeof row != 'undefined') ? this.props.componentDdlbOnChangeAction(ddlbParser(row.roles, {
                    label: 'name',
                    value: 'slug'
                })[0]) : [],
            }
        })
    }

    toggleEdit(row) {
        this.setStateSelected(row)

        this.props.componentModalToggleAction({
            target: 'edit',
            isOpen: true
        })
    }

    toggleDelete(row) {
        this.setStateSelected(row)

        this.props.componentModalToggleAction({
            target: 'delete',
            isOpen: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader data={this.props.data} isDataTableLoading={this.props.isDataTableLoading} dataTable={
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <DataTable
                                            columns={columns({
                                                toggleEdit: this.toggleEdit,
                                                toggleDelete: this.toggleDelete,
                                            })}
                                            data={this.props.data}
                                            toggleAddModal={{
                                                target: 'add',
                                                isOpen: true
                                            }}
                                            isDataTableLoading={this.props.isDataTableLoading}
                                        />
                                        <ModalAddUser
                                            ddlbRoles={ddlbParser(this.props.roles, {
                                                label: 'name',
                                                value: 'slug'
                                            })}
                                        />
                                        <ModalEditUser
                                            id={this.state.selected.id}
                                            email={this.state.selected.email}
                                            firstName={this.state.selected.firstName}
                                            lastName={this.state.selected.lastName}
                                            fullName={this.state.selected.fullName}
                                            roles={this.state.selected.roles}
                                            ddlbRoles={ddlbParser(this.props.roles, {
                                                label: 'name',
                                                value: 'slug'
                                            })}
                                        />
                                        <ModalDelete
                                            primaryKey={
                                                {
                                                    id: this.state.selected.id
                                                }
                                            }
                                            deleteAction={this.props.aclUsersDeleteAction}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                } />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        data
    } = state.AclUsers

    const roles = {
        ...state.AclRoles.data
    }

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        data,
        isDataTableLoading,
        roles,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclUsersAction,
    aclUsersDeleteAction,
    aclRolesAction,
    componentDdlbOnChangeAction,
    componentModalInitAction,
    componentModalToggleAction,
})(TableUsers))