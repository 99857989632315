import React, {Component} from 'react';
import PropTypes          from 'prop-types'
import ReactApexChart     from 'react-apexcharts'

const PieChartDisplay = props => {
    let chartColor  = '#f46a6a'
    let chartOption = {
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 360,
                hollow: {
                    size: "70%",
                    background: "transparent",
                    image: void 0,
                },
                dataLabels: {
                    name : {
                        show : false,
                    },
                    value : {
                        fontSize : '40px',
                        fontWeight: 600,
                        color: chartColor,
                        formatter: function (val) {
                            return val
                        },
                    }
                },
            },
        },
        stroke: {
            lineCap: "round",
        },
        colors: ["#f46a6a"],
        chart: {
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        }
    }


    if (props.score <= 100 && props.score >= 90) {
        chartOption.colors = ['#34c38f']
        chartColor = '#34c38f'
    } else if (props.score <= 89 && props.score >= 50) {
        chartOption.colors = ['#f1b44c']
        chartColor = '#f1b44c'
    }

    if (props.title) {
        chartOption.labels = [props.title]
    }

    chartOption.plotOptions.radialBar.dataLabels.value.color = chartColor

    chartOption.chart.animations.enabled = (typeof props.animation != 'undefined') ? props.animation : true;

    return (<ReactApexChart
        options={chartOption}
        series={[[props.score]]}
        type="radialBar"
        height={200}
        />)
}

class PieChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <PieChartDisplay {...this.props} />
            </React.Fragment>
        )
    }
}

PieChart.propTypes = {
    score: PropTypes.number.isRequired
}

export default PieChart
