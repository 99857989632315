import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'

import BacklinkCount from './BacklinkCount'
import AnchorsCloud  from './AnchorsCloud'
import { withTranslation } from "react-i18next"

class OffPageSeo extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>{ this.props.t('Off-page SEO') }</h2>
                </div>

                <BacklinkCount />
                <AnchorsCloud />
            </React.Fragment>
        )
    }
}

export default withTranslation()(OffPageSeo)
