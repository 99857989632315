import PropTypes from 'prop-types'
import {connect} from "react-redux";
import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"
import { Alert, Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

import { notificationAction, readNotificationAction } from '../../../store/actions'

//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from 'app.cms.react/helpers/util';
import moment from 'moment'
import classNames from 'classnames';


class NotificationDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
        menu: false,
        total: 0,
        notifications: [],
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount()
  {
    this.timerID = setInterval(() => {
        this.props.notificationAction({
            page: 1
        })
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps) {
        const prevNotif = getPropTraverse(prevProps, ['notifications'])
        const notifs    = this.props.notifications

        if (prevNotif.length != notifs.length) {
            this.setState({
                total: notifs.length,
                notifications: notifs
            })
        }
  }

  markAsRead(id) {
      this.props.readNotificationAction({
          id: id
      })
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }
  render() {

    const notifs = this.props.notifications;
    const total  = notifs.length

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className={
                classNames('bx', 'bx-bell', {
                    'bx-tada' : this.props.notificationUpdated
                })
            } />
            { total > 0 && (<span className="badge bg-danger rounded-pill">{total}</span>)}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <SimpleBar style={{ height: "230px" }}>
                { total > 0 ? (
                    <React.Fragment>
                        { notifs.map((item, key) => (
                            <Link to={'/reports/' + item.token} key={key} className="text-reset notification-item" onClick={ () => this.markAsRead(item.id)}>
                                <div className="media">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                            <i className="bx bx-cart" />
                                        </span>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mt-0 mb-1">
                                            { (item.type.includes('ReportGenerated')) ? 'Your Report is now ready' : 'New Notification' }
                                        </h6>
                                        <div className="font-size-12 text-muted">
                                        <p className="mb-1">
                                            { item.name }
                                        </p>
                                        <p className="mb-0">
                                            <i className="mdi mdi-clock-outline" />{" "}
                                            { moment(item.created_date).fromNow() }{" "}
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </React.Fragment>
                ) : (
                    <Alert color="primary">
                        { this.props.t('No new notification!') }
                    </Alert>
                )}
                
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link className="btn btn-sm btn-link font-size-14 text-center" to="/reports">
                <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{this.props.t("View More Reports")}</span>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = (state) => {
    const {
        notifications
    } = state.Layout

    return {
        notifications
    }
}

export default withTranslation()(connect(mapStateToProps, {
    notificationAction,
    readNotificationAction
})(NotificationDropdown))
