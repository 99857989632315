import React, {Component} from 'react'
import {connect} from 'react-redux'
import MetaTags from 'react-meta-tags'
import {Link} from 'react-router-dom'
import {Col, Container, Row, CardBody, Card} from 'reactstrap'
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo.svg'
import lightlogo from 'assets/images/logo-light.svg'
import FormLogin from 'app.cms.react/components/Authentication/FormLogin'
import FormSocialLoginSignup from '../../components/Authentication/FormSocialLoginSignup'
import {pluckRouteFromName} from '../../helpers/util'

class Login extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Login</title>
                </MetaTags>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome Back!</h5>
                                                    <p>Sign in to continue.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={lightlogo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                            <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <FormLogin />
                                        </div>
                                        <div className="p-2 text-center">
                                            Or sign in with
                                        </div>
                                        <div className="p-2 text-center">
                                            <FormSocialLoginSignup />
                                        </div>
                                        <div className="pb-2 text-center">
                                            <Link to={pluckRouteFromName('forgot-password')} className="text-muted"><i className="bx bx bx-lock" /> Forgot password?</Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                Don't have an account? <Link to={pluckRouteFromName('register')} className="text-primary">Signup now</Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(Login)