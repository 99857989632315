import React, {Component} from 'react'
import PropTypes from "prop-types"
import ReCAPTCHA from 'react-google-recaptcha'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {AvForm, AvField} from 'availity-reactstrap-validation'
import FormAlert from '../FormAlert'
import ButtonLoading from '../ButtonLoading'
import FormSocialLoginSignup from './FormSocialLoginSignup'
import {registerUserAction, componentFormInitAction} from 'app.cms.react/store/actions'
import BroadcastNotification from '../BroadcastNotification'
import {pluckGatedRouteFromName} from '../../helpers/util'

const FormAlertFragment = (props) => {
    if (props.formSubmit && props.alertType == 'danger') {
        return (
            <FormAlert response={props.response} alertType={props.alertType} validationType='notification'/>
        )
    } else if (props.formSubmit && props.alertType == 'success') {
        return (
            <FormAlert response="Registration successful" alertType={props.alertType} validationType='notification' />
        )
    }

    return ''
}

const recaptchaRef = React.createRef();
class FormRegister extends Component {
    constructor(props) {
        super(props)

        this.state = {
            gtm: {
                type: 'RegisterNotification',
                notifiableType: 'Account'
            },
            captchaIsValid : true
        }

        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleBroadcast = this.handleBroadcast.bind(this)
        this.handleRedirect = this.handleRedirect.bind(this)
        this.captchaOnChage = this.captchaOnChage.bind(this)
    }

    captchaOnChage(value) {
        this.setState({
            captchaIsValid: (value !='')
        });
    }

    handleValidSubmit(event, values) {
        const recaptchaValue = (this.props.captchaSitekey) ? recaptchaRef.current.getValue() : true;
        
        if (recaptchaValue != '') {
            this.props.registerUserAction({
                firstName: values.first_name,
                lastName: values.last_name,
                email: values.email,
                companyName: values.company_name,
                password: values.password,
                passwordConfirmation: values.password_confirmation,
            }, this.props.history)
        } else {
            this.setState({
                captchaIsValid: false
            });
        }
    }

    handleBroadcast(userData) {
        this.handleRedirect()
        return <BroadcastNotification notifiableId={userData.account_id} notifiableType={this.state.gtm.notifiableType} type={this.state.gtm.type} />
    }

    handleRedirect() {
        setTimeout(() => {
            window.location.assign(pluckGatedRouteFromName('dashboard'))
        }, 1000)
    }


    render() {
        return (
            <React.Fragment>
                {(Object.entries(this.props.userData).length > 0 && this.props.alertType == 'success') ? (this.handleBroadcast(this.props.userData)) : null}

                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlertFragment {...this.props} formSubmit={this.props.formSubmit} />
                    <div className="mb-3">
                        <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    {(typeof this.props.hasFirstName != 'undefined' && this.props.hasFirstName == true) ? (
                        <div className="mb-3">
                            <AvField
                                name="first_name"
                                label="First Name"
                                value=""
                                className="form-control"
                                placeholder="Enter first name"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                            />
                        </div>
                    ) : null}

                    {(typeof this.props.hasLastName != 'undefined' && this.props.hasLastName == true) ? (
                        <div className="mb-3">
                            <AvField
                                name="last_name"
                                label="Last Name"
                                value=""
                                className="form-control"
                                placeholder="Enter last name"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                            />
                        </div>
                    ) : null}

                    {(typeof this.props.hasCompanyName != 'undefined' && this.props.hasCompanyName == true) ? (
                        <div className="mb-3">
                            <AvField
                                name="company_name"
                                label="Company Name"
                                value=""
                                className="form-control"
                                placeholder="Enter company name"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                            />
                        </div>
                    ) : null}

                    <div className="mb-3">
                        <AvField
                            name="password"
                            label="Password"
                            value=""
                            className="form-control"
                            placeholder="Enter password"
                            type="password"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    <div className="mb-3">
                        <AvField
                            name="password_confirmation"
                            label="Confirm Password"
                            value=""
                            className="form-control"
                            placeholder="Confirm password"
                            type="password"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    { (typeof this.props.captchaSitekey != 'undefined' && this.props.captchaSitekey != '') ? (
                        <div className="form-group">
                            <div className={ (this.state.captchaIsValid == false) ? 'is-invalid' : ''}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={this.props.captchaSitekey}
                                    onChange={this.captchaOnChage}
                                />
                            </div>
                            { (this.state.captchaIsValid == false) ? (
                                <div className="invalid-feedback">Captcha is required</div>
                            ) : null }
                        </div>
                    ) : null}

                    <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">
                            Sign up with
                        </h5>
                        <FormSocialLoginSignup />
                    </div>

                    <div className="mb-0 text-center">By registering you agree to the <a href={this.props.tocLink} className="text-primary">Terms of Use</a></div>

                    <div className="mt-3 d-grid">
                        <ButtonLoading
                            formLoading={this.props.formLoading}
                            buttonName="Register"
                        />
                    </div>
                </AvForm>
            </React.Fragment>
        )
    }
}

FormRegister.propTypes = {
    tocLink: PropTypes.string,
    hasCompanyName: PropTypes.bool,
    hasFirstName: PropTypes.bool,
    hasLastName: PropTypes.bool,
    captchaSitekey: PropTypes.string,
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        userData
    } = state.Register

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        userData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    registerUserAction,
    componentFormInitAction,
})(FormRegister))