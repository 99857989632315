import React, {Component} from 'react'
import {connect} from "react-redux";
import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Col, Container, Row, Card, CardBody} from 'reactstrap'
import profile from 'assets/images/profile-img.png'
import FormPasswordReset from 'app.cms.react/components/Authentication/FormPasswordReset'
import PropTypes from 'prop-types'

class PasswordReset extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Reset Password</title>
                </MetaTags>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Reset password</h5>
                                                    <p>Enter your new password.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={this.props.logo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                            <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={this.props.logo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <FormPasswordReset locationSearch={this.props.location} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

PasswordReset.propTypes = {
    logo: PropTypes.string
}


const mapStateToProps = (state) => {
    return {}
};

export default connect(mapStateToProps, {})(PasswordReset)