import {
    SUBSCRIPTION_PRODUCT_MODULE_STRIPE,
    SUBSCRIPTION_PRODUCT_MODULE_STRIPE_DATA,
    SUBSCRIPTION_PRODUCT_MODULE_STRIPE_UPDATE,
} from './actionTypes'

export const subscriptionProductModuleStripeAction = (params) => ({
    type: SUBSCRIPTION_PRODUCT_MODULE_STRIPE,
    payload: { params }
})

export const subscriptionProductModuleStripeDataAction = (data) => ({
    type: SUBSCRIPTION_PRODUCT_MODULE_STRIPE_DATA,
    payload: { data }
})

export const subscriptionProductModuleStripeUpdateAction = (params, meta) => ({
    type: SUBSCRIPTION_PRODUCT_MODULE_STRIPE_UPDATE,
    payload: { params, meta },
})