import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {broadcastNotificationAction} from 'app.cms.react/store/actions'

class BroadcastNotification extends Component {
    constructor(props) {
        super(props)

        if (typeof this.props.notifiableId != 'undefined') {
            this.handleBroadcastNotification()
        }
    }

    handleBroadcastNotification() {
        this.props.broadcastNotificationAction({
            type: this.props.type,
            notifiable_type: this.props.notifiableType,
            notifiable_id: this.props.notifiableId
        })
    }

    render() {
        return (<React.Fragment></React.Fragment>)
    }
}

BroadcastNotification.propTypes = {
    notifiableId: PropTypes.any,
    notifiableType: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
    broadcastNotificationAction
})(BroadcastNotification))