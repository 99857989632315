import PropTypes            from 'prop-types'
import {connect}            from 'react-redux';
import React, { Component } from 'react'
import { Alert, Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'

class SocialMediaSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasFacebook  : false,
            hasTwitter   : false,
            hasLinkedIn  : false,
            hasYoutube   : false,
            hasPinterest : false,
            hasInstagram : false,
            hasYelp      : false,
        }

        this.updateSocialMediaSetting = this.updateSocialMediaSetting.bind(this)
    }

    componentDidMount() {
        const reportSettings = this.props.reportSettings;
        const sectionData    = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks']) || []
        const facebook       = getPropTraverse(sectionData, ['facebook']) || {}
        const twitter        = getPropTraverse(sectionData, ['twitter']) || {}
        const linkedin       = getPropTraverse(sectionData, ['linkedin']) || {}
        const youtube        = getPropTraverse(sectionData, ['youtube']) || {}
        const pinterest      = getPropTraverse(sectionData, ['pinterest']) || {}
        const instagram      = getPropTraverse(sectionData, ['instagram']) || {}
        const yelp           = getPropTraverse(sectionData, ['yelp']) || {}
        let hasFacebook      = getPropTraverse(reportSettings, ['hasFacebook']) || ''
        let hasTwitter       = getPropTraverse(reportSettings, ['hasTwitter']) || ''
        let hasLinkedIn      = getPropTraverse(reportSettings, ['hasLinkedIn']) || ''
        let hasYoutube       = getPropTraverse(reportSettings, ['hasYoutube']) || ''
        let hasPinterest     = getPropTraverse(reportSettings, ['hasPinterest']) || ''
        let hasInstagram     = getPropTraverse(reportSettings, ['hasInstagram']) || ''
        let hasYelp          = getPropTraverse(reportSettings, ['hasYelp']) || ''

        if (hasFacebook == '') {
            hasFacebook = Object.keys(facebook).length > 0
        }
        if (hasTwitter == '') {
            hasTwitter = Object.keys(twitter).length > 0
        }
        if (hasLinkedIn == '') {
            hasLinkedIn = Object.keys(linkedin).length > 0
        }
        if (hasYoutube == '') {
            hasYoutube = Object.keys(youtube).length > 0
        }
        if (hasPinterest == '') {
            hasPinterest = Object.keys(pinterest).length > 0
        }
        if (hasInstagram == '') {
            hasInstagram = Object.keys(instagram).length > 0
        }
        if (hasYelp == '') {
            hasYelp = Object.keys(yelp).length > 0
        }

        this.setState({
            hasFacebook  : (hasFacebook != '' && hasFacebook != 'false'),
            hasTwitter   : (hasTwitter != '' && hasTwitter != 'false'),
            hasLinkedIn  : (hasLinkedIn != '' && hasLinkedIn != 'false'),
            hasYoutube   : (hasYoutube != '' && hasYoutube != 'false'),
            hasPinterest : (hasPinterest != '' && hasPinterest != 'false'),
            hasInstagram : (hasInstagram != '' && hasInstagram != 'false'),
            hasYelp      : (hasYelp != '' && hasYelp != 'false')
        })

    }

    updateSocialMediaSetting(meta, value) {
        const reportSettings = this.props.reportSettings
        const data           = {}
        let reportData       = {}
        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value

        reportData = reportSettings;

        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    render() {
        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Social Media Buttons')}</CardTitle>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Facebook')} { this.state.hasFacebook }</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasFacebook"
                                    switch="bool"
                                    checked={ this.state.hasFacebook }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasFacebook', !this.state.hasFacebook)
                                    }
                                />
                                <label
                                    htmlFor="hasFacebook"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Twitter')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasTwitter"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasTwitter) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasTwitter', !this.state.hasTwitter)
                                    }
                                />
                                <label
                                    htmlFor="hasTwitter"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('LinkedIn')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasLinkedIn"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasLinkedIn) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasLinkedIn', !this.state.hasLinkedIn)
                                    }
                                />
                                <label
                                    htmlFor="hasLinkedIn"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Youtube')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasYoutube"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasYoutube) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasYoutube', !this.state.hasYoutube)
                                    }
                                />
                                <label
                                    htmlFor="hasYoutube"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Pinterest')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasPinterest"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasPinterest) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasPinterest', !this.state.hasPinterest)
                                    }
                                />
                                <label
                                    htmlFor="hasPinterest"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Instagram')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasInstagram"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasInstagram) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasInstagram', !this.state.hasInstagram)
                                    }
                                />
                                <label
                                    htmlFor="hasInstagram"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Yelp')}</CardTitle>
                            <div className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasYelp"
                                    switch="bool"
                                    checked={ Boolean(this.state.hasYelp) }
                                    onChange={() =>
                                        this.updateSocialMediaSetting('hasYelp', !this.state.hasYelp)
                                    }
                                />
                                <label
                                    htmlFor="hasYelp"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

SocialMediaSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(SocialMediaSettings))
