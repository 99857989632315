import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class MetaDescription extends Component {
    constructor(props) {
        super(props)

        const sectionDataForSeo = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeo'])
        const sectionData   = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePage'])
        let canonical       = getPropTraverse(sectionData, ['canonical']) || ''
        let reportUrl       = getPropTraverse(this.props.reportData, ['url']);
        let metaDescription = getPropTraverse(sectionData, ['meta', 'description', 'text']) || '';

        if (
            metaDescription.trim().toLowerCase() == 'not acceptable!' ||
            metaDescription.trim().toLowerCase() == 'access denied' ||
            metaDescription.trim().toLowerCase() == '403 forbidden' ||
            metaDescription.trim().toLowerCase() == ''
        ) {
            metaDescription = getPropTraverse(sectionDataForSeo, ['meta', 'description']) || ''
        }

        // try getting data on dataForSEO
        if (canonical == '') {
            canonical = getPropTraverse(sectionDataForSeo, ['meta', 'canonical']) || '';
        }

        if (canonical == '') {
            canonical = reportUrl
        }

        this.state = {
            sectionData            : sectionData || sectionDataForSeo,
            canonical              : canonical,
            descriptionText        : metaDescription,
            descriptionTitleLength : metaDescription.length  || 0,
            status                 : 'warning',
            message                : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = 'danger'
            let message = 'Not good! Your page is missing meta description element.'

            if (this.state.descriptionTitleLength > 0) {
                if (this.state.descriptionTitleLength >= 160 && this.state.descriptionTitleLength <= 220) {
                    status  = 'success'
                    message = 'metaDescriptionGood'
                } else {
                    status  = 'warning'
                    message = 'metaDescriptionWarning'
                }
            }

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { meta_description : message }
            } else {
                reportSummary[status]['meta_description'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="metaDescriptionSection" className='mt-4'>
                    <h4>{ this.props.t('Meta Description') }</h4>
                    <p>Although Meta descriptions don't have direct influence on rankings, they are still important as they form the snippet that people see in search results. Therefore, it should "sell" the webpage to the searcher and encourage click through.</p>
                    <p>For optimum effectiveness, Meta descriptions should be 160-220 characters long.</p>

                    <div className='p-3 mb-5'>
                        <h5>{ this.props.t('Homepage') }:</h5>

                        { this.state.descriptionTitleLength > 0 ? (
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className='text-center fw-50'>{ this.props.t('URL') }</th>
                                        <th>{ this.state.canonical }</th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>{ this.props.t('Char') }.</th>
                                        <th>{ this.props.t('Meta Description') }</th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>{ this.state.descriptionTitleLength }</th>
                                        <td>{ this.state.descriptionText }</td>
                                    </tr>
                                    <tr>
                                        <th className='text-center fw-50'><ReportIcon type={this.state.status} /></th>
                                        <td>{ this.props.t(this.state.message) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className='text-center fw-50'><ReportIcon type={this.state.status} /></th>
                                        <td>{ this.props.t(this.state.message) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(MetaDescription))
