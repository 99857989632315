import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {isUserAuthenticated} from '../app.cms.react/helpers/authUtils'
import {jwtSessionExpiredAction} from '../app.cms.react/store/auth/jwtSession/actions';

const AppRoute = ({
          component: Component,
          layout: Layout,
          isAuthProtected,
          ...rest
      }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                rest.jwtSessionExpiredAction()
                return (
                    <Redirect
                        to={{pathname: '/login', state: {from: props.location}}}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />
)

AppRoute.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {
    jwtSessionExpiredAction
})(AppRoute)
