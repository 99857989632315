import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class XmlSitemap extends Component {
    constructor(props) {
        super(props)

        const sectionData   = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []

        this.state = {
            sectionData : sectionData,
            xmlFound    : getPropTraverse(sectionData, ['xmlSiteMap', 'url']) || false,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service',
            hasGoogleConsoleAccess : getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary
        let message = 'xmlSiteMapMessageNotInstalled'
        let status  = 'danger'

        const hasGoogleConsoleAccess = getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        const xmlSitemap             = getPropTraverse(this.props.reportSettings, ['xmlSitemap']) || this.state.status

        if (this.state.xmlFound && hasGoogleConsoleAccess) {
            status  = 'success'
            message = 'xmlSiteMapMessageSuccess';
        } else if (this.state.xmlFound && !hasGoogleConsoleAccess) {
            status  = 'warning'
            message = 'xmlSiteMapMessageWarning'
        }

        if (xmlSitemap != null && xmlSitemap != this.state.status) {
            status  = xmlSitemap
            message = 'xmlSiteMapMessageNotInstalled'
            switch (xmlSitemap) {
                case 'success' :
                    message = 'xmlSiteMapMessageSuccess'
                    break;
                case 'warning' :
                    message = 'xmlSiteMapMessageWarning'
                    break;
            }
        }

        this.setState({
            status  : status,
            message : message,
            hasGoogleConsoleAccess : hasGoogleConsoleAccess
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['xml_sitemap']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['xml_sitemap']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['xml_sitemap']
        }

        if (typeof reportSummary[status] == 'undefined') {
            reportSummary[status] = {}
        }

        reportSummary[status]['xml_sitemap'] = {
            message,
            links: this.state.xmlFound
        };

        this.props.saveReportSummary(reportSummary)
    }

    componentDidUpdate(prevProps, prevState) {
        const hasGoogleConsoleAccess = getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == true
        const xmlSitemap             = getPropTraverse(this.props.reportSettings, ['xmlSitemap']) || null

        if (typeof this.props.reportSettings.xmlSitemap != 'undefined' && (hasGoogleConsoleAccess != this.state.hasGoogleConsoleAccess || xmlSitemap != this.state.status)) {
            this.updateReportSummary()
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="xmlSiteMapSection" className='mt-4'>
                    <h4>{ this.props.t('XML Sitemap') }</h4>
                    <p>An XML sitemap is an XML file containing all the pages on your website. Creating and submitting the sitemap to Google Search Console allows search engine bots to easily crawl the website in a more effective manner.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                {(this.state.xmlFound) && (
                                    <tr>
                                        <td colSpan={2}>
                                            <a href={this.state.xmlFound} target="_blank">{ this.state.xmlFound }</a>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.props.t(this.state.message) }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(XmlSitemap));
