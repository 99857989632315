import React, {Component}      from 'react'
import {connect}               from "react-redux";
import { withTranslation } from 'react-i18next'
import { loginUserAction, componentFormOnSubmitAction } from '../../app.cms.react/store/actions'

class ExternalLogin extends Component {
    constructor(props) {
        super(props)

        const credential = atob(props.match.params.hash)
        const user       = credential.split('|')

        this.props.loginUserAction({
            email: user[0] || '',
            password: user[1] || ''
        }, this.props.history)

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return ''
    }
}


const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
    loginUserAction,
    componentFormOnSubmitAction,
})(ExternalLogin))
