import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {AvForm, AvField} from 'availity-reactstrap-validation'
import FormAlert from '../FormAlert'
import ButtonLoading from '../ButtonLoading'
import {componentFormInitAction, componentFormOnSubmitAction, passwordResetAction} from 'app.cms.react/store/actions'

class FormPasswordReset extends Component {
    constructor(props) {
        super(props)

        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        const query = new URLSearchParams(this.props.locationSearch.search);

        this.props.passwordResetAction({
            password: values.password,
            passwordConfirmation: values.password_confirmation,
            token: query.get('token'),
            email: query.get('email'),
        }, this.props.history)

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification'/>

                    <div className="mb-3">
                        <AvField
                            name="password"
                            label="New Password"
                            value=""
                            className="form-control"
                            placeholder="Enter new password"
                            type="password"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    <div className="mb-3">
                        <AvField
                            name="password_confirmation"
                            label="Confirm New Password"
                            value=""
                            className="form-control"
                            placeholder="Confirm new password"
                            type="password"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    <div className='text-end'>
                        <ButtonLoading
                            formLoading={this.props.formLoading}
                            buttonName="Update"
                        />
                    </div>
                </AvForm>
            </React.Fragment>
        )
    }
}

FormPasswordReset.propTypes = {
    locationSearch: PropTypes.any.isRequired
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    passwordResetAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(FormPasswordReset))