import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'

class Recommendations extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const websiteOptimization = getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == 'true' || getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == true
        const organicSeo          = getPropTraverse(this.props.reportSettings, ['organicSeo']) == 'true' || getPropTraverse(this.props.reportSettings, ['organicSeo']) == true

        return (
            <React.Fragment>
                { (websiteOptimization || organicSeo) && (
                    <React.Fragment>
                        <div className='mb-4 mb-md-5'>
                            <h2 className='mb-0'>{ this.props.t('Recommendations') }</h2>
                        </div>

                        <div className='my-auto'>
                            { websiteOptimization && (
                                <div className="mb-5">
                                    <h4>{ this.props.t('Website Optimization') }</h4>
                                    <p>{ this.props.t('The items on this sheet are critical to making the website function the best way it can. Fixing these is a crucial first step to its success. We recommend fixing all the items on this report as soon as possible.') }</p>
                                </div>
                            ) }

                            { organicSeo && (
                                <div className="mb-5">
                                    <h4>{ this.props.t('Organic SEO') }</h4>
                                    <p>{ this.props.t('Running an organic Search Engine Optimisation campaign will increase the website\'s visibility and traffic consistently. It will also maintain high Google rankings over a long period of time. We recommend starting a campaign immediately.')}</p>
                                </div>
                            ) }
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings
    } = state.Reports

    return {
        reportSettings
    }
}
export default withTranslation()(connect(mapStateToProps)(Recommendations))
