import { takeEvery, put, call } from 'redux-saga/effects'
import {
    SOCIAL_AUTH_CALLBACK,
} from './actionTypes'

import {
    defaultPostOptions,
    fetchJSON,
    formatFormData,
    getPropTraverse,
    parseStringVariable,
    pluckRouteFromName
} from 'app.cms.react/helpers/util';
import {AJAX_URL_SOCIAL_AUTH_PROVIDER_CALLBACK} from 'app.cms.react/store/actionTypes'
import {setSession} from '../../../helpers/authUtils'
import {componentFormOnSubmitDoneAction} from '../../components/form/actions'

function* socialAuthCallback({ payload: { params, meta } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}
    let response = ''
    let argsProfile
    let argsToken

    try {
        switch (params._provider) {
            case 'facebook':
                argsProfile = params._profile
                argsToken = params._token

                options.body = formatFormData({
                    id: argsProfile.id,
                    email: argsProfile.email,
                    name: argsProfile.name,
                    first_name: argsProfile.firstName,
                    last_name: argsProfile.lastName,
                    profile_pic_url: argsProfile.profilePicURL,
                    token: argsToken.accessToken,
                    expires_in: argsToken.expiresAt,
                    raw: JSON.stringify({
                        profile: argsProfile,
                        token: argsToken,
                    })
                });
                break;
            case 'google':
                argsProfile = params._profile
                argsToken = params._token

                options.body = formatFormData({
                    id: argsProfile.id,
                    email: argsProfile.email,
                    name: argsProfile.name,
                    first_name: argsProfile.firstName,
                    last_name: argsProfile.lastName,
                    profile_pic_url: argsProfile.profilePicURL,
                    token: argsToken.accessToken,
                    expires_in: argsToken.expiresIn,
                    raw: JSON.stringify({
                        profile: argsProfile,
                        token: argsToken,
                    })
                });
                break;
            default:
                throw {
                    data: {
                        response: {
                            error: 'Invalid provider'
                        }
                    }
                }
        }

        response = yield call(fetchJSON, parseStringVariable(AJAX_URL_SOCIAL_AUTH_PROVIDER_CALLBACK, params._provider), options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        setSession(response)
        window.location.href = pluckRouteFromName('dashboard')
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* SocialSaga() {
    yield takeEvery(SOCIAL_AUTH_CALLBACK, socialAuthCallback)
}

export default SocialSaga