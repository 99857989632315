import {
    REPORTS,
    REPORTS_DATA,
    REPORT_DETAILS,
    REPORT_DETAILS_DATA,
    REPORTS_DELETE,
    REPORTS_DELETE_DONE,
    REPORT_SETTINGS,
    REPORT_SUMMARY,
    GENERATE_REPORT,
    GENERATE_REPORT_DATA,
    SAVE_REPORT,
    SAVE_REPORT_DATA,
    DOWNLOAD_REPORT,
    DOWNLOAD_REPORT_DATA,
    CHECK_TOKEN_STATUS,
    CHECK_TOKEN_STATUS_DATA,
    DOWNLOAD_REPORT_FILE,
    DOWNLOAD_REPORT_FILE_DATA,
    REPORT_DELETE_BY_ID
} from './actionTypes'

export const reportsAction = (params) => ({
    type: REPORTS,
    payload: { params }
})

export const reportsDataAction = (data, polling) => ({
    type: REPORTS_DATA,
    payload: { data, polling }
})

export const reportDetailsAction = (params) => ({
    type: REPORT_DETAILS,
    payload: { params }
})

export const reportDetailsDataAction = (data) => ({
    type: REPORT_DETAILS_DATA,
    payload: { data }
})

export const reportsDeleteAction = (params, meta) => ({
    type: REPORTS_DELETE,
    payload: { params, meta }
})

export const reportsDeleteByIdAction = (reportId) => ({
    type: REPORT_DELETE_BY_ID,
    payload: { reportId, reportId }
})

export const reportsDeleteDoneAction = (params, meta) => ({
    type: REPORTS_DELETE_DONE,
    payload: { params, meta }
})

export const saveReportSettings = (data) => ({
    type: REPORT_SETTINGS,
    payload: { data }
})

export const saveReportSummary = (data) => ({
    type: REPORT_SUMMARY,
    payload: { data }
})

export const saveReportAction = (params, meta) => ({
    type: SAVE_REPORT,
    payload: { params, meta }
})

export const saveReportDataAction = (data) => ({
    type: SAVE_REPORT_DATA,
    payload: { data }
})

export const generateReportAction = (params) => ({
    type: GENERATE_REPORT,
    payload: { params }
})

export const generateReportDataAction = (data) => ({
    type: GENERATE_REPORT_DATA,
    payload: { data }
})

export const downloadReportAction = (params) => ({
    type: DOWNLOAD_REPORT,
    payload: { params }
})

export const downloadReportDataAction = (data) => ({
    type: DOWNLOAD_REPORT_DATA,
    payload: { data }
})

export const checkTokenStatusAction = (params) => ({
    type: CHECK_TOKEN_STATUS,
    payload: { params }
})

export const checkTokenStatusDataAction = (data) => ({
    type: CHECK_TOKEN_STATUS_DATA,
    payload: { data }
})

export const downloadReportFileAction = (params) => ({
    type: DOWNLOAD_REPORT_FILE,
    payload: { params }
})

export const downloadReportFileDataAction = (data) => ({
    type: DOWNLOAD_REPORT_FILE_DATA,
    payload: { data }
})
