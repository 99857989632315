import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import FormAlert from '../../FormAlert'
import ButtonLoading from '../../ButtonLoading'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {formatFormDataArray} from 'app.cms.react/helpers/util'
import {subscriptionProductModuleStripeUpdateAction, componentFormInitAction, componentFormOnSubmitAction, componentModalToggleAction, componentDdlbMultiOnChangeAction} from 'app.cms.react/store/actions'
import PropTypes from 'prop-types'

const animatedComponents = makeAnimated()

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalEditProductModule extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.toggleModal       = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleDdlbChange = (value) => {
        this.props.componentDdlbMultiOnChangeAction(value)
    };

    handleValidSubmit(event, values) {
        this.props.subscriptionProductModuleStripeUpdateAction({
            product_id: this.props.productId,
            price_id: this.props.priceId,
            product_module: formatFormDataArray(this.props.ddlb, 'value'),
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'edit') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <ModalHeader>Edit</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <div>
                                <label>Subscription Modules</label>
                                <Select
                                    name="subscription-module"
                                    value={this.props.ddlb}
                                    options={this.props.ddlbSubscriptionModule}
                                    onChange={this.handleDdlbChange}
                                    classNamePrefix="select2-selection"
                                    isClearable={true}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalEditProductModule.propTypes = {
    productId: PropTypes.any.isRequired,
    priceId: PropTypes.any.isRequired,
    ddlbSubscriptionModule: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        ddlb
    } = state.ComponentsDdlb

    return {
        ddlb,
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    subscriptionProductModuleStripeUpdateAction,
    componentDdlbMultiOnChangeAction,
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalEditProductModule))