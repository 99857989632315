import React, {Component} from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next"
import MalwareCheck from './MalwareCheck'
import SocialMediaButtons from './SocialMediaButtons'

class ReputationSignalsMain extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const pageBreak = {
            pageBreakAfter: 'always'
        }
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>Reputation Signals</h2>
                </div>
                <MalwareCheck />
                <SocialMediaButtons />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData
    } = state.Reports

    return {
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps)(ReputationSignalsMain))
