import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {List, ListInlineItem} from 'reactstrap'
import DataTable from '../../app.cms.react/components/DataTable'
import { reportsAction, reportsDeleteAction } from '../../store/actions'
import { componentModalInitAction, componentModalToggleAction } from '../../app.cms.react/store/components/modal/actions'
import ModalDelete from '../../app.cms.react/components/ModalDelete'
import { getPropTraverse, dateFormat } from '../../app.cms.react/helpers/util'

const columns = (props) => [
    {
        dataField: 'name',
        text: 'Report URL',
        sort: true,
        formatter: ColFormatReportName
    },
    {
        dataField: 'template',
        text: 'Template Name',
        sort: true,
        formatter: ColFormatReportTemplate
    },
    {
        dataField: 'status.created_date',
        text: 'Date Requested',
        sort: true,
        formatter: ColFormatDateRequested
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        formatter: ColFormatActions,
        formatExtraData: {
            toggleDelete: props.toggleDelete
        }
    }
]

const ColFormatReportName = (cell, row, rowIndex) => {
    let report_url = getPropTraverse(row, ['url'])

    report_url = report_url.replace('https://', '').replace('http://', '')
    if (report_url.endsWith('/')) {
        report_url = report_url.slice(0, -1)
    }

    return (
        <React.Fragment>
            <Link to={ 'reports/' + row.token }>{ report_url.toLowerCase() }</Link>
        </React.Fragment>
    )
}

const ColFormatReportTemplate = (cell, row, rowIndex) => {
    return getPropTraverse(row, ['name'])
}

const ColFormatDateRequested = (cell, row, rowIndex) => {
    const created_date = getPropTraverse(row, ['status', 'created_date']) || getPropTraverse(row, ['created_date'])
    return dateFormat(created_date, 'MMMM DD, YYYY')
}

const ColFormatActions = (cell, row, rowIndex, formatExtraData) => {
    const reportStatus = getPropTraverse(row, ['status', 'tokenize_request_status_code']) || 'queued'
    const templateId   = getPropTraverse(row, ['report_template_id']) || 1
    return (
        <React.Fragment>
            { reportStatus == 'completed' && (
            <List type="inline" className="p-0 m-0">

                <ListInlineItem>
                    { (templateId == 2) ? 
                        <React.Fragment>
                            <Link to={ 'reports/view/' + row.token }>
                                <i className="bx bx-book-content text-info" />
                            </Link>
                        </React.Fragment>
                    : 
                        <React.Fragment>
                            <Link to={ 'reports/' + row.token }>
                                <i className="bx bx-book-content text-info" />
                            </Link>
                        </React.Fragment>
                    }
                    
                </ListInlineItem>
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleDelete(row, e)
                }}><i className="bx bx-trash text-danger" /></ListInlineItem>
            </List>
            )}
            { reportStatus == 'in-progress' && (
                <div
                    className="spinner-border spinner-border-sm text-primary m-1"
                    role="status"
                    >
                    <span className="sr-only">Loading...</span>
                </div>
            )}
            { reportStatus == 'failed' && (
                <div className="text-danger m-1">Failed</div>
            )}

            { reportStatus == 'queued' && (
                <div className="text-warning m-1">Queued</div>
            )}
        </React.Fragment>
    )
}

class TableReports extends Component {
    constructor(props) {
        super(props)

        this.toggleDelete = this.toggleDelete.bind(this)
        this.state = {
            selected: {
                id: '',
                name: ''
            }
        }
    }

    componentDidMount() {
        this.props.reportsAction()

        this.timerID = setInterval(() => {
            if (this.props.polling === true) {
                this.props.reportsAction()
            }
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.report_id : '',
                name: (typeof row != 'undefined') ? row.name : ''
            }
        })
    }

    toggleDelete(row) {
        this.setStateSelected(row)

        this.props.componentModalToggleAction({
            target: 'delete',
            isOpen: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <DataTable
                    data={this.props.data}
                    keyField='report_id'
                    isDataTableLoading={this.props.isDataTableLoading}
                    columns={columns({
                        toggleDelete: this.toggleDelete,
                    })}
                />
                <ModalDelete
                    primaryKey={this.state.selected.id}
                    deleteAction={this.props.reportsDeleteAction}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        data,
        isDataTableLoading,
        polling
    } = state.Reports

    return {
        data,
        isDataTableLoading,
        polling
    }
}

export default withTranslation()(connect(mapStateToProps, {
    reportsAction,
    reportsDeleteAction,
    componentModalInitAction,
    componentModalToggleAction
})(TableReports))