import React, {Component} from 'react'
import SocialLogin from 'react-social-login'

const RenderSocialButton = params => {
    switch (params.type) {
        case 'span':
            return (
                <React.Fragment>
                    <span onClick={params.triggerLogin} className={params.class} role='button'>{params.children}</span>
                </React.Fragment>
            )
    }

    return ''
}


class SocialLoginButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {...props} = this.props

        return (
            <RenderSocialButton {...props} />
        )
    }
}

export default SocialLogin(SocialLoginButton);