import React, {Component} from 'react'
import {connect} from "react-redux";

import { withTranslation } from "react-i18next"
import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'

import GoogleAnalytics     from './GoogleAnalytics'
import GoogleSearchConsole from './GoogleSearchConsole'
import MobileFriendlyCheck from './MobileFriendlyCheck'
import PageLoadSpeed       from './PageLoadSpeed'
import WebCoreVitals       from './WebCoreVitals'
import PageStatusCode      from './PageStatusCode'
import XmlSitemap          from './XmlSitemap'
import Robots              from './Robots'

class TechnicalSeoMain extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const pageBreak = {
            pageBreakAfter: 'always'
        }
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>{ this.props.t('Technical SEO') }</h2>
                </div>

                <GoogleAnalytics />
                <GoogleSearchConsole />
                <MobileFriendlyCheck />
                <div style={pageBreak}></div>
                <PageLoadSpeed />
                <div style={pageBreak}></div>
                <WebCoreVitals />
                <PageStatusCode />
                <XmlSitemap />
                <div style={pageBreak}></div>
                <Robots />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData
    } = state.Reports

    return {
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps)(TechnicalSeoMain))
