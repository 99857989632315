import React, {Component} from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next"

import {Link} from 'react-router-dom'
import {Card, Button, Col, Container, Row, Spinner} from 'reactstrap'

import coverImage from '../../../assets/images/report/cover.jpg'
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { sanitizeFilename } from '../../../helpers/util';

import moment from 'moment';

class Cover extends Component {
    constructor(props) {
        super(props)


        const reportData = this.props.reportData
        let reportTitle  = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeo', 'meta', 'title']) || ''
        let reportUrl    = getPropTraverse(reportData, ['url'])

        if (reportTitle == '' || reportTitle.trim().toLowerCase() == 'not acceptable!' || reportTitle.trim().toLowerCase() == 'access denied' ) {
            reportTitle = sanitizeFilename(reportUrl);
        }

        this.state = {
            title        : reportTitle,
            url          : getPropTraverse(this.props.reportData, ['url']) || '',
            created_date : getPropTraverse(this.props.reportData, ['status', 'created_date']) || ''
        }
    }

    render() {
        let reportTitle  = getPropTraverse(this.props.reportSettings, ['coverTitle']) || false;

        if (!reportTitle || reportTitle === '') {
            reportTitle = this.state.title;
        }

        return (
            <React.Fragment>
                <div className="cover-page" id="coverContainer">
                    <img
                      src={ coverImage }
                      alt="Cover"
                      className="img-fluid"
                    />
                    <div className='p-5 pt-0'>
                        <h1>{ reportTitle }</h1>
                        <p className='mb-5'>{ this.state.url }</p>
                        <h5>{ this.props.t('CoverTitle') }</h5>
                        <p className='mb-0'>{ (this.state.created_date) ? moment(this.state.created_date).format('MMMM DD, YYYY') : '' }</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        reportData,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps)(Cover));
