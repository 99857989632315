import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Card, CardBody ,Button } from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {componentFormInitAction} from 'app.cms.react/store/actions'
import ButtonLoading from './ButtonLoading'
import {numberFormatComma, pluckGatedRouteFromName} from 'app.cms.react/helpers/util'
import {Link} from 'react-router-dom'

const RenderSubscribeButton = props => {
    if (props.hasSubscription) {
        return (
            <Link
                to={pluckGatedRouteFromName('subscription-billing')}
                color="primary"
                className="btn btn-primary btn-lg"
            >
                Activate
            </Link>
        )
    }

    return (
        <Button
            to={''}
            color="primary"
            className="btn btn-primary btn-lg"
            onClick={(e) => {props.onClickEvent(props.productId, props.priceId)}}
        >
            Activate
        </Button>
    )
}

const FormSubmitButtons = props => {
    if (props.formLoading) {
        return (
            <ButtonLoading formLoading={props.formLoading} />
        )
    }

    if (props.isSubscribed) {
        return (
            <div className="btn btn-secondary btn-lg disabled">Subscribed</div>
        )
    } else {
        return (<RenderSubscribeButton {...props} />)
    }
}

class CardSubscription extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasSubscription: false
        }

        this.props.componentFormInitAction()
    }

    subscriptionChecker() {
        this.state.hasSubscription = (this.props.subscription == null) ? false : true
    }

    render() {
        this.subscriptionChecker()
        const product = this.props.product

        // This is retrieve ONLY recurring products (SAAS based)
        // Uncomment this if you want to retrieve ALL products regardless if one-time or recurring
        return (product.recurring == null) ? ('') : (
            <React.Fragment>
                <Col xl="3" md="6">
                    <Card className="plan-box text-center">
                        <CardBody className="p-4">
                            <h5>{product.product_name}</h5>
                            <div>{product.product_description}</div>
                            <div className="py-4">
                                <h2>
                                    <sup>
                                        <small>{product.currency.toUpperCase()}</small>
                                    </sup>{" "}
                                    {numberFormatComma(product.amount_decimal)}
                                </h2>
                                {(product.recurring) ?
                                    (
                                        <React.Fragment>
                                            <div>Per {(product.recurring.interval_count == 1) ? '' : product.recurring.interval_count} {product.recurring.interval}</div>
                                            {(product.recurring.trial_period_days != null) ?
                                                (<span className="font-size-11 badge bg-soft bg-primary text-primary text-uppercase">{product.recurring.trial_period_days} days trial</span>)
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                    )
                                    :
                                    (<div>One time payment</div>)
                                }
                            </div>
                            {(product.price_description) ?
                                (<div>{product.price_description}</div>)
                                :
                                null
                            }
                            <div className="mt-3">
                                <FormSubmitButtons
                                    formLoading={this.props.formLoading}
                                    onClickEvent={this.props.onClickEvent}
                                    productId={product.product_id}
                                    priceId={product.price_id}
                                    isSubscribed={product.is_subscribed}
                                    hasSubscription={this.state.hasSubscription}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        )
    }
}

CardSubscription.propTypes = {
    product: PropTypes.object.isRequired,
    onClickEvent: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        subscription,
    } = state.SubscriptionChecker

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        subscription
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentFormInitAction,
})(CardSubscription))