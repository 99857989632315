module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:"",
    SECRET: "",
    MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  },
  facebook: {
    APP_ID: "",
  },
  file_upload : {
      IMAGE : {
          VALID_FORMATS : ['png', 'jpg', 'jpeg'],
          MAX_SIZE_LIMIT : process.env.REACT_APP_UPLOAD_SIZE_LIMIT
      }
  }
}
