import {
    ACL_USERS,
    ACL_USERS_DATA,
    ACL_USERS_UPDATE,
    ACL_USERS_ADD,
    ACL_USERS_DELETE,
} from './actionTypes'

export const aclUsersAction = (params) => ({
    type: ACL_USERS,
    payload: { params },
})

export const aclUsersDataAction = (data) => ({
    type: ACL_USERS_DATA,
    payload: { data },
})

export const aclUsersUpdateAction = (params, meta) => ({
    type: ACL_USERS_UPDATE,
    payload: { params, meta },
})

export const aclUsersAddAction = (params, meta) => ({
    type: ACL_USERS_ADD,
    payload: { params, meta },
})

export const aclUsersDeleteAction = (params, meta) => ({
    type: ACL_USERS_DELETE,
    payload: { params, meta },
})