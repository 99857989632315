import {
    FORGOT_PASSWORD,
} from './actionTypes'

const INIT_STATE = {}

const forgotPassword = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case FORGOT_PASSWORD:
            return { ...state }
    }

    return { ...state };
}

export default forgotPassword