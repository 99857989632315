import {takeEvery, put, call} from 'redux-saga/effects'
import {fetchJSON, getPropTraverse, defaultGetOptions, buildUrlParams, defaultPutOptions, defaultPostOptions, defaultDeleteOptions, formatFormData} from 'app.cms.react/helpers/util'
import {ACL_USERS, ACL_USERS_UPDATE, ACL_USERS_ADD, ACL_USERS_DELETE} from './actionTypes'
import {AJAX_URL_ACL_USERS} from 'app.cms.react/store/actionTypes'
import {aclUsersDataAction, exceptionHandlerAction, componentDataTableLoadingAction, componentDataTableLoadedAction, componentFormOnSubmitDoneAction} from 'store/actions'

function* aclUsers({ payload: { params, history } }) {
    try {
        const url = AJAX_URL_ACL_USERS + buildUrlParams(params);

        yield put(componentDataTableLoadingAction())

        const response   = yield call(fetchJSON, url, defaultGetOptions())
        const data       = getPropTraverse(response, ['response', 'data'])

        yield put(aclUsersDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* aclUsersUpdate({ payload: { params, meta } }) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.id,
        first_name: params.firstName,
        last_name: params.lastName,
        email: params.email,
        roles: params.roles.value,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_USERS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData = yield call(fetchJSON, AJAX_URL_ACL_USERS, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclUsersDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclUsersAdd({ payload: { params, meta } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        first_name: params.firstName,
        last_name: params.lastName,
        email: params.email,
        roles: params.roles.value,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_USERS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData = yield call(fetchJSON, AJAX_URL_ACL_USERS, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclUsersDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclUsersDelete({ payload: { params, meta } }) {
    let options  = defaultDeleteOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.primaryKey.id,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_USERS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData = yield call(fetchJSON, AJAX_URL_ACL_USERS, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclUsersDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* AclUsersSaga() {
    yield takeEvery(ACL_USERS, aclUsers)
    yield takeEvery(ACL_USERS_UPDATE, aclUsersUpdate)
    yield takeEvery(ACL_USERS_ADD, aclUsersAdd)
    yield takeEvery(ACL_USERS_DELETE, aclUsersDelete)
}

export default AclUsersSaga