import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import FormAlert from '../../FormAlert'
import ButtonLoading from '../../ButtonLoading'
import {aclPermissionsUpdateAction, componentFormInitAction, componentFormOnSubmitAction, componentModalToggleAction} from 'app.cms.react/store/actions'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalEditPermission extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.toggleModal       = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleValidSubmit(event, values) {
        this.props.aclPermissionsUpdateAction({
            id: this.props.id,
            name: values.name
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'edit') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <ModalHeader>Edit</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <AvField name="name"
                                value={this.props.name}
                                className="form-control"
                                placeholder='Input value'
                                type="text"
                                required
                                disabled={this.props.disableFields}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclPermissionsUpdateAction,
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalEditPermission))