import {
    CODE_TABLE_OPTIONS,
    CODE_TABLE_SET_OPTIONS
} from './actionTypes'

export const codeTableOptionsAction = (options) => ({
    type    : CODE_TABLE_OPTIONS,
    payload : { options },
})

export const codeTableSetOptionsAction = (options, type) => ({
    type    : CODE_TABLE_SET_OPTIONS,
    payload : { options, type },
})