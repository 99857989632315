import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'
import KeywordRankings    from './KeywordRankings'
import FileUploadInfo     from './FileUploadInfo'
import toastr             from "toastr"


class OnPageSeoSettings extends Component {
    constructor(props) {
        super(props)

        const settingsData = this.props.reportSettings

        this.state = {
            validFileTypes        : ['image/png', 'image/jpg', 'image/jpeg'],
            ctaButton             : getPropTraverse(settingsData, ['ctaButton']) == 'true',
            textContent           : getPropTraverse(settingsData, ['textContent']) == 'true',
            properHeading         : getPropTraverse(settingsData, ['properHeading']) == 'true',
            properBannerImage     : getPropTraverse(settingsData, ['properBannerImage']) == 'true',
            clickablePhoneNumbers : getPropTraverse(settingsData, ['clickablePhoneNumbers']) == 'true',
            businessLogo          : getPropTraverse(settingsData, ['businessLogo']) == 'true',
            aboveTheFoldImage     : null,
        }

        this.updateAboveThefoldSettings = this.updateAboveThefoldSettings.bind(this)
        this.onImageChange              = this.onImageChange.bind(this);
    }


    updateAboveThefoldSettings(meta, value) {
        const reportSettings = this.props.reportSettings
        const data = {
            ctaButton             : this.state.ctaButton,
            textContent           : this.state.textContent,
            properHeading         : this.state.properHeading,
            properBannerImage     : this.state.properBannerImage,
            clickablePhoneNumbers : this.state.clickablePhoneNumbers,
            businessLogo          : this.state.businessLogo,
        }
        let reportData     = {}

        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value

        reportData = reportSettings;

        this.props.saveReportSettings()

        if (reportData.aboveTheFoldImageFile) {
            delete(reportData.aboveTheFoldImage)
        }

        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    onImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const reportSettings = this.props.reportSettings
            let imgBase64 = ''

            if (this.validateFile(img)) {
                this.getBase64(img, (result) => {
                     imgBase64 = result;

                     this.setState({
                         aboveTheFoldImage: imgBase64
                     });

                     reportSettings['aboveTheFoldImage'] = imgBase64
                     reportSettings['aboveTheFoldImageFile'] = img
                     this.props.saveReportSettings()
                });
            } else {
                this.resetImage(e)
            }
        }
    }

    resetImage(e){
        const reportSettings = this.props.reportSettings
        this.setState({
            aboveTheFoldImage: '',
        });
        e.target.value = null
        reportSettings['aboveTheFoldImage'] = ''
        reportSettings['aboveTheFoldImageFile'] = ''
        this.props.saveReportSettings()
    }

    validateFile(file) {
        let isValid = true

        if (!this.state.validFileTypes.includes(file.type)) {
            this.showToaster('error', 'Upload Error', 'Invalid File type, only files with the following extensions are allowed: ' + this.state.validFileTypes.join())
            isValid = false
        }

        if (file.size > process.env.REACT_APP_UPLOAD_SIZE_LIMIT) {
            this.showToaster('error', 'Upload Error', 'File size exceeds the allowable limit of ' + (process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000000) + 'MB')
            isValid = false
        }

        return isValid
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    render(){

        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <KeywordRankings token={this.props.token} />
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Above the Fold Section')}</CardTitle>
                            <CardText>{ this.props.t('Upload Image') }</CardText>
                            <CardText className="mb-3">
                                <input type="file" name="aboveTheFoldImage" accept={this.state.validFileTypes.join()} onChange={this.onImageChange} />
                                { (this.state.aboveTheFoldImage != '') && (
                                    <img className="mt-2 img-fluid" src={this.state.aboveTheFoldImage} />
                                )}
                            </CardText>
                            <FileUploadInfo />
                            <CardText>{ this.props.t('To further optimize the above the fold section, the page should have the following (select all that apply)') }</CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="ctaButton"
                                    checked={this.state.ctaButton}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('ctaButton', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="ctaButton"
                                    >
                                    { this.props.t('CTA button') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="textContent"
                                    checked={this.state.textContent}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('textContent', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="textContent"
                                    >
                                    { this.props.t('Text Content') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="properHeading"
                                    checked={this.state.properHeading}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('properHeading', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="properHeading"
                                    >
                                    { this.props.t('Proper Heading') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="properBannerImage"
                                    checked={this.state.properBannerImage}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('properBannerImage', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="properBannerImage"
                                    >
                                    { this.props.t('Proper Banner Image') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="clickablePhoneNumbers"
                                    checked={this.state.clickablePhoneNumbers}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('clickablePhoneNumbers', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="clickablePhoneNumbers"
                                    >
                                    { this.props.t('Clickable Phone Numbers') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="businessLogo"
                                    checked={this.state.businessLogo}
                                    onChange={e => {
                                        this.updateAboveThefoldSettings('businessLogo', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="businessLogo"
                                    >
                                    { this.props.t('Business Logo') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

OnPageSeoSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(OnPageSeoSettings))
