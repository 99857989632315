import {
    COMPONENT_FORM_INIT,
    COMPONENT_FORM_ON_SUBMIT,
    COMPONENT_FORM_ON_SUBMIT_DONE,
} from './actionTypes'

export const componentFormInitAction = (params) => ({
    type: COMPONENT_FORM_INIT,
    payload: { params }
})

export const componentFormOnSubmitAction = (params) => ({
    type: COMPONENT_FORM_ON_SUBMIT,
    payload: { params }
})

export const componentFormOnSubmitDoneAction = (response) => ({
    type: COMPONENT_FORM_ON_SUBMIT_DONE,
    payload: response
})