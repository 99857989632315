import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {buildUrlParams, defaultGetOptions, fetchJSON, getPropTraverse} from 'app.cms.react/helpers/util'
import {AJAX_URL_CODE_TABLE_OPTIONS} from '../actionTypes'
import {CODE_TABLE_OPTIONS} from './actionTypes'
import {codeTableSetOptionsAction} from './actions'
import {exceptionHandlerAction} from 'app.cms.react/store/auth/exceptionHandler/actions'

function* codeTableOptionsSaga({ payload: { options } }) {
    try {
        const url = AJAX_URL_CODE_TABLE_OPTIONS + buildUrlParams(options)
        const response = yield call(fetchJSON, url, defaultGetOptions())
        const data     = getPropTraverse(response, ['response', 'data'])

        yield put(codeTableSetOptionsAction(data, options.table))
    } catch (error) {
        yield put(exceptionHandlerAction(error))
    }
}

export function* watchCodeTableOptions() {
    yield takeEvery(CODE_TABLE_OPTIONS, codeTableOptionsSaga)
}

function* CodeTableSaga() {
    yield all([
        fork(watchCodeTableOptions),
    ])
}


export default CodeTableSaga