import {takeEvery, put, call} from 'redux-saga/effects'
import {fetchJSON, getPropTraverse, defaultGetOptions, buildUrlParams, defaultPutOptions, defaultPostOptions, formatFormData, defaultDeleteOptions} from 'app.cms.react/helpers/util'
import {ACL_PERMISSIONS, ACL_PERMISSIONS_UPDATE, ACL_PERMISSIONS_ADD, ACL_PERMISSIONS_DELETE} from './actionTypes'
import {AJAX_URL_ACL_PERMISSIONS} from 'app.cms.react/store/actionTypes'
import {aclPermissionsDataAction, componentFormOnSubmitDoneAction, exceptionHandlerAction, componentDataTableLoadedAction, componentDataTableLoadingAction} from 'store/actions'

function* aclPermissions({ payload: { params, history } }) {
    try {
        const url = AJAX_URL_ACL_PERMISSIONS + buildUrlParams(params)

        yield put(componentDataTableLoadingAction())

        const response   = yield call(fetchJSON, url, defaultGetOptions())
        const data       = getPropTraverse(response, ['response', 'data'])

        yield put(aclPermissionsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* aclPermissionsUpdate({ payload: { params, meta } }) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.id,
        name: params.name,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_PERMISSIONS, options)
        const statusCode = getPropTraverse(response, ['statusCode']);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclPermissionsAdd({ payload: { params, meta } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        name: params.name,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_PERMISSIONS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData = yield call(fetchJSON, AJAX_URL_ACL_PERMISSIONS, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclPermissionsDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclPermissionsDelete({ payload: { params, meta } }) {
    let options  = defaultDeleteOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.primaryKey.id,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_PERMISSIONS, options)
        const statusCode = getPropTraverse(response, ['statusCode']);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* AclPermissionsSaga() {
    yield takeEvery(ACL_PERMISSIONS, aclPermissions)
    yield takeEvery(ACL_PERMISSIONS_UPDATE, aclPermissionsUpdate)
    yield takeEvery(ACL_PERMISSIONS_ADD, aclPermissionsAdd)
    yield takeEvery(ACL_PERMISSIONS_DELETE, aclPermissionsDelete)
}

export default AclPermissionsSaga