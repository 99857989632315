import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class PageStatusCode extends Component {
    constructor(props) {
        super(props)

        const sectionData   = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []
        const badLinksCount = getPropTraverse(sectionData, ['linksStatusCode', 'bad_links_total']) || 0

        this.state = {
            sectionData      : sectionData,
            badLinksDetected : (badLinksCount > 0),
            status           : 'warning',
            message          : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (!this.state.badLinksDetected) ? 'success' : 'danger'
            let message = (!this.state.badLinksDetected)
                        ? 'Good job! Your website is free from 4xx errors.'
                        : 'Not good! We detected # pages that are not available to users, this will negatively affect traffic if not resolved immediately.'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { page_status_code : message }
            } else {
                reportSummary[status]['page_status_code'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="pageStatusCodeSection" className='mt-4'>
                    <h4>{ this.props.t('4xx Status Code') }</h4>
                    <p>4xx errors often point to a problem on a website. For example, if you have a broken link on a page, and visitors click it, they may see a 4xx error. It's important to regularly monitor these errors and investigate their causes, because they may have a negative impact and signal lower site authority to users.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(PageStatusCode));
