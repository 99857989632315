import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next'
import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import TableRoles from 'app.cms.react/components/Acl/Roles/TableRoles'
import MetaTags from 'react-meta-tags'

class Roles extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.props.t('Roles')}</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Access Control List')} breadcrumbItem={this.props.t('Roles')} />
                        <TableRoles />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Roles.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {

})(Roles))