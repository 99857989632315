import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import FormAlert from '../../FormAlert'
import {Col, Row} from 'reactstrap'
import ButtonLoading from '../../ButtonLoading'
import {accountPasswordUpdateAction, componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/actions'

class FormPassword extends Component {
    constructor(props) {
        super(props)

        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        this.props.accountPasswordUpdateAction({
            current_password: values.current_password,
            password: values.password,
            password_confirmation: values.password_confirmation,
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="current_password"
                                label="Current Password"
                                value=""
                                className="form-control"
                                placeholder="Enter current password"
                                type="password"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 10}}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="password"
                                label="New Password"
                                value=""
                                className="form-control"
                                placeholder="Enter new password"
                                type="password"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 10}}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="password_confirmation"
                                label="Confirm New Password"
                                value=""
                                className="form-control"
                                placeholder="Confirm new password"
                                type="password"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 10}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonLoading formLoading={this.props.formLoading} />
                        </Col>
                    </Row>
                </AvForm>
            </React.Fragment>
        )
    }
}

FormPassword.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    accountPasswordUpdateAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(FormPassword))