import {takeEvery, put, call} from "redux-saga/effects"

// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from './actionTypes'
import { fetchJSON, formatFormData, getPropTraverse, defaultPostOptions, DEFAULT_ERROR_MSG } from 'app.cms.react/helpers/util'
import { AJAX_URL_LOGIN } from 'store/actionTypes'
import { setSession, unsetSession } from '../../../helpers/authUtils'
import {componentFormOnSubmitDoneAction} from '../../components/form/actions'
import {pluckGatedRouteFromName} from 'app.cms.react/helpers/util'
import {pluckRouteFromName} from 'app.cms.react/helpers/util'


function* loginUser({ payload: { params, meta } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        email    : params.email,
        password : params.password,
    });

    try {
        const response = yield call(fetchJSON, AJAX_URL_LOGIN, options);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        setSession(response);
        window.location.assign(pluckGatedRouteFromName('dashboard'))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}


function* logoutUser({payload: {history}}) {
    unsetSession();
    window.location.assign(pluckRouteFromName('login'))
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga