import {PAYMENT_GATEWAY_STRIPE_CHECKOUT} from './actionTypes'
import {takeEvery, call, put} from "redux-saga/effects"

import {defaultPostOptions} from 'app.cms.react/helpers/util'
import {getPropTraverse} from 'app.cms.react/helpers/util'
import {fetchJSON} from 'app.cms.react/helpers/util'

import {AJAX_URL_PAYMENT_GATEWAY_STRIPE_CHECKOUT} from 'app.cms.react/store/actionTypes'
import {formatFormData, objToUrlParams} from 'app.cms.react/helpers/util'
import {PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL} from './actionTypes'
import {AJAX_URL_PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL} from 'app.cms.react/store/actionTypes'
import {pluckGatedRouteFromName} from 'app.cms.react/helpers/util'

function* paymentGatewayStripeCheckout({ payload: {stripe, productId, priceId}}) {
    const objUrlParams = {
        productCode: productId,
        priceCode: priceId,
    }

    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        product_code: productId,
        price_code: priceId,
        success_url: process.env.REACT_APP_BASE_URL + pluckGatedRouteFromName('subscription-plans') + '?status=success&' + objToUrlParams(objUrlParams),
        cancel_url: process.env.REACT_APP_BASE_URL + pluckGatedRouteFromName('subscription-plans') + '?status=cancel&' + objToUrlParams(objUrlParams),
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_PAYMENT_GATEWAY_STRIPE_CHECKOUT, options)
        const statusCode = getPropTraverse(response, ['statusCode']);

        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        stripe.redirectToCheckout({
            sessionId: getPropTraverse(response, ['response', 'data', 'id']),
        });
    } catch (error) {
        console.error(error)
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }
}

function* paymentGatewayStripeCustomerPortal({ payload: {params}}) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        return_url: process.env.REACT_APP_BASE_URL + pluckGatedRouteFromName('subscription')
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL, options)
        const statusCode = getPropTraverse(response, ['statusCode']);

        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data', 'url']),
        }

        window.location.assign(componentResponse.response)
    } catch (error) {
        console.error(error)
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }
}

function* PaymentGatewayStripeSaga() {
    yield takeEvery(PAYMENT_GATEWAY_STRIPE_CHECKOUT, paymentGatewayStripeCheckout)
    yield takeEvery(PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL, paymentGatewayStripeCustomerPortal)
}

export default PaymentGatewayStripeSaga