import {
    PAYMENT_GATEWAY_STRIPE_CHECKOUT
} from './actionTypes'
import {PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL} from './actionTypes'

const INIT_STATE = {
    data: {},
    showSweetAlert: false,
}

const paymentGatewayStripe = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case PAYMENT_GATEWAY_STRIPE_CHECKOUT:
        case PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL:
            return { ...state }
    }

    return { ...state }
}

export default paymentGatewayStripe