import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class Headings extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo'])
        const sectionDataForSeo = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeo']) || false;

        let head  = getPropTraverse(sectionData, ['sitePage', 'header']) || false;
        let head1 = getPropTraverse(sectionData, ['sitePage', 'header', 'h1']) || [];
        let head2 = getPropTraverse(sectionData, ['sitePage', 'header', 'h2']) || [];
        let head3 = getPropTraverse(sectionData, ['sitePage', 'header', 'h3']) || [];
        let head4 = getPropTraverse(sectionData, ['sitePage', 'header', 'h4']) || [];
        let head5 = getPropTraverse(sectionData, ['sitePage', 'header', 'h5']) || [];
        let head6 = getPropTraverse(sectionData, ['sitePage', 'header', 'h6']) || [];

        // if has data for seo
        if (sectionDataForSeo) {
            head  = sectionDataForSeo;
            head1 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h1']) || [];
            head2 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h2']) || [];
            head3 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h3']) || [];
            head4 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h4']) || [];
            head5 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h5']) || [];
            head6 = getPropTraverse(sectionDataForSeo, ['meta', 'htags', 'h6']) || [];
        }

        this.state = {
            sectionData : head,
            h1          : head1,
            h2          : head2,
            h3          : head3,
            h4          : head4,
            h5          : head5,
            h6          : head6,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service',
            headingCount : 0
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary
        let headingCount  = 0

        if (this.state.h1.length > 0) {
            this.state.h1.map((val, key) => {
                if (val.trim().replace(/\n/g, '') != '' && val.toLowerCase() != 'not acceptable!' && val.toLowerCase() != 'access denied') {
                    headingCount++
                }
            })
        }

        if (this.state.sectionData) {
            let status  = (headingCount == 1) ? 'success' : 'danger'
            let message = (headingCount == 1)
                        ? 'headingsGood'
                        : 'Not good. Currently, there are '+ headingCount + ' H1 tags found on your page'

            this.setState({
                status       : status,
                message      : message,
                headingCount : headingCount
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { heading : message }
            } else {
                reportSummary[status]['heading'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="headingsSection" className='mt-4'>
                    <h4>{ this.props.t('Headings') }</h4>
                    <p>{ this.props.t('headingsDescriptionLine1') }</p>
                    <p>{ this.props.t('headingsDescriptionLine2') }</p>

                    <div className='p-3'>
                        <h5>{ this.props.t('Homepage') }:</h5>
                        { this.state.headingCount > 0 && (
                            <table className='table mt-4 mb-0 table-bordered align-middle'>
                                <thead>
                                    <tr>
                                        <th>{ this.props.t('Tag') }</th>
                                        <th>{ this.props.t('Heading') }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>H1</td>
                                        <td>
                                            { this.state.h1.length > 1  ? (
                                            <ul>
                                            { this.state.h1.map((list, key) => (
                                                <React.Fragment key={key}>
                                                    <li>{list}</li>
                                                </React.Fragment>
                                            ))}
                                            </ul>) : this.state.h1
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        <table className='table mt-4 mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.props.t(this.state.message) }</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='table mt-4 mb-0 table-bordered align-middle'>
                            <thead>
                                <tr>
                                    <th>H2</th>
                                    <th>H3</th>
                                    <th>H4</th>
                                    <th>H5</th>
                                    <th>H6</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.h2.length}</td>
                                    <td>{this.state.h3.length}</td>
                                    <td>{this.state.h4.length}</td>
                                    <td>{this.state.h5.length}</td>
                                    <td>{this.state.h6.length}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(Headings))
