import React, {Component} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {AvForm, AvField} from 'availity-reactstrap-validation'
import FormAlert from '../FormAlert'
import ButtonLoading from '../ButtonLoading'
import {componentFormInitAction, componentFormOnSubmitAction, forgotPasswordAction} from 'app.cms.react/store/actions'

const recaptchaRef = React.createRef();
class FormForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            captchaIsValid : true
        }

        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.captchaOnChage = this.captchaOnChage.bind(this)
    }

    captchaOnChage(value) {
        this.setState({
            captchaIsValid: (value !='')
        });
    }

    handleValidSubmit(event, values) {
        const recaptchaValue = (this.props.captchaSitekey) ? recaptchaRef.current.getValue() : true;
        
        if (recaptchaValue != '') {
            this.props.forgotPasswordAction({
                email: values.email,
            }, this.props.history)
    
            this.props.componentFormOnSubmitAction()
        } else {
            this.setState({
                captchaIsValid: false
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification'/>
                    <div className="mb-3">
                        <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>

                    { (typeof this.props.captchaSitekey != 'undefined' && this.props.captchaSitekey != '') ? (
                        <div className="form-group mb-3">
                            <div className={ (this.state.captchaIsValid == false) ? 'is-invalid' : ''}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={this.props.captchaSitekey}
                                    onChange={this.captchaOnChage}
                                />
                            </div>
                            { (this.state.captchaIsValid == false) ? (
                                <div className="invalid-feedback">Captcha is required</div>
                            ) : null }
                        </div>
                    ) : null}

                    <div className="text-end">
                        <ButtonLoading
                            formLoading={this.props.formLoading}
                            buttonName="Send"
                        />
                    </div>
                </AvForm>
            </React.Fragment>
        )
    }
}

FormForgotPassword.propTypes = {
    captchaSitekey: PropTypes.string
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    forgotPasswordAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(FormForgotPassword))