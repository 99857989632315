import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'

class RankingKeywords extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo']) || []

        this.state = {
            organicKeywordCount : getPropTraverse(sectionData, ['rankingKeyword', 'organic_keywords']) || 0
        }
    }

    render() {
        const keywordRankingsImage = (getPropTraverse(this.props.reportSettings, ['keywordRankingsImage']) != 'null') ? getPropTraverse(this.props.reportSettings, ['keywordRankingsImage']) : false
        let organicKeywordCount = getPropTraverse(this.props.reportSettings, ['organicKeywordsCount']) || ''

        if (organicKeywordCount == '') {
            organicKeywordCount = this.state.organicKeywordCount
        }

        return (
            <React.Fragment>
                <div id="rankingKeywordSection" className='mt-4'>
                    <h4>{ this.props.t('Ranking Keywords') }</h4>
                    <p>Currently, the site is ranking for { organicKeywordCount } organic keywords.</p>

                    { keywordRankingsImage && (
                        <img className="my-2 img-fluid" src={keywordRankingsImage} />
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps)(RankingKeywords));
