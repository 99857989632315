import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { withTranslation }  from 'react-i18next'
import { Redirect }         from 'react-router-dom';
import toastr               from "toastr"
import {
    Card,
    Row,
    Col,
    Button
} from 'reactstrap'
import { AvField, AvForm } from "availity-reactstrap-validation"
import { generateReportAction } from '../../store/actions'
import { reportsAction } from '../../store/actions'

import bgImage from "assets/images/profile-img.png"

class GenerateReport extends Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.handleValidSubmit = this.handleValidSubmit.bind(this);

        this.state = {
            redirect : '',
        }
    }

    handleValidSubmit(event, values) {
        const url = values.url || ''
        this.props.generateReportAction({
            url: url.toLowerCase()
        })

        const form = this.formRef.current;

        if (form) {
            form.reset();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.notificationUpdated) {
            this.showToaster('success', 'Basic SEO Report', 'Your report is now ready!')

            this.props.reportsAction()
        }

        if (prevProps.token != this.props.token) {
            this.showToaster('info', 'Basic SEO Report', 'We&apos;ll notify you once the audit report is ready.')
            
            this.props.reportsAction()
        }
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}  />
        }

        return (
            <React.Fragment>
                <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                        <Row>
                            <Col xs="9" lg="10">
                                <div className="text-primary px-3 pt-3">
                                    <h5 className="text-primary">{ this.props.t('Basic SEO Report') }</h5>
                                    <p className="mb-0">{ this.props.t('Scan your prospect websites and generate a basic SEO report.') }</p>
                                </div>
                                <AvForm className="p-3" onValidSubmit={this.handleValidSubmit} ref={this.formRef}>
                                    <Row>
                                        <Col xs="12" sm="7" md="9">
                                            <AvField
                                                name="url"
                                                type="url"
                                                className="form-control p-3"
                                                id="reportUrl"
                                                placeholder="https://www.mywebsite.com"
                                                errorMessage={ this.props.t('Invalid URL') }
                                                validate={{
                                                    required: { value: true },
                                                    url: { value: true },
                                                }}
                                            />
                                        </Col>
                                        <Col xs="12" sm="5" md="3">
                                            <Button type="submit" color="primary" className="w-md p-3" disabled={ this.props.loading } >
                                                { this.props.loading ? (
                                                    <React.Fragment>
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> { this.props.t('Loading') }
                                                    </React.Fragment>
                                                ) : this.props.t('Generate Report')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                            <Col xs="3" lg="2" className="align-self-end">
                                <img src={bgImage} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        token,
        loading
    } = state.Reports

    const {
        notifications,
        notificationUpdated
    } = state.Layout

    return {
        token,
        loading,
        notifications,
        notificationUpdated
    }
}

export default withTranslation()(connect(mapStateToProps, {
    reportsAction,
    generateReportAction
})(GenerateReport))
