export const API_URL = process.env.REACT_APP_API_URL

export const AJAX_URL_LOGIN                    = API_URL + '/login'
export const AJAX_URL_SEND_PASSWORD_RESET_LINK = API_URL + '/password/send-reset-link-email'
export const AJAX_URL_PASSWORD_RESET           = API_URL + '/password/reset'
export const AJAX_URL_REGISTER                 = API_URL + '/register'
export const AJAX_URL_ACL_ROLES                = API_URL + '/acl/roles'
export const AJAX_URL_ACL_PERMISSIONS          = API_URL + '/acl/permissions'
export const AJAX_URL_ACL_USERS                = API_URL + '/acl/users'
export const AJAX_URL_ACCOUNT_PROFILE          = API_URL + '/account/profile'
export const AJAX_URL_ACCOUNT_PASSWORD         = API_URL + '/account/password'
export const AJAX_URL_CODE_TABLE_OPTIONS       = API_URL + '/code-table/options'
export const AJAX_URL_SUBSCRIPTION_MODULE      = API_URL + '/subscription/module'
export const AJAX_URL_SUBSCRIPTION_CHECKER     = API_URL + '/subscription/usage-limit'

export const AJAX_URL_SOCIAL_AUTH_PROVIDER_CALLBACK                       = API_URL + '/social/auth/%s/callback'
export const AJAX_URL_PAYMENT_GATEWAY_STRIPE_CHECKOUT                     = API_URL + '/subscription/stripe/checkout'
export const AJAX_URL_PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL              = API_URL + '/subscription/stripe/customer-portal'
export const AJAX_URL_SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_SUBSCRIPTION = API_URL + '/product/stripe/price/subscription'
export const AJAX_URL_SUBSCRIPTION_PRODUCT_MODULE_STRIPE                  = API_URL + '/subscription/stripe/product-module'

export const AJAX_URL_BROADCAST_NOTIFICATION = API_URL + '/broadcast-notification'