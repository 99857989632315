import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions';
import toastr from "toastr";

class CoverSettings extends Component {
    constructor(props) {
        super(props)
        this.saveChanges = this.saveChanges.bind(this);

        const reportData = this.props.reportData
        let reportTitle  = getPropTraverse(this.props.reportSettings, ['coverTitle']) || ' ';
        let reportUrl    = getPropTraverse(reportData, ['url']);

        if ((reportTitle.trim()).length == 0) {
            reportTitle  = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeo', 'meta', 'title']) || '';
        }

        if (reportTitle == ' ' || reportTitle.trim().toLowerCase() == 'not acceptable!' || reportTitle.trim().toLowerCase() == 'access denied' ) {
            reportTitle = this.sanitizeFilename(reportUrl)
        }

        this.state = {
            title : reportTitle,
            disableSave: false
        }
    }

    saveChanges(self) {
        const reportSettings = this.props.reportSettings
        let reportData       = {}

        const title = self.state.title;
        reportSettings['coverTitle'] = title;

        reportData = reportSettings;

        this.props.saveReportSettings()

        this.props.saveReportAction({ ...reportData, token: this.props.token});

        this.showToaster('success', 'Cover Changed', 'Title Successfully saved')
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    sanitizeFilename(str) {
        str = (str) ? str.replace('https://', '').replace('http://', '') : str;
        if (str && str.endsWith('/')) {
            str = str.slice(0, -1)
        }

        return str
    }

    onChangeTitle(val) {
        this.setState({
            title: val
        })
    }

    render(){
        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Title')}</CardTitle>
                            <input id="cover_title_text"
                                   placeholder="Cover title"
                                   type="text"
                                   onChange={e => {this.onChangeTitle(e.target.value); }}
                                   className="form-control"
                                   disabled={this.state.disableSave}
                                   value={this.state.title}/>
                                   <br/>
                            <button className="btn btn-primary mx-1"
                                    disabled={this.state.disableSave}
                                    onClick={(e) => { this.saveChanges(this)}}>
                                { this.props.t('Save') }
                            </button>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

CoverSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(CoverSettings))
