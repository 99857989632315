import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText, Input, FormFeedback, Tooltip  } from 'reactstrap'

//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'

class IndexingAndCrawlabilitySettings extends Component {
    constructor(props) {
        super(props)

        const settingsData    = this.props.reportSettings
        const httpsVersionData    = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability', 'httpsVersion']) || []
        let indexedPagesCount = getPropTraverse(settingsData, ['indexedPagesCount']) || '';
        let httpVersion       = getPropTraverse(settingsData, ['httpVersion']) || '';
        let httpVersionIsOk   = false
        let httpsVersionIsOk  = false

        if (indexedPagesCount == '') {
            indexedPagesCount = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability', 'indexStatus', 'index_count']) || 0
        }

        if (httpVersion == '') {
            httpVersionIsOk  = getPropTraverse(httpsVersionData, ['http', 'statusCode']) == 200
            httpsVersionIsOk = getPropTraverse(httpsVersionData, ['https', 'statusCode']) == 200
            httpVersion      = (httpVersionIsOk && httpsVersionIsOk) ? 'success' : 'danger'
        }

        this.state = {
            indexedPagesCount : indexedPagesCount,
            httpVersion       : httpVersion,
            validate: {
                indexedPagesCount: '',
            },
            tooltipOpen : false
        }

        this.updateReportSettings          = this.updateReportSettings.bind(this)
        this.handleindexedPagesCountChange = this.handleindexedPagesCountChange.bind(this)
        this.toggle                        = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    updateReportSettings(meta, value) {
        const reportSettings = this.props.reportSettings
        const data = {
            indexedPagesCount : this.state.indexedPagesCount,
        }
        let reportData     = {}

        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value

        reportData = reportSettings;

        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    handleindexedPagesCountChange = (event) => {
        const { target }   = event;
        const value        = target.type === 'checkbox' ? target.checked : target.value;
        const { validate } = this.state;

        if (value >= 0) {
            validate.indexedPagesCount = 'has-success';
        } else {
            validate.indexedPagesCount = 'has-danger';
        }

        this.setState({ validate });
    }

    render(){

        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Enter no. of pages indexed by Google')} <span className='text-info' href="#" id="UncontrolledTooltipExample"><i className='fas fa-info-circle'></i></span></CardTitle>
                            <Input
                                id="indexed_pages_count"
                                type="number"
                                placeholder={ this.props.t('Indexed Pages Count') }
                                value={ this.state.indexedPagesCount }
                                invalid={ this.state.validate.indexedPagesCount === 'has-danger' }
                                onChange={e => {
                                    this.handleindexedPagesCountChange(e)
                                    this.updateReportSettings('indexedPagesCount', e.target.value)
                                }}/>
                            <FormFeedback>
                                {this.props.t('You are allow to enter only numeric value. No Alphabetic and special character allowed.')}
                            </FormFeedback>
                            <Tooltip placement="top" isOpen={this.state.tooltipOpen} toggle={this.toggle} target="UncontrolledTooltipExample">
                                {this.props.t('You are allow to enter only numeric value. No Alphabetic and special character allowed.')}
                            </Tooltip>
                        </CardBody>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('HTTP/HTTPS Versions')}</CardTitle>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="successHttps"
                                    checked={this.state.httpVersion == 'success'}
                                    onChange={e => {
                                        this.updateReportSettings('httpVersion', 'success')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="successHttps"
                                    >
                                    { this.props.t('Low') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="dangerHttps"
                                    checked={this.state.httpVersion == 'danger'}
                                    onChange={e => {
                                        this.updateReportSettings('httpVersion','danger')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="dangerHttps"
                                    >
                                    { this.props.t('High') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

IndexingAndCrawlabilitySettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(IndexingAndCrawlabilitySettings))
