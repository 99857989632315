import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class HttpVersions extends Component {
    constructor(props) {
        super(props)

        const sectionData      = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability']) || []
        const httpVersionIsOk  = getPropTraverse(sectionData, ['httpsVersion', 'http', 'statusCode']) == 200
        const httpsVersionIsOk = getPropTraverse(sectionData, ['httpsVersion', 'https', 'statusCode']) == 200
        let httpVersion        = getPropTraverse(this.props.reportSettings, ['httpVersion']) || ((httpVersionIsOk && httpsVersionIsOk) ? 'success' : 'danger')
        let status             = 'warning'
        let message            = 'Error: An error occurred while calling the endpoint service'

        if (httpVersion != null) {
            status  = httpVersion
            message = (status == 'success')
                    ? 'Good job! There are no issues with HTTP/HTTPS versions of your website.'
                    : 'Not good! There are pages on the site that load content over an unsecured connection (HTTP).'
        }

        this.state = {
            sectionData      : sectionData,
            httpVersionIsOk  : httpVersionIsOk,
            httpsVersionIsOk : httpsVersionIsOk,
            status           : status,
            message          : message
        }
    }

    componentDidUpdate(prevProps) {
        const httpVersion = getPropTraverse(this.props.reportSettings, ['httpVersion']) || null
        if (typeof this.props.reportSettings.httpVersion != 'undefined' && httpVersion != this.state.status) {
            this.updateReportSummary()
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary
        const sectionData      = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability']) || []
        const httpVersionIsOk  = getPropTraverse(sectionData, ['httpsVersion', 'http', 'statusCode']) == 200
        const httpsVersionIsOk = getPropTraverse(sectionData, ['httpsVersion', 'https', 'statusCode']) == 200
        let httpVersion        = getPropTraverse(this.props.reportSettings, ['httpVersion']) || ((httpVersionIsOk && httpsVersionIsOk) ? 'success' : 'danger')
        let status             = 'warning'
        let message            = 'Error: An error occurred while calling the endpoint service'

        if (httpVersion != null) {
            status  = httpVersion
            message = (status == 'success')
                    ? 'Good job! There are no issues with HTTP/HTTPS versions of your website.'
                    : 'Not good! There are pages on the site that load content over an unsecured connection (HTTP).'
        }
        
        this.setState({
            status  : status,
            message : message
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['http_version']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['http_version']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['http_version']
        }

        if (typeof reportSummary[status] == 'undefined') {
            reportSummary[status] = { http_version : message }
        } else {
            reportSummary[status]['http_version'] = message
        }

        this.props.saveReportSummary(reportSummary)
    }

    render() {
        return (
            <React.Fragment>
                <div id="httpVersionSection" className='mt-4'>
                    <h4>{ this.props.t('HTTP/HTTPS Versions') }</h4>
                    <p>If your website's HTTP and HTTPS versions are not set properly, search engines can index both versions that may result in duplicate content issues and undermine your website rankings.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
};

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(HttpVersions))
