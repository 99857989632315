module.exports = {
    google: {
        API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
        CLIENT_ID:'',
        SECRET: '',
    },
    facebook: {
        APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    },
    stripe: {
        APP_KEY: process.env.REACT_APP_STRIPE_APP_KEY,
        APP_SECRET: process.env.REACT_APP_STRIPE_APP_SECRET,
    }
}