import {
    COMPONENT_DDLB_ON_HANDLE_CHANGE,
    COMPONENT_DDLB_MULTI_ON_HANDLE_CHANGE,
} from './actionTypes'

const INIT_STATE = {}

const componentDdlb = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case COMPONENT_DDLB_ON_HANDLE_CHANGE:
            return {
                ...state,
                ddlb: {
                    label: (payload != null && typeof payload != 'undefined') ? payload.label : '',
                    value: (payload != null && typeof payload != 'undefined') ? payload.value : '',
                }
            }
        case COMPONENT_DDLB_MULTI_ON_HANDLE_CHANGE:
            return {
                ...state,
                ddlb: payload
            }
    }

    return {...state}
}

export default componentDdlb