import {
    BROADCAST_NOTIFICATION,
    BROADCAST_NOTIFICATION_DATA,
} from './actionTypes'

export const broadcastNotificationAction = (params) => ({
    type: BROADCAST_NOTIFICATION,
    payload: { params }
})

export const broadcastNotificationDataAction = (data) => ({
    type: BROADCAST_NOTIFICATION_DATA,
    payload: { data },
})