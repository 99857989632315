import React, {Component} from 'react'
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Breadcrumbs from 'components/Common/Breadcrumb'
import {Container, Row, Col, Card, CardBody} from 'reactstrap'
import MetaTags from 'react-meta-tags'
import FormPassword from 'app.cms.react/components/Account/Password/FormPassword'

class Password extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.props.t('Password')}</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Account')} breadcrumbItem={this.props.t('Password')} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormPassword />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Password.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(Password))