import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class AboveTheFold extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ctaButton             : null,
            textContent           : null,
            properHeading         : null,
            properBannerImage     : null,
            clickablePhoneNumbers : null,
            businessLogo          : null,
            haveIssues            : null,
            status                : null,
            message               : null
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    componentDidUpdate(prevProps, prevState) {
        const ctaButton                = getPropTraverse(prevProps.reportSettings, ['ctaButton']) == 'true' || getPropTraverse(prevProps.reportSettings, ['ctaButton']) == true
        const textContent              = getPropTraverse(prevProps.reportSettings, ['textContent']) == 'true' || getPropTraverse(prevProps.reportSettings, ['textContent']) == true
        const properHeading            = getPropTraverse(prevProps.reportSettings, ['properHeading']) == 'true' || getPropTraverse(prevProps.reportSettings, ['properHeading'])== true
        const properBannerImage        = getPropTraverse(prevProps.reportSettings, ['properBannerImage']) == 'true' || getPropTraverse(prevProps.reportSettings, ['properBannerImage']) == true
        const clickablePhoneNumbers    = getPropTraverse(prevProps.reportSettings, ['clickablePhoneNumbers']) == 'true' || getPropTraverse(prevProps.reportSettings, ['clickablePhoneNumbers']) == true
        const businessLogo             = getPropTraverse(prevProps.reportSettings, ['businessLogo']) == 'true' || getPropTraverse(prevProps.reportSettings, ['businessLogo']) == true

        if ((this.state.ctaButton != null && ctaButton != this.state.ctaButton) ||
            (this.state.textContent != null && textContent != this.state.textContent) ||
            (this.state.properHeading != null && properHeading != this.state.properHeading) ||
            (this.state.properBannerImage != null && properBannerImage != this.state.properBannerImage) ||
            (this.state.clickablePhoneNumbers != null && clickablePhoneNumbers != this.state.clickablePhoneNumbers) ||
            (this.state.businessLogo != null && businessLogo != this.state.businessLogo)
        ) {
            this.updateReportSummary()
        }
    }

    updateReportSummary() {
        const ctaButton                = getPropTraverse(this.props.reportSettings, ['ctaButton']) == 'true' || getPropTraverse(this.props.reportSettings, ['ctaButton']) == true
        const textContent              = getPropTraverse(this.props.reportSettings, ['textContent']) == 'true' || getPropTraverse(this.props.reportSettings, ['textContent']) == true
        const properHeading            = getPropTraverse(this.props.reportSettings, ['properHeading']) == 'true' || getPropTraverse(this.props.reportSettings, ['properHeading'])== true
        const properBannerImage        = getPropTraverse(this.props.reportSettings, ['properBannerImage']) == 'true' || getPropTraverse(this.props.reportSettings, ['properBannerImage']) == true
        const clickablePhoneNumbers    = getPropTraverse(this.props.reportSettings, ['clickablePhoneNumbers']) == 'true' || getPropTraverse(this.props.reportSettings, ['clickablePhoneNumbers']) == true
        const businessLogo             = getPropTraverse(this.props.reportSettings, ['businessLogo']) == 'true' || getPropTraverse(this.props.reportSettings, ['businessLogo']) == true
        const haveIssues               = (ctaButton || textContent || properHeading || properBannerImage || clickablePhoneNumbers || businessLogo)
        const ctaButtonMsg             = 'A CTA button'
        const textContentMsg           = 'Text content'
        const properHeadingMsg         = 'A proper heading that discusses what the page is about'
        const properBannerMsg          = 'A proper banner Image'
        const clickablePhoneNumbersMsg = 'Clickable phone numbers'
        const businessLogoMsg          = 'Business Logo'
        let status                     = 'success'
        let message                    = 'aboveTheFoldGood'
        let msgSummary                 = 'aboveTheFoldGood'
        let reportSummary              = this.props.reportSummary
        let summaryList                = []

        if (haveIssues) {
            status  = 'danger'
            message = (
                <React.Fragment>
                    <p>Currently, the homepage does not have:</p>
                    <ul>
                        { ctaButton && <li>{ ctaButtonMsg }</li> }
                        { textContent && <li>{ textContentMsg }</li> }
                        { properHeading && <li>{ properHeadingMsg }</li> }
                        { properBannerImage && <li>{ properBannerMsg }</li> }
                        { clickablePhoneNumbers && <li>{ clickablePhoneNumbersMsg }</li> }
                        { businessLogo && <li>{ businessLogoMsg }</li> }
                    </ul>
                </React.Fragment>
            )
            msgSummary = 'Currently, the homepage does not have'
            
            if (ctaButton) {
                summaryList.push(ctaButtonMsg)
            }
            if (textContent) {
                summaryList.push(textContentMsg)
            }
            if (properHeading) {
                summaryList.push(properHeadingMsg)
            }
            if (properBannerImage) {
                summaryList.push(properBannerMsg)
            }
            if (clickablePhoneNumbers) {
                summaryList.push(clickablePhoneNumbersMsg)
            }
            if (businessLogo) {
                summaryList.push(businessLogoMsg)
            }
        }

        this.setState({
            ctaButton             : ctaButton,
            textContent           : textContent,
            properHeading         : properHeading,
            properBannerImage     : properBannerImage,
            clickablePhoneNumbers : clickablePhoneNumbers,
            businessLogo          : businessLogo,
            haveIssues            : haveIssues,
            status                : status,
            message               : message
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['above_the_fold']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['above_the_fold']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['above_the_fold']
        }

        reportSummary[status]['above_the_fold'] = {
            message : '',
            list_before: msgSummary,
            list: summaryList
        };

        this.props.saveReportSummary(reportSummary)
    }

    render() {
        const aboveTheFoldImage  = (getPropTraverse(this.props.reportSettings, ['aboveTheFoldImage']) != 'null') ? getPropTraverse(this.props.reportSettings, ['aboveTheFoldImage']) : false

        return (
            <React.Fragment>
                <div id="aboveTheFoldSection" className='mt-4'>
                    <h4>{ this.props.t('Above-the-Fold Page Section') }</h4>
                    <p>The above the fold section of a site is typically the first thing to load, the first area that search bots crawl and the first thing users see.</p>
                    <p>{ this.props.t('Below is a screenshot of the above-the-fold section of the homepage') }</p>

                    { aboveTheFoldImage && (
                        <img className="my-2 img-fluid" src={aboveTheFoldImage} />
                    )}

                    { (this.state.status != null ?
                        <div className='p-3'>
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className="text-center fw-50"><ReportIcon type={this.state.status} /></th>
                                        <td>{ this.state.haveIssues ? this.state.message : this.props.t(this.state.message) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    : ''
                    )}
                    
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(AboveTheFold));
