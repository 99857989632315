import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class MobileFriendlyCheck extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []

        this.state = {
            sectionData      : sectionData,
            isMobileFriendly : getPropTraverse(sectionData, ['mobileFriendly', 'mobile_friendliness']) == 'MOBILE_FRIENDLY',
            status           : 'warning',
            message          : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (this.state.isMobileFriendly) ? 'success' : 'danger'
            let message = (this.state.isMobileFriendly)
                        ? 'Good job! Your site is mobile-friendly.'
                        : 'Not good! The page appears not mobile-friendly. Your mobile users may have difficulty reading your page and finding the necessary information and would likely abandon the website.'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { mobile_friendly_check : message }
            } else {
                reportSummary[status]['mobile_friendly_check'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="mobileFriendlySection" className='mt-4'>
                    <h4>{ this.props.t('Mobile Friendly Check') }</h4>
                    <p>Google mobile-first algorithm updates ensuress that mobile-friendly websites are ranked higher over those that taren’t. This update now uses the website’s mobile version, and not its desktop version, for its indexing, crawling, and ranking system.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(MobileFriendlyCheck));
