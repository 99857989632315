import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';
import toastr from "toastr";

let changeTimeout = null;

class SchemaMarkupSetting extends Component {
    constructor(props) {
        super(props)
        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePage', 'schema']) || false;
        let setting       = getPropTraverse(this.props, ['reportSettings', 'jsonLdSchema']) || '';

        let item    = getPropTraverse(sectionData, ['jsonld', 'item']) || [];
        let newItem = [];

        try {
            if (setting && setting !== '') {
                item = JSON.parse(setting);
            }
        } catch (e) {}

        if (item.length > 0) {
            item.map((el, index) => {
                newItem[index] = JSON.stringify(el, null, 2)
            })
        } else {
            newItem.push('{}')
        }

        this.submitJsonSchema = this.submitJsonSchema.bind(this);
        this.onChangeSchema   = this.onChangeSchema.bind(this);

        this.state = {
            hasScrapedData : (Object.keys(sectionData).length),
            jsonld         : item,
            total          : getPropTraverse(sectionData, ['jsonld', 'count']) || 0,
            loading        : false,
            validJson      : true,
            newItem        : newItem
        }
    }

    addSchema(e, self) {
        const newItem = self.state.newItem || [];
        newItem.push('{}');
        self.setState({
            newItem
        })
    }

    removeSchema(index, self) {
        const newItem = self.state.newItem || [];
        newItem.splice(index, 1);
        self.setState({
            newItem,
            validJson: true
        })
    }

    submitJsonSchema() {
        const newItem = this.state.newItem || [];
        let toJson    = [];
        let valid     = true;
        const self    = this;

        try {
            if (newItem.length > 0) {
                newItem.map((value, index) => {
                    if (value === ''){
                        valid = false;
                        return false;
                    }

                    toJson[index] = JSON.parse(value);
                });
            }
        } catch (e) {
            valid = false;
        }


        if (valid) {
            this.setState({
                loading: true
            });

            this.updateSchema(toJson);
        } else {
            this.showToaster('error', 'JSON-LD Schema Markup', 'JSON not valid');
        }
    }

    updateSchema(toJson) {
        const reportSettings = this.props.reportSettings
        let reportData       = {}

        reportSettings['jsonLdSchema'] = JSON.stringify(toJson);

        reportData = reportSettings;

        this.props.saveReportSettings()

        this.props.saveReportAction({ ...reportData, token: this.props.token})

        this.setState({
            loading: false
        });

        this.showToaster('success', 'JSON-LD Schema Markup', 'Successfully saved')
    }

    onChangeSchema(index, value, self) {
        let newItem = self.state.newItem;
        let validJson = true;

        try {
            if (typeof self.state.jsonld[index] !== 'undefined') {
                const json = JSON.parse(value);
            }
        } catch (e) {
            validJson = false;
        }

        if (newItem[index]) {
            newItem[index] = (value && value !== '') ? value : '{}';
        }

        self.setState({
            newItem,
            validJson
        });

    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    render(){

        return (
            <React.Fragment>
                <div className="accordion-body">
                    { this.state.newItem.map( (list, key) => (
                        <React.Fragment  key={key}>
                            <AceEditor
                                mode="json"
                                theme="github"
                                name="jsonLD"
                                width={'460px'}
                                height={'250px'}
                                editorProps={{$blockScrolling: true}}
                                value={list}
                                onChange={(newValue) => { this.onChangeSchema(key, newValue, this) }}
                            />
                            <br/>
                            <button className="btn btn-danger"
                                    onClick={(e) => { this.removeSchema(key, this)}}
                                    disabled={this.state.loading}
                            >
                                { this.props.t('Remove') }
                            </button>
                            <br/><br/>

                        </React.Fragment>
                    )) }

                    <h2 className="accordion-header">
                        <button className="btn btn-info"
                                onClick={this.submitJsonSchema}
                                disabled={!this.state.validJson || this.state.loading}
                        >
                            { this.state.loading ? (
                                <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> { this.props.t('Loading') }
                                </React.Fragment>
                            ) : this.props.t('Save')}
                        </button>

                        <button className="btn btn-primary mx-1"
                                onClick={(e) => { this.addSchema(e, this)}}
                                disabled={this.state.loading}
                        >
                            { this.props.t('Add Schema') }
                        </button>
                    </h2>
                </div>
            </React.Fragment>
        )
    }
}

SchemaMarkupSetting.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(SchemaMarkupSetting))
