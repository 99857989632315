import {
    LOGIN_USER,
    LOGOUT_USER,
} from './actionTypes'

const INIT_STATE = {}

const login = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case LOGIN_USER:
            return { ...state }
        case LOGOUT_USER:
            return { ...state }
    }

    return { ... state }
}

export default login
