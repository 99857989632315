import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import PlaceholderEmptyState from './PlaceholderEmptyState'
import {Spinner} from 'reactstrap'

class DataTableLoader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.data.length == 0 && this.props.isDataTableLoading == false) ? (<PlaceholderEmptyState />) : (
                    (this.props.isDataTableLoading) ? (
                        <div className="text-center"><Spinner className="m-2" color="primary" /></div>
                    ) : (
                        this.props.dataTable
                    )
                )}
            </React.Fragment>
        )
    }
}

DataTableLoader.propTypes = {
    dataTable: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    isDataTableLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
})(DataTableLoader))