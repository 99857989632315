import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next'

import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import TableReports from '../../components/Report/TableReports'
import GenerateReport from '../../components/Report/GenerateReport'

class ReportList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Reports')} breadcrumbItem={this.props.t('Basic SEO Report')} />
                        <Row>
                            <Col>
                                <GenerateReport />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <TableReports />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(ReportList)
