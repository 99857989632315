import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class AnchorsCloud extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'offPageSeo', 'anchorCloud', 'anchor_text'])

        this.state = {
            sectionData     : sectionData,
            anchorTextCount : getPropTraverse(sectionData, ['headers', 'total_results']) || 0,
            anchorTextList  : getPropTraverse(sectionData, ['data']) || [],
            status          : 'warning',
            message         : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (this.state.anchorTextCount) ? 'success' : 'danger'
            let message = (this.state.anchorTextCount)
                        ? 'The brand and/or domain name must dominate the anchors cloud distribution in order to rank other relevant keywords.'
                        : 'Not good! The brand and/or domain name must dominate the anchors cloud distribution in order to rank other relevant keywords.'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { anchor_text : message }
            } else {
                reportSummary[status]['anchor_text'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        const anchorsImage  = (getPropTraverse(this.props.reportSettings, ['anchorsImage']) != 'null') ? getPropTraverse(this.props.reportSettings, ['anchorsImage']) : false
        return (
            <React.Fragment>
                <div id="anchorCloudSection" className='mt-4'>
                    <h4>{ this.props.t('Anchors cloud') }</h4>
                    <p>The brand name and the naked URL must dominate the anchors cloud. Once these are visible, it should target terms that are relevant to its business.</p>
                    { anchorsImage && (
                        <img className="my-2 img-fluid" src={anchorsImage} />
                    )}
                    <div className='p-3'>
                        <table className='table mb-3 table-bordered align-middle'>
                            <thead>
                                <tr>
                                    <th>{ this.props.t('Anchors')}</th>
                                    <th className='text-center'>{ this.props.t('Referring Domains') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.anchorTextList.map( list => (
                                    <tr key={list.anchor_text}>
                                        <td>{ list.anchor_text }</td>
                                        <td className="text-center">{ list.ref_domains }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(AnchorsCloud))
