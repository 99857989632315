import PropTypes            from 'prop-types'
import React, { Component } from 'react'
//i18n
import { withTranslation } from "react-i18next"
import SocialMediaSettings from "./SocialMedia";

class ReputationSignals extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <SocialMediaSettings token={this.props.token}/>
            </React.Fragment>
        )
    }
}

ReputationSignals.propTypes = {
    token: PropTypes.any
}

export default withTranslation()(ReputationSignals)
