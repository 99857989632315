import React, {Component}      from 'react'
import {connect}               from "react-redux";
import Cover                   from '../../components/Report/Sections/Cover'
import TableOfContent          from '../../components/Report/Sections/TableOfContent'
import IndexingAndCrawlability from '../../components/Report/Sections/IndexingAndCrawlability/Main'
import TechnicalSeo            from '../../components/Report/Sections/TechnicalSeo/Main'
import OnPageSeo               from '../../components/Report/Sections/OnPageSeo/Main'
import OffPageSeo              from '../../components/Report/Sections/OffPageSeo/Main'
import ReputationSignals       from '../../components/Report/Sections/ReputationSignals/Main'
import SchemaMarkup            from '../../components/Report/Sections/SchemaMarkup'
import Summary                 from '../../components/Report/Sections/Summary'
import Recommendations         from '../../components/Report/Sections/Recommendations'
import { getPropTraverse }     from '../../app.cms.react/helpers/util'
import { withTranslation }     from "react-i18next"
import moment                  from 'moment';

class Report extends Component {
    constructor(props) {
        super(props)
    }

    sanitizeFilename(str) {
        str = str.replace('https://', '').replace('http://', '')
        if (str.endsWith('/')) {
            str = str.slice(0, -1)
        }

        return str
    }

    render() {
        const reportData          = this.props.reportData
        const dateGenerated       = getPropTraverse(this.props.reportData, ['status', 'created_date']) || ''
        const websiteOptimization = getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == 'true' || getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == true
        const organicSeo          = getPropTraverse(this.props.reportSettings, ['organicSeo']) == 'true' || getPropTraverse(this.props.reportSettings, ['organicSeo']) == true
        const pageBreak           = {
            pageBreakAfter: 'always'
        }

        let reportTitle  = getPropTraverse(this.props.reportSettings, ['coverTitle']) || false;
        let reportUrl    = getPropTraverse(reportData, ['url']);

        if (!reportTitle || reportTitle === '') {
            reportTitle = getPropTraverse(reportData, ['report', 'onPageSeo', 'sitePageDataForSeo', 'meta', 'title']) || '';
        }

        if (reportTitle == '' || reportTitle.trim().toLowerCase() == 'not acceptable!' || reportTitle.trim().toLowerCase() == 'access denied' ) {
            reportTitle = this.sanitizeFilename(reportUrl)
        }

        if (reportUrl.endsWith('/')) {
            reportUrl = reportUrl.slice(0, -1)
        }

        return (
            <React.Fragment>
                <Cover />
                <p style={pageBreak}></p>
                <div className="top-left-content-title d-none">
                    { reportTitle }
                </div>
                <div className="top-left-content-url d-none">
                    { reportUrl }
                </div>
                <div className="top-right-content-title d-none">
                    SEO Site Audit Report
                </div>
                <div className="top-right-content-date d-none">
                    { (dateGenerated) ? moment(dateGenerated).format('MMMM DD, YYYY') : '' }
                </div>
                <div id="report-body">
                    <TableOfContent />
                    <p style={pageBreak}></p>
                    <div id="summarySection">
                        <Summary />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="indexingAndCrawlabilitySection">
                        <IndexingAndCrawlability />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="technicalSeoSection">
                        <TechnicalSeo />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="onPageSeoSection">
                        <OnPageSeo />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="offPageSeoSection">
                        <OffPageSeo />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="reputationSignalsSection">
                        <ReputationSignals />
                    </div>
                    <p style={pageBreak}></p>
                    <div id="schemaMarkupSection">
                        <SchemaMarkup />
                    </div>
                    
                    { (websiteOptimization && organicSeo) && (
                        <React.Fragment>
                            <p style={pageBreak}></p>
                            <div id="recommendationSection">
                                <Recommendations />
                            </div>
                        </React.Fragment>
                    ) }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSettings
    }
}
export default withTranslation()(connect(mapStateToProps)(Report))
