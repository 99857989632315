import {takeEvery, put, call } from 'redux-saga/effects'
import {fetchJSON, getPropTraverse, defaultGetOptions, buildUrlParams, defaultPutOptions, defaultPostOptions, defaultDeleteOptions, formatFormData} from 'app.cms.react/helpers/util'
import {ACL_ROLES, ACL_ROLES_UPDATE, ACL_ROLES_ADD, ACL_ROLES_DELETE} from './actionTypes'
import {AJAX_URL_ACL_ROLES} from 'app.cms.react/store/actionTypes'
import {aclRolesDataAction,componentFormOnSubmitDoneAction, exceptionHandlerAction, componentDataTableLoadedAction, componentDataTableLoadingAction} from 'store/actions'

function* aclRoles({ payload: { params, history } }) {
    try {
        const url = AJAX_URL_ACL_ROLES + buildUrlParams(params);

        yield put(componentDataTableLoadingAction())

        const response   = yield call(fetchJSON, url, defaultGetOptions());
        const statusCode = getPropTraverse(response, ['statusCode']);
        const data       = getPropTraverse(response, ['response', 'data']);


        yield put(aclRolesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* aclRolesUpdate({ payload: { params, meta } }) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.id,
        name: params.name,
        permissions: params.permissions
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_ROLES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData  = yield call(fetchJSON, AJAX_URL_ACL_ROLES, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclRolesDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclRolesAdd({ payload: { params, meta } }) {
    let options  = defaultPostOptions();
    let componentResponse = {}

    options.body = formatFormData({
        name: params.name,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_ROLES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData  = yield call(fetchJSON, AJAX_URL_ACL_ROLES, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclRolesDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* aclRolesDelete({ payload: { params, meta } }) {
    let options  = defaultDeleteOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.primaryKey.id,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACL_ROLES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }

        const responseData  = yield call(fetchJSON, AJAX_URL_ACL_ROLES, defaultGetOptions())
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(aclRolesDataAction(data))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* AclRolesSaga() {
    yield takeEvery(ACL_ROLES, aclRoles)
    yield takeEvery(ACL_ROLES_UPDATE, aclRolesUpdate)
    yield takeEvery(ACL_ROLES_ADD, aclRolesAdd)
    yield takeEvery(ACL_ROLES_DELETE, aclRolesDelete)
}

export default AclRolesSaga