import {
    COMPONENT_FORM_INIT,
    COMPONENT_FORM_ON_SUBMIT,
    COMPONENT_FORM_ON_SUBMIT_DONE,
} from './actionTypes'
import {getPropTraverse} from '../../../helpers/util'

const INIT_STATE = {
    response: '',
    alertType: '',
    formLoading: false,
    formSubmit: false,
    disableFields: false,
}

const componentForm = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case COMPONENT_FORM_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
                formLoading: false,
                formSubmit: false,
                disableFields: false,
            }
        case COMPONENT_FORM_ON_SUBMIT:
            return {
                ...state,
                formLoading: true,
                disableFields: true,
            }
        case COMPONENT_FORM_ON_SUBMIT_DONE:
            return {
                ...state,
                response: (getPropTraverse(payload, ['response']) != null) ? getPropTraverse(payload, ['response']) : INIT_STATE.response,
                alertType: (getPropTraverse(payload, ['alertType']) != null) ? getPropTraverse(payload, ['alertType']) : INIT_STATE.alertType,
                formLoading: false,
                formSubmit: true,
                disableFields: false,
            }
    }

    return {...state}
}

export default componentForm