import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class WwwVersions extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability']) || []
        const wwwRedirect = getPropTraverse(sectionData, ['wwwVersion', 'www_redirect']) || getPropTraverse(sectionData, ['wwwVersion', 'www_redirect'])

        this.state = {
            sectionData    : sectionData,
            wwwVersionIsOk : wwwRedirect,
            status         : 'warning',
            message        : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            const status  = (this.state.wwwVersionIsOk) ? 'success' : 'danger'
            const message = (this.state.wwwVersionIsOk) ? 'Good job! WWW and non-WWW versions on your website are already fixed.' : (
                <div>
                    <p>Not good! We found different domains of your website. Google will consider one of your domains as duplicate that can affect rankings and traffic.</p>
                    <p className="mb-0">Please do a 301 redirect on https://non-indexed-url to https://indexed-url, which is the domain being indexed by Google.</p>
                </div>
            )

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { www_versions : message }
            } else {
                reportSummary[status]['www_versions'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="wwwVersionSection" className='mt-4'>
                    <h4>{ this.props.t('WWW and non-WWW Versions') }</h4>
                    <p>Usually, websites are available with and without "www" in the domain name. This issue is quite common, and people link to either or both www and non-www versions. Fixing this will prevent search engines from indexing two versions</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(WwwVersions))
