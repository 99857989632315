import React, { Component } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"
import { pluckRouteFromName } from '../../app.cms.react/helpers/util'

class ActivityComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-5 h4">Activity</CardTitle>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"/>
                </div>
                <Media>
                  <div className="me-3">
                    <h5 className="font-size-14">
                      12 MAR{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"/>
                    </h5>
                  </div>
                  <Media body>
                    <div>Generated "SEO Site Audit Report - https://www.seoreseller.com" Report</div>
                  </Media>
                </Media>
              </li>
              <li className="event-list active">
                <div className="event-timeline-dot">
                  <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right"/>
                </div>
                <Media>
                  <div className="me-3">
                    <h5 className="font-size-14">
                      13 MAR{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"/>
                    </h5>
                  </div>
                  <Media body>
                    <div>Generating "SEO Site Audit Report - https://www.seoreseller.com" Report</div>
                  </Media>
                </Media>
              </li>
            </ul>
            <div className="text-center mt-4">
              <Link
                to={ pluckRouteFromName('reports') }
                className="btn btn-primary btn-sm"
              >
                View More <i className="mdi mdi-arrow-right ms-1"/>
              </Link>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default ActivityComp
