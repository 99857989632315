import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'
import KeywordRankings    from './KeywordRankings'
import FileUploadInfo     from './FileUploadInfo'
import toastr             from "toastr"


class OffPageSeoSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            validFileTypes : ['image/png', 'image/jpg', 'image/jpeg'],
            backLinkImage: null,
            anchorsImage: null
        }

        this.onImageChange = this.onImageChange.bind(this);
    }


    onImageChange(e, imageType) {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const reportSettings = this.props.reportSettings
            let imgBase64 = ''

            if (this.validateFile(img)) {
                this.getBase64(img, (result) => {
                    imgBase64 = result;
                    let reportData     = {}

                    if (imageType === 'backlink') {
                        this.setState({
                            backLinkImage: imgBase64
                        });
                        //
                        reportSettings['backlinkImage'] = imgBase64;
                        reportSettings['backlinkImageFile'] = img;
                    } else {
                        this.setState({
                            anchorsImage: imgBase64
                        });

                        reportSettings['anchorsImage'] = imgBase64;
                        reportSettings['anchorsImageFile'] = img;
                    }
                    reportData = reportSettings;
                    this.props.saveReportSettings()
                    this.props.saveReportAction({ ...reportData, token: this.props.token})
                });
            } else {
                this.resetImage(e, imageType)
            }
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    validateFile(file) {
        let isValid = true

        if (!this.state.validFileTypes.includes(file.type)) {
            this.showToaster('error', 'Upload Error', 'Invalid File type, only files with the following extensions are allowed: ' + this.state.validFileTypes.join())
            isValid = false
        }

        if (file.size > process.env.REACT_APP_UPLOAD_SIZE_LIMIT) {
            this.showToaster('error', 'Upload Error', 'File size exceeds the allowable limit of ' + (process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000000) + 'MB')
            isValid = false
        }

        return isValid
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }


    resetImage(e, imageType){
        let reportData     = {};
        const reportSettings = this.props.reportSettings;

        e.target.value = null;

        if (imageType === 'backlink') {
            this.setState({
                backLinkImage: null,
            });
            reportSettings['backlinkImage'] = null;
            reportSettings['backlinkImageFile'] = null;
        } else {
            this.setState({
                anchorsImage: null,
            });
            reportSettings['anchorsImage'] = null;
            reportSettings['anchorsImageFile'] = null;
        }

        reportData = reportSettings;

        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    render(){

        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Backlink count')}</CardTitle>
                            <CardText>{ this.props.t('Upload Image') }</CardText>
                            <CardText className="mb-3">
                                <input type="file" name="" accept={this.state.validFileTypes.join()} onChange={(e) => { this.onImageChange(e, 'backlink') }} />
                                { (this.state.aboveTheFoldImage != '') && (
                                    <img className="mt-2 img-fluid" src={this.state.backLinkImage} />
                                )}
                            </CardText>
                            <FileUploadInfo />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Anchors cloud')}</CardTitle>
                            <CardText>{ this.props.t('Upload Image') }</CardText>
                            <CardText className="mb-3">
                                <input type="file" name="" accept={this.state.validFileTypes.join()} onChange={(e) => { this.onImageChange(e, 'anchors') }} />
                                { (this.state.aboveTheFoldImage != '') && (
                                    <img className="mt-2 img-fluid" src={this.state.anchorsImage} />
                                )}
                            </CardText>
                            <FileUploadInfo />
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

OffPageSeoSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(OffPageSeoSettings))
