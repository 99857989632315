import { combineReducers } from 'redux'

import Layout from './layout/reducer'
import {
    AccountProfile,
    Login,
    ForgotPassword,
    PasswordReset,
    JwtSession,
    Register,
    Social,
    AclRoles,
    AclPermissions,
    AclUsers,
    ComponentsDdlb,
    ComponentsForm,
    ComponentsModal,
    PaymentGatewayStripe,
    SubscriptionPlanStripe,
} from '../app.cms.react/store/reducers'
import Reports from './reports/reducer'

const rootReducer = combineReducers({
    Layout,
    AccountProfile,
    Login,
    ForgotPassword,
    PasswordReset,
    JwtSession,
    Register,
    Social,
    AclRoles,
    AclPermissions,
    AclUsers,
    ComponentsDdlb,
    ComponentsForm,
    ComponentsModal,
    PaymentGatewayStripe,
    SubscriptionPlanStripe,
    Reports
})

export default rootReducer
