import {
    REPORTS,
    REPORTS_DATA,
    REPORT_DETAILS,
    REPORT_DETAILS_DATA,
    REPORTS_DELETE,
    REPORTS_DELETE_DONE,
    REPORT_SETTINGS,
    REPORT_SUMMARY,
    GENERATE_REPORT,
    GENERATE_REPORT_DATA,
    SAVE_REPORT,
    SAVE_REPORT_DATA,
    DOWNLOAD_REPORT,
    DOWNLOAD_REPORT_DATA,
    CHECK_TOKEN_STATUS,
    CHECK_TOKEN_STATUS_DATA,
    DOWNLOAD_REPORT_FILE,
    DOWNLOAD_REPORT_FILE_DATA,
    REPORT_DELETE_BY_ID
} from './actionTypes'

import {getPropTraverse} from '../../app.cms.react/helpers/util'

const INIT_STATE = {
    data: [],
    reportData: [],
    isDataTableLoading: false,
    pageIsLoading: false,
    reportSettings: {},
    token: null,
    loading: false,
    downloadLoading: false,
    downloadToken: null,
    downloadedFileUrl: null,
    downloadErrorMessage : null,
    downloadTokenStatus: null,
    reportSummary : {},
    polling: false
}

const reports = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case REPORTS:
            return {
                ...state,
                isDataTableLoading: state.polling ? false : true
            }
        case REPORTS_DELETE:
            return { ...state }
        case REPORT_DELETE_BY_ID:
            return {
                ...state,
                data: state.data.filter((item) => item.report_id !== payload.reportId)
            }
        case REPORTS_DELETE_DONE:
            return { ...state }
        case REPORT_DETAILS: {
            return {
                ...state,
                pageIsLoading: true
            }
        }
        case REPORT_DETAILS_DATA: {
            const reportRequestStatus = getPropTraverse(payload, ['data', 'status', 'tokenize_request_status_code']) || 'failed'

            return {
                ...state,
                reportData: getPropTraverse(payload, ['data']),
                pageIsLoading: (reportRequestStatus == 'open' || reportRequestStatus == 'in-progress'),
                reportSettings: getPropTraverse(payload, ['data', 'settings']) || {}
            }
        }
        case REPORTS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
                isDataTableLoading: false,
                polling: getPropTraverse(payload, ['polling'])
            }
        case REPORT_SETTINGS:
            return {
                ...state,
                reportSettings: { ...getPropTraverse(payload, ['data']), ...state.reportSettings }
            }
        case REPORT_SUMMARY:
            return {
                ...state,
                reportSummary: { ...getPropTraverse(payload, ['data']), ...state.reportSummary }
            }
        case GENERATE_REPORT:
            return {
                ...state,
                loading: true,
                token: null
             }
        case GENERATE_REPORT_DATA:
            return {
                ...state,
                loading: false,
                token: getPropTraverse(payload, ['data', 'token']),
            }
        case SAVE_REPORT:
            return {
                ...state,
                loading: true
             }
        case SAVE_REPORT_DATA:
            return {
                ...state,
                loading: false
             }
         case DOWNLOAD_REPORT:
             return {
                 ...state,
                 downloadLoading: true,
                 downloadToken: null
              }
         case DOWNLOAD_REPORT_DATA:
             return {
                 ...state,
                 downloadToken: getPropTraverse(payload, ['data', 'token']),
                 downloadTokenStatus: 'open',
             }
         case CHECK_TOKEN_STATUS:
              let downloadIterator = state.downloadIterator;

              return {
                 ...state,
                 downloadTokenStatus: null,
                 downloadErrorMessage: null,
              }
              break;
         case CHECK_TOKEN_STATUS_DATA:
             const downloadTokenStatus = getPropTraverse(payload, ['data', 'status', 'tokenize_request_status_code']) || null
             const downloadToken       = getPropTraverse(payload, ['data', 'status', 'token']) || null
             return {
                 ...state,
                 downloadToken        : downloadToken,
                 downloadTokenStatus  : downloadTokenStatus,
                 downloadErrorMessage : getPropTraverse(payload, ['data', 'error_message']) || null,
                 downloadLoading      : (downloadTokenStatus != 'failed')
             }
         case DOWNLOAD_REPORT_FILE:
            return {
                ...state,
            }
         case DOWNLOAD_REPORT_FILE_DATA:
            return {
                ...state,
                downloadToken: null,
                downloadLoading: false,
                downloadErrorMessage: null,
                downloadTokenStatus: 'done'
            }

    }

    return { ...state }
}

export default reports
