import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class BacklinkCount extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'offPageSeo', 'backlinks'])

        this.state = {
            sectionData          : sectionData,
            totalBackLinks       : getPropTraverse(sectionData, ['total_back_links']) || 0,
            RefDomainTopicsCount : getPropTraverse(sectionData, ['referring_domain_count']) || 0,
            status               : 'warning',
            message              : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (this.state.totalBackLinks) ? 'success' : 'danger'
            let message = (this.state.totalBackLinks)
                        ? 'Backlink acquisition is optimal.'
                        : 'No data found'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { backlink_count : message }
            } else {
                reportSummary[status]['backlink_count'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        const backlinkImage  = (getPropTraverse(this.props.reportSettings, ['backlinkImage']) != 'null') ? getPropTraverse(this.props.reportSettings, ['backlinkImage']) : false
        return (
            <React.Fragment>
                <div id="backlinkCountSection" className='mt-4'>
                    <h4>{ this.props.t('Backlink count') }</h4>
                    <p>There {this.state.totalBackLinks > 1 ? 'are' : 'is'} {this.state.totalBackLinks} link{this.state.totalBackLinks > 1 ? 's' : ''} found on your website from {this.state.RefDomainTopicsCount} referring domains. Google provides one credit for each domain link irrespective of the number of pages. Backlinks are considered 'votes of approval' from external sites, therefore the goal of effective off-page SEO is to {this.props.t('optimize')} the number of domains linking in.</p>
                    { backlinkImage && (
                        <img className="my-2 img-fluid" src={backlinkImage} />
                    )}
                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(BacklinkCount))
