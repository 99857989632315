import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText, Input } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'
import toastr              from "toastr"
import FileUploadInfo     from './FileUploadInfo'

class KeywordRankings extends Component {
    constructor(props) {
        super(props)

        const settingsData       = this.props.reportSettings
        let organicKeywordsCount = getPropTraverse(settingsData, ['organicKeywordsCount']) || '';

        if (organicKeywordsCount == '') {
            organicKeywordsCount = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'rankingKeyword', 'organic_keywords']) || 0
        }

        this.state = {
            validFileTypes      : ['image/png', 'image/jpg', 'image/jpeg'],
            keywordRankingsImage: null,
            organicKeywordsCount: organicKeywordsCount
        }

        this.keywordRankingSettings = this.keywordRankingSettings.bind(this)
        this.onImageChange          = this.onImageChange.bind(this);
    }


    keywordRankingSettings(meta, value) {
        const reportSettings = this.props.reportSettings
        const data = {
            organicKeywordsCount : this.state.organicKeywordsCount,
        }
        let reportData     = {}

        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value

        reportData = reportSettings;

        this.props.saveReportSettings()

        if (reportData.keywordRankingsImageFile) {
            delete(reportData.keywordRankingsImage)
        }

        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    onImageChange(e) {
        let reportData = {};

        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const reportSettings = this.props.reportSettings

            let imgBase64 = ''

            if (this.validateFile(img)) {
                this.getBase64(img, (result) => {
                     imgBase64 = result;

                     this.setState({
                         keywordRankingsImage: imgBase64
                     });

                     reportSettings['keywordRankingsImage'] = imgBase64
                     reportSettings['keywordRankingsImageFile'] = img
                     this.props.saveReportSettings()

                    reportData = reportSettings;

                    this.props.saveReportAction({ ...reportData, token: this.props.token})
                });
            } else {
                this.resetImage(e)
            }
        }
    }

    resetImage(e){
        let reportData = {};
        const reportSettings = this.props.reportSettings
        this.setState({
            keywordRankingsImage: ''
        });

        e.target.value = null

        reportSettings['keywordRankingsImage'] = ''
        reportSettings['keywordRankingsImageFile'] = ''
        this.props.saveReportSettings()

        reportData = reportSettings;

        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    validateFile(file) {
        let isValid = true

        if (!this.state.validFileTypes.includes(file.type)) {
            this.showToaster('error', 'Upload Error', 'Invalid File type, only files with the following extensions are allowed: ' + this.state.validFileTypes.join())
            isValid = false
        }

        if (file.size > process.env.REACT_APP_UPLOAD_SIZE_LIMIT) {
            this.showToaster('error', 'Upload Error', 'File size exceeds the allowable limit of ' + (process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000000) + 'MB')
            isValid = false
        }

        return isValid
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    render(){
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="h5">{ this.props.t('Keyword Rankings')}</CardTitle>
                        <CardText>
                            <Input
                                id="organic_keyword_count"
                                type="number"
                                placeholder={ this.props.t('Organic Keywords Count') }
                                value={ this.state.organicKeywordsCount }
                                onChange={e => {
                                    this.keywordRankingSettings('organicKeywordsCount', e.target.value)
                                }}/>
                        </CardText>
                        <CardText>{ this.props.t('Upload Image') }</CardText>
                        <CardText className="mb-3">
                            <input type="file" name="keywordRankingsImage" accept={this.state.validFileTypes.join()} onChange={this.onImageChange} />
                            <img className="mt-2 img-fluid" src={this.state.keywordRankingsImage} />
                        </CardText>
                        <FileUploadInfo />
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

KeywordRankings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(KeywordRankings))
