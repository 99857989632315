import {
    PAYMENT_GATEWAY_STRIPE_CHECKOUT,
    PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL
} from './actionTypes'

export const paymentGatewayStripeCheckoutAction = (stripe, productId, priceId) => ({
    type: PAYMENT_GATEWAY_STRIPE_CHECKOUT,
    payload: { stripe, productId, priceId }
})

export const paymentGatewayStripeCustomerPortalAction = (stripe, productId, priceId) => ({
    type: PAYMENT_GATEWAY_STRIPE_CUSTOMER_PORTAL,
    payload: { stripe, productId, priceId }
})
