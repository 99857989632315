import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'

class RecommendationsSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            websiteOptimization : this.props.reportSettings.websiteOptimization == 'true',
            organicSeo          : this.props.reportSettings.organicSeo == 'true',
        }

        this.updateRecommendationSettings = this.updateRecommendationSettings.bind(this)
    }

    updateRecommendationSettings(meta, value) {
        const reportSettings = this.props.reportSettings
        const data = {
            websiteOptimization : this.state.websiteOptimization,
            organicSeo          : this.state.organicSeo,
        }

        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportSettings, token: this.props.token})
    }

    render(){
        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Recommendations')}</CardTitle>
                            <CardText>{ this.props.t('To improve the performance of your site, We recommend the following') }:</CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="websiteOptimization"
                                    checked={this.state.websiteOptimization}
                                    onChange={e => {
                                        this.updateRecommendationSettings('websiteOptimization', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="websiteOptimization"
                                    >
                                    { this.props.t('Website Optimisation') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="organicSeo"
                                    checked={this.state.organicSeo}
                                    onChange={e => {
                                        this.updateRecommendationSettings('organicSeo', e.target.checked)
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="organicSeo"
                                    >
                                    { this.props.t('Organic SEO') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

RecommendationsSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings
    } = state.Reports

    return {
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(RecommendationsSettings))
