import PropTypes from 'prop-types'
import React, { Component } from "react"
import { connect } from "react-redux"

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"

class Header extends Component {
    constructor(props) {
        super(props)
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.props.toggleMenuCallback()
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <button type="button" onClick={this.toggleMenu} className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>
                        <div className="d-flex">
                            <NotificationDropdown />
                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout
    return { layoutType }
}

export default connect(mapStatetoProps)(
    withTranslation()(Header)
)

Header.propTypes = {
    t: PropTypes.any,
    toggleMenuCallback: PropTypes.any,
}