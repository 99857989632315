import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction, componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/actions'
import ButtonLoading from './ButtonLoading'
import {AvForm} from 'availity-reactstrap-validation'
import FormAlert from './FormAlert'
import PropTypes from 'prop-types'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                !props.alertType && (<button type="submit" className="btn btn-danger">{(typeof props.buttonConfirmText == 'undefined') ? 'Confirm Delete' : props.buttonConfirmText}</button>)
            }
        </React.Fragment>
    )
}

class ModalDelete extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.toggleModal = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleValidSubmit(event, values) {
        this.props.deleteAction({
            primaryKey: this.props.primaryKey
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'delete') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <ModalHeader>{(typeof this.props.title == 'undefined') ? 'Delete' : this.props.title}</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <i className="mdi mdi-alert-outline me-2"></i>{(typeof this.props.context == 'undefined') ? 'Are you sure you want to delete the selected record?' : this.props.context}
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons alertType={this.props.alertType} formLoading={this.props.formLoading} buttonConfirmText={this.props.buttonConfirmText} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalDelete.propTypes = {
    primaryKey: PropTypes.any.isRequired,
    context: PropTypes.any,
    title: PropTypes.any,
    buttonConfirmText: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalDelete))