import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import {Container, Row, Col, Card, CardBody} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import TableProductModule from 'app.cms.react/components/Subscription/ProductModule/TableProductModule'

class ProductModule extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.props.t('Product Module')}</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Product Module')}/>
                        <TableProductModule />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

ProductModule.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(ProductModule))