import React, {Component}      from 'react'
import {connect}               from "react-redux";
import { Container }           from 'reactstrap'
import { getPropTraverse }     from '../../app.cms.react/helpers/util'
import { reportDetailsAction } from '../../store/actions'
import { withTranslation }     from "react-i18next"
import ReportSettings          from '../../components/Report/Settings'
import Report                  from './Report'
import Pages404                from '../Utility/pages-404'
import MetaTags                from 'react-meta-tags';
import ReportViewDoc           from './ReportView'
import '../../assets/scss/custom/pages/_custom/_report-pdf.scss';
import ReactDrawer from 'react-drawer';

class ReportWebView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: props.match.params.any
        }

        this.getReportDetails()
        this.downloadReport   = this.downloadReport.bind(this)
    }

    downloadReport() {
        ReactPDF.render(<ReportViewDoc />, `${__dirname}/example.pdf`);
    }

    componentDidUpdate(prevProps)
    {
        const status   = getPropTraverse(this.props.reportData, ['status', 'tokenize_request_status_code'])
        if (status == 'open' || status == 'in-progress') {
            setTimeout(() => {
                this.getReportDetails()
            }, 3000)
        }
    }

    getReportDetails()
    {
        this.props.reportDetailsAction({
            token : this.state.token
        })
    }

    render() {
        const reportData = this.props.reportData
        const status     = getPropTraverse(reportData, ['status', 'tokenize_request_status_code']) || ''
        const pageTitle  = getPropTraverse(reportData, ['name']) || this.props.t('Loading...')

        return (
            <React.Fragment>
                <MetaTags>
                  <title>{ pageTitle }</title>
                </MetaTags>
                { (status == 'completed') && (
                    <React.Fragment>
                        <Report />
                        <ReactDrawer>
                            <ReportSettings token={this.state.token} />
                        </ReactDrawer>
                    </React.Fragment>
                ) }

                { (status != '' && status != 'completed') && (
                    <Pages404 />
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        reportData,
        pageIsLoading
    } = state.Reports

    return {
        reportData,
        pageIsLoading
    }
}

export default withTranslation()(connect(mapStateToProps, {
    reportDetailsAction
})(ReportWebView));
