import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class UrlStructure extends Component {
    constructor(props) {
        super(props)

        const sectionData       = getPropTraverse(this.props.reportData, ['report', 'onPageSeo']) || []
        const malformedUrlCount = getPropTraverse(sectionData, ['urlStructure', 'total']) || 0

        this.state = {
            sectionData             : sectionData,
            urlStructureIsOptimized : (malformedUrlCount == 0),
            malformedUrls           : getPropTraverse(sectionData, ['urlStructure', 'list']) || [],
            status                  : 'warning',
            message                 : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (this.state.urlStructureIsOptimized) ? 'success' : 'danger'
            let message = (this.state.urlStructureIsOptimized)
                        ? 'urlStructureGood'
                        : 'urlStructureDanger'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { url_structure : message }
            } else {
                reportSummary[status]['url_structure'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="urlStructureSection" className='mt-4'>
                    <h4>{ this.props.t('URL Structure') }</h4>
                    <p>{ this.props.t('urlStructureDescription') }</p>
                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={  this.state.status } /></th>
                                    <td>
                                        { this.props.t(this.state.message) }

                                        { !this.state.urlStructureIsOptimized &&
                                        <ul className="mt-2 fw-550">
                                        { this.state.malformedUrls.map(list =>
                                            <li key={list}>{list}</li>
                                        )}
                                        </ul>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(UrlStructure))
