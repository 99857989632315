import {
    ACL_ROLES,
    ACL_ROLES_DATA,
    ACL_ROLES_UPDATE,
    ACL_ROLES_ADD,
    ACL_ROLES_DELETE,
} from './actionTypes'

export const aclRolesAction = (params) => ({
    type: ACL_ROLES,
    payload: { params },
})

export const aclRolesDataAction = (data) => ({
    type: ACL_ROLES_DATA,
    payload: { data },
})

export const aclRolesUpdateAction = (params, meta) => ({
    type: ACL_ROLES_UPDATE,
    payload: { params, meta },
})

export const aclRolesAddAction = (params, meta) => ({
    type: ACL_ROLES_ADD,
    payload: { params, meta },
})

export const aclRolesDeleteAction = (params, meta) => ({
    type: ACL_ROLES_DELETE,
    payload: { params, meta },
})