import { takeEvery, put, call } from 'redux-saga/effects'

import {BROADCAST_NOTIFICATION} from './actionTypes'
import {AJAX_URL_BROADCAST_NOTIFICATION} from 'app.cms.react/store/actionTypes'

import {buildUrlParams, defaultGetOptions, fetchJSON, getPropTraverse} from 'app.cms.react/helpers/util'
import {broadcastNotificationDataAction} from './actions'

function* broadcastNotification({ payload: { params }}) {
    try {
        const url = AJAX_URL_BROADCAST_NOTIFICATION + buildUrlParams(params);

        const response   = yield call(fetchJSON, url, defaultGetOptions());
        const statusCode = getPropTraverse(response, ['statusCode']);
        const data       = getPropTraverse(response, ['response', 'data']);

        yield put(broadcastNotificationDataAction(data))
    } catch (error) {
        console.log(error, ' --- error')
    }
}

function* broadcastNotificationSaga() {
    yield takeEvery(BROADCAST_NOTIFICATION, broadcastNotification)
}

export default broadcastNotificationSaga