import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom'

import Dashboard from '../pages/Dashboard/index'
import {
    Roles,
    Permissions,
    Users,
    SubscriptionPlans,
    SubscriptionBilling,
    AccountProfile,
    Logout,
    ForgotPassword,
    PasswordReset,
    Register,
} from 'app.cms.react/routes'
import ExternalLogin from '../pages/Utility/ExternalLogin'
import ReportList from '../pages/Report/ReportList'
import ReportView from '../pages/Report/View'
import ReportWebView from '../pages/Report/WebView'
import Pages404 from '../pages/Utility/pages-404'
import Login from '../pages/Authentication/Login'

const ReportPublicWebView = lazy(() => import('../pages/Report/ReportWebView'))

let authProtectedRoutes = [
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    { path: '/acl/roles', name: 'acl-roles', component: Roles },
    { path: '/acl/permissions', name: 'acl-permissions', component: Permissions },
    { path: '/reports', name: 'reports', component: ReportList },
    { path: '/reports/:any', name: 'report-view', component: ReportView },
    { path: '/acl/users', name: 'acl-users', component: Users },
    { path: '/subscription/plans', name: 'subscription-plans', component: SubscriptionPlans },
    { path: '/subscription/billing', name: 'subscription-billing', component: SubscriptionBilling },
    { path: '/account/profile', name: 'account-profile', component: AccountProfile },

    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
    { path: '*', name: '404', component: Pages404 },
]

let publicRoutes = [
    { path: '/logout', name: 'logout', component: Logout },
    { path: '/login', name: 'login', component: Login },
    { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword },
    { path: '/password-reset', name: 'password-reset', component: PasswordReset },
    { path: '/register', name: 'register', component: Register },
    { path: '/reports/view/:any', name:'report-lar-web-view', component: ReportPublicWebView },
    { path: '/reports/web/:any', name:'report-web-view', component: ReportWebView },
    { path: '/external-login/:hash', name:'external-login', component: ExternalLogin }
]

if ((window.location.origin).replace(/\/+$/, '') != (process.env.REACT_APP_BASE_URL).replace(/\/+$/, '')) {
    authProtectedRoutes = []
    publicRoutes = [
        { path: '/reports/view/:any', name:'report-lar-web-view', component: ReportPublicWebView },
        { path: '/reports/web/:any', name:'report-web-view', component: ReportWebView },
        { path: '/external-login/:hash', name:'external-login', component: ExternalLogin }
    ];
}

const allRoutes = [...authProtectedRoutes, ...publicRoutes]
export { authProtectedRoutes, publicRoutes, allRoutes }