import {
    SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE,
    SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_DATA,
} from './actionTypes'

export const subscriptionPlanStripeProductPriceAction = (params) => ({
    type: SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE,
    payload: { params }
})

export const subscriptionPlanStripeProductPriceDataAction = (data, attributes) => ({
    type: SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_DATA,
    payload: { data, attributes },
})