import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class GoogleSearchConsole extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []

        this.state = {
            sectionData : sectionData,
            isInstalled : getPropTraverse(sectionData, ['googleSearchConsole', 'code']) ? true : false,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service',
            hasGoogleConsoleAccess : getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true,
            googleSearchConsoleInstalled : getPropTraverse(this.props.reportSettings, ['googleSearchConsoleInstalled']) == 'true' || getPropTraverse(this.props.reportSettings, ['googleSearchConsoleInstalled']) == true
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        const reportSettings = this.props.reportSettings;
        let reportSummary = this.props.reportSummary;
        let gscInstalled = this.state.isInstalled;

        if (this.state.sectionData) {
            const hasGoogleConsoleAccess = getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
            let message = this.props.t('Needs installation and we need to be given full access to your Google Search Console account.')
            let status  = 'danger'

            if (typeof reportSettings.googleSearchConsoleInstalled !== 'undefined') {
                gscInstalled = reportSettings.googleSearchConsoleInstalled;
            }

            if (gscInstalled && hasGoogleConsoleAccess) {
                status  = 'success'
                message = this.props.t('Installed and we have been given full access to your Google Search Console account.')
            } else if (gscInstalled && !hasGoogleConsoleAccess) {
                status  = 'warning'
                message = 'Installed but we will require full access to your Google Search Console account.'
            }

            this.setState({
                status  : status,
                message : message,
                hasGoogleConsoleAccess : this.props.reportSettings.hasGoogleConsoleAccess,
                googleSearchConsoleInstalled: this.props.reportSettings.googleSearchConsoleInstalled
            })

            // remove from summary first
            if (typeof reportSummary['success'] != 'undefined') {
                delete reportSummary['success']['google_search_console']
            }
            if (typeof reportSummary['warning'] != 'undefined') {
                delete reportSummary['warning']['google_search_console']
            }
            if (typeof reportSummary['danger'] != 'undefined') {
                delete reportSummary['danger']['google_search_console']
            }

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { google_search_console : message }
            } else {
                reportSummary[status]['google_search_console'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.reportSettings.hasGoogleConsoleAccess != this.state.hasGoogleConsoleAccess ||
            prevProps.reportSettings.googleSearchConsoleInstalled != this.state.googleSearchConsoleInstalled
        ) {
            this.updateReportSummary()
        }
    }

    render() {
        let status  = this.state.status
        let message = this.state.message

        return (
            <React.Fragment>
                <div id="googleSearchConsoleSection" className='mt-4'>
                    <h4>{ this.props.t('Google Search Console') }</h4>
                    <p>Giving us full access to your Google Search Console (previously Google Webmaster Tools) will allow us to check if your website has broken links, penalties, malware warning, crawl errors, or if it requires action to resolve issues.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={status} /></th>
                                    <td>{message}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(GoogleSearchConsole));
