import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import Select from 'react-select'
import FormAlert from '../../FormAlert'
import ButtonLoading from '../../ButtonLoading'
import {aclUsersAddAction, componentFormInitAction, componentFormOnSubmitAction, componentModalToggleAction, componentDdlbOnChangeAction} from 'app.cms.react/store/actions'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalAddUser extends Component {
    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this)
        this.handleAddNewSubmit = this.handleAddNewSubmit.bind(this)
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleDdlbChange = (value) => {
        this.props.componentDdlbOnChangeAction(value)
    };

    handleAddNewSubmit(event, values) {
        this.props.aclUsersAddAction({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roles: this.props.ddlb,
        })
        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'add') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleAddNewSubmit}>
                        <ModalHeader>Add New</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <div className="mb-3">
                                <AvField
                                    label="First Name"
                                    name="firstName"
                                    className="form-control"
                                    placeholder='First Name'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label="Last Name"
                                    name="lastName"
                                    className="form-control"
                                    placeholder='First Name'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label="Email"
                                    name="email"
                                    className="form-control"
                                    placeholder='Email'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Role</label>
                                <Select
                                    options={this.props.ddlbRoles}
                                    onChange={this.handleDdlbChange}
                                    classNamePrefix="select2-selection"
                                    isClearable={true}
                                    required
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        ddlb
    } = state.ComponentsDdlb

    return {
        ddlb,
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclUsersAddAction,
    componentDdlbOnChangeAction,
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalAddUser))