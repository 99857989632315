import {
    COMPONENT_MODAL_INIT,
    COMPONENT_MODAL_TOGGLE,
} from './actionTypes'

const INIT_STATE = {
    isOpen: false,
}

const componentModal = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case COMPONENT_MODAL_INIT:
            return { ...state }
        case COMPONENT_MODAL_TOGGLE:
            return {
                ...state,
                isOpen: (typeof payload != 'undefined') ? payload.isOpen : true,
                target: payload.target,
            }
    }

    return { ...state }
}

export default componentModal