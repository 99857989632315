import {
    ACL_PERMISSIONS,
    ACL_PERMISSIONS_DATA,
    ACL_PERMISSIONS_UPDATE,
    ACL_PERMISSIONS_ADD,
    ACL_PERMISSIONS_DELETE,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/util'

const INIT_STATE = {
    data: [],
}

const aclPermissions = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case ACL_PERMISSIONS:
        case ACL_PERMISSIONS_UPDATE:
        case ACL_PERMISSIONS_ADD:
        case ACL_PERMISSIONS_DELETE:
            return { ...state }
        case ACL_PERMISSIONS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
    }

    return { ...state }
}

export default aclPermissions