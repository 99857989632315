import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class MalwareCheck extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'malwareCheck']) || []

        this.state = {
            sectionData : sectionData,
            hasMalware  : getPropTraverse(sectionData, ['hasMalware']) || false,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service',
            hasGoogleConsoleAccess : getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            const hasGoogleConsoleAccess = getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
            let message = 'Oh no! Google’s Safe Browsing Site Status tagged the website as “Maybe Dangerous.” Some pages on the site may infect visitors with software designed to access confidential information or harm their computers. Act quickly to repair the damage by following the recommendations provided on Google Search Console.'
            let status  = 'danger'

            if (this.state.hasMalware == false && hasGoogleConsoleAccess) {
                status  = 'success'
                message = 'Great! Google’s Safe Browsing Site Status checker has not recently seen malicious content on the website. Google Search Console did not detect malware infection as well'
            } else if (this.state.hasMalware == false && !hasGoogleConsoleAccess) {
                status  = 'warning'
                message = 'Great! Google’s Safe Browsing Site Status checker recently has not seen malicious content on the website, but we cannot verify this since we need access to Google Search Console.'
            }

            this.setState({
                status  : status,
                message : message,
                hasGoogleConsoleAccess : hasGoogleConsoleAccess
            })

            // remove from summary first
            if (typeof reportSummary['success'] != 'undefined') {
                delete reportSummary['success']['malware_check']
            }
            if (typeof reportSummary['warning'] != 'undefined') {
                delete reportSummary['warning']['malware_check']
            }
            if (typeof reportSummary['danger'] != 'undefined') {
                delete reportSummary['danger']['malware_check']
            }

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { malware_check : message }
            } else {
                reportSummary[status]['malware_check'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const hasGoogleConsoleAccess = getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == true

        if (hasGoogleConsoleAccess != this.state.hasGoogleConsoleAccess) {
            this.updateReportSummary()
        }
    }

    render() {
        let status  = this.state.status
        let message = this.state.message

        const hasGoogleAnalyticsAccess = (getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == true)

        if (this.state.isInstalled) {
            if (hasGoogleAnalyticsAccess) {
                status  = 'success'
                message = 'Installed and we have been given full access to your Google Analytics account.'
            }
        }

        return (
            <React.Fragment>
                <div id="malwareCheckSection" className='mt-4'>
                    <h4>Malware Check</h4>
                    <p>If the website has been infected with malware, a hacker might have taken control of the site and changed the content to add spam or additional pages for phishing. To protect site visitors from malware, Google’s search results now display a warning on the link to infected sites</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={status} /></th>
                                    <td>{message}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(MalwareCheck));
