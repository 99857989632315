import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Select from 'react-select'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import FormAlert from '../../FormAlert'
import ButtonLoading from '../../ButtonLoading'
import {aclUsersUpdateAction, componentFormInitAction, componentFormOnSubmitAction, componentModalToggleAction, componentDdlbOnChangeAction} from 'app.cms.react/store/actions'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalEditUser extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.toggleModal       = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    handleDdlbChange = (value) => {
        this.props.componentDdlbOnChangeAction(value)
    };

    handleValidSubmit(event, values) {
        this.props.aclUsersUpdateAction({
            id: this.props.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roles: this.props.ddlb,
        })

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'edit') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        <ModalHeader>Edit</ModalHeader>
                        <ModalBody>
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                            <div className="mb-3">
                                <AvField
                                    label="First Name"
                                    name="firstName"
                                    value={this.props.firstName}
                                    className="form-control"
                                    placeholder='First Name'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label="Last Name"
                                    name="lastName"
                                    value={this.props.lastName}
                                    className="form-control"
                                    placeholder='First Name'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label="Email"
                                    name="email"
                                    value={this.props.email}
                                    className="form-control"
                                    placeholder='Email'
                                    type="text"
                                    required
                                    disabled={this.props.disableFields}
                                />
                            </div>
                            <div>
                                <label>Role</label>
                                <Select
                                    name="roles"
                                    value={this.props.ddlb}
                                    options={this.props.ddlbRoles}
                                    onChange={this.handleDdlbChange}
                                    classNamePrefix="select2-selection"
                                    isClearable={true}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        ddlb
    } = state.ComponentsDdlb

    return {
        ddlb,
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclUsersUpdateAction,
    componentDdlbOnChangeAction,
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalEditUser))