import React, {Component} from 'react';
import PropTypes          from 'prop-types'

const ElReportIcon = props => {
    switch (props.type) {
        case 'success':
            return (
                <span className='text-success'><i className='fas fa-check-circle'></i></span>
            )
        case 'warning':
            return (
                <span className='text-warning'><i className='fas fa-exclamation-triangle'></i></span>
            )
        case 'danger':
            return (
                <span className='text-danger'><i className='fas fa-fire-alt'></i></span>
            )
    }

    return ''
}

class ReportIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <ElReportIcon {...this.props} />
            </React.Fragment>
        )
    }
}

ReportIcon.propTypes = {
    type: PropTypes.string.isRequired,
}

export default ReportIcon
