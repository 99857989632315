export const REPORTS              = 'REPORTS'
export const REPORTS_DATA         = 'REPORTS_DATA'
export const REPORT_DETAILS       = 'REPORT_DETAILS'
export const REPORT_DETAILS_DATA  = 'REPORT_DETAILS_DATA'
export const REPORTS_DELETE       = 'REPORTS_DELETE'
export const REPORT_DELETE_BY_ID  = 'REPORT_DELETE_BY_ID'
export const REPORTS_DELETE_DONE  = 'REPORTS_DELETE_DONE'
export const REPORT_SETTINGS      = 'REPORT_SETTINGS'
export const REPORT_SUMMARY       = 'REPORT_SUMMARY'
export const GENERATE_REPORT      = 'GENERATE_REPORT'
export const GENERATE_REPORT_DATA = 'GENERATE_REPORT_DATA'
export const SAVE_REPORT          = 'SAVE_REPORT'
export const SAVE_REPORT_DATA     = 'SAVE_REPORT_DATA'
export const DOWNLOAD_REPORT      = 'DOWNLOAD_REPORT'
export const DOWNLOAD_REPORT_DATA = 'DOWNLOAD_REPORT_DATA'
export const CHECK_TOKEN_STATUS      = 'CHECK_TOKEN_STATUS'
export const CHECK_TOKEN_STATUS_DATA = 'CHECK_TOKEN_STATUS_DATA'
export const DOWNLOAD_REPORT_FILE = 'DOWNLOAD_REPORT_FILE'
export const DOWNLOAD_REPORT_FILE_DATA = 'DOWNLOAD_REPORT_FILE_DATA'
