import PropTypes            from 'prop-types'
import {connect}            from 'react-redux';
import React, { Component } from 'react'
import { Card, CardBody, CardTitle, CardText, Collapse } from 'reactstrap'
import SimpleBar            from 'simplebar-react'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../app.cms.react/helpers/util'
import { saveReportSettings } from '../../store/reports/actions'

import * as Scroll from 'react-scroll';
import { Link, scroller } from 'react-scroll'

import CoverSettings          from './Settings/Cover'
import IndexingAndCrawlabilitySettings   from './Settings/IndexingAndCrawlability'
import TechnicalSeoSettings   from './Settings/TechnicalSeo'
import OffPageSeoSettings     from './Settings/OffPageSeo'
import OnPageSeoSettings      from './Settings/OnpageSeo'
import RecommendationSettings from './Settings/Recommendations'
import LanguageSettings       from './Settings/Language'
import SchemaMarkup           from "./Settings/SchemaMarkup";
import ReputationSignals      from "./Settings/ReputationSignals";

class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cover                    : false,
            indexing_and_crawlability: false,
            technical_seo            : false,
            recommendation           : false,
            on_page                  : false,
            off_page                 : false,
            reputationSignals        : false,
            jsonld                   : false,
            language                 : false
        }

        this.onCloseSetting          = this.onCloseSetting.bind(this);
        this.indexingAndCrawlability = this.indexingAndCrawlability.bind(this);
        this.technicalSeo            = this.technicalSeo.bind(this);
        this.onPage                  = this.onPage.bind(this);
        this.offPage                 = this.offPage.bind(this);
        this.recommendation          = this.recommendation.bind(this);
        this.language                = this.language.bind(this);
        this.schemaMarkupSection     = this.schemaMarkupSection.bind(this);
        this.reputationSignalsSection= this.reputationSignalsSection.bind(this);
        this.coverSection            = this.coverSection.bind(this);
    }

    onCloseSetting = () => {
        this.props.onClose();
    }

    indexingAndCrawlability() {
        this.closeOtherSection()
        this.setState( { indexing_and_crawlability: !this.state.indexing_and_crawlability})
        scroller.scrollTo('indexingAndCrawlabilitySection', {
            offset: -90
        });
    }

    technicalSeo() {
        this.closeOtherSection()
        this.setState( { technical_seo : !this.state.technical_seo })
        scroller.scrollTo('technicalSeoSection', {
            offset: -90
        });
    }

    coverSection() {
        this.closeOtherSection()
        this.setState( { cover : !this.state.cover })
        scroller.scrollTo('coverContainer', {
            offset: -90
        });
    }

    onPage() {
        this.closeOtherSection()
        this.setState( { on_page : !this.state.on_page })
        scroller.scrollTo('onPageSeoSection', {
            offset: -90
        });
    }

    offPage() {
        this.closeOtherSection()
        this.setState( { off_page : !this.state.off_page })
        scroller.scrollTo('offPageSeoSection', {
            offset: -90
        });
    }


    recommendation() {
        this.closeOtherSection()
        this.setState( { recommendation : !this.state.recommendation })
        scroller.scrollTo('recommendationSection', {
            offset: -90
        });
    }

    schemaMarkupSection() {
        this.closeOtherSection()
        this.setState( { jsonld : !this.state.jsonld })
        scroller.scrollTo('schemaMarkupSection', {
            offset: -90
        });
    }

    reputationSignalsSection() {
        this.closeOtherSection()
        this.setState( { reputationSignals : !this.state.reputationSignals })
        scroller.scrollTo('reputationSignalsSection', {
            offset: -90
        });
    }

    language() {
        this.closeOtherSection()
        this.setState( { language : !this.state.language })
    }

    closeOtherSection() {
        this.setState({
            cover                    : false,
            indexing_and_crawlability: false,
            technical_seo            : false,
            on_page                  : false,
            off_page                 : false,
            recommendation           : false,
            language                 : false,
            jsonld                   : false,
            reputationSignals              : false
        })
    }

    render(){
        return (
            <React.Fragment>
                <SimpleBar className="report-settings" style={{width: '510px'}}>
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title px-3 py-4">
                            <Link
                                to="#"
                                onClick={this.onCloseSetting}
                                className="right-bar-toggle float-end"
                                >
                                <i className="mdi mdi-close noti-icon" />
                            </Link>
                            <h5 className="m-0">{ this.props.t('Report Content')}</h5>
                        </div>
                        <hr className="my-0" />

                        <div className="accordion accordion-flush">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.cover ? ' collapsed' : '')} type="button" onClick={this.coverSection}>
                                        { this.props.t('Cover') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.cover} className="accordion-collapse">
                                    <CoverSettings token={this.props.token} />
                                </Collapse>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.indexing_and_crawlability ? ' collapsed' : '')} type="button" onClick={this.indexingAndCrawlability}>
                                        { this.props.t('Indexing and Crawlability') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.indexing_and_crawlability} className="accordion-collapse">
                                    <IndexingAndCrawlabilitySettings token={this.props.token} />
                                </Collapse>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.technical_seo ? ' collapsed' : '')} type="button" onClick={this.technicalSeo}>
                                        { this.props.t('Technical SEO') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.technical_seo} className="accordion-collapse">
                                    <TechnicalSeoSettings token={this.props.token} />
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.on_page ? ' collapsed' : '')} type="button" onClick={this.onPage}>
                                        { this.props.t('On-Page SEO') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.on_page} className="accordion-collapse">
                                    <OnPageSeoSettings token={this.props.token} />
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.off_page ? ' collapsed' : '')} type="button" onClick={this.offPage}>
                                        { this.props.t('Off-Page SEO') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.off_page} className="accordion-collapse">
                                    <OffPageSeoSettings token={this.props.token} />
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.recommendation ? ' collapsed' : '')} type="button" onClick={this.recommendation}>
                                        { this.props.t('Recommendations') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.recommendation} className="accordion-collapse">
                                    <RecommendationSettings token={this.props.token} />
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.reputationSignals ? ' collapsed' : '')} type="button" onClick={this.reputationSignalsSection}>
                                        { this.props.t('Reputation Signals') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.reputationSignals} className="accordion-collapse">
                                   <ReputationSignals token={this.props.token}/>
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.jsonld ? ' collapsed' : '')} type="button" onClick={this.schemaMarkupSection}>
                                        { this.props.t('JSON-LD Schema Markup') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.jsonld} className="accordion-collapse">
                                   <SchemaMarkup token={this.props.token}/>
                                </Collapse>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className={"btn accordion-button fw-medium" + (this.state.language ? ' collapsed' : '')} type="button" onClick={this.language}>
                                        { this.props.t('Language') }
                                    </button>
                                </h2>
                                <Collapse isOpen={this.state.language} className="accordion-collapse">
                                    <LanguageSettings token={this.props.token} />
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }
}

Settings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {saveReportSettings})(Settings))
