import {
    SOCIAL_AUTH_CALLBACK,
} from './actionTypes'

const INIT_STATE = {}

const social = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case SOCIAL_AUTH_CALLBACK:
            return { ...state };
    }

    return { ...state }
}

export default social