const sanitizeFilename = function(str) {
    str = str.replace('https://', '').replace('http://', '')
    if (str.endsWith('/')) {
        str = str.slice(0, -1)
    }

    return str
};

export {
    sanitizeFilename
};