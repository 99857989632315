import {
    ACL_ROLES,
    ACL_ROLES_DATA,
    ACL_ROLES_UPDATE,
    ACL_ROLES_ADD,
    ACL_ROLES_DELETE,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/util';

const INIT_STATE = {
    data: []
}

const aclRoles = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case ACL_ROLES:
        case ACL_ROLES_UPDATE:
        case ACL_ROLES_ADD:
        case ACL_ROLES_DELETE:
            return { ...state }
        case ACL_ROLES_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
    }

    return { ...state }
}

export default aclRoles