import {
    ACCOUNT_PROFILE,
    ACCOUNT_PROFILE_DATA,
    ACCOUNT_PROFILE_UPDATE,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/util'

const INIT_STATE = {
    data: [],
    isDataTableLoading: false,
}

const accountProfile = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case ACCOUNT_PROFILE:
        case ACCOUNT_PROFILE_UPDATE:
            return {
                ...state,
                isDataTableLoading: true,
            }
        case ACCOUNT_PROFILE_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
                isDataTableLoading: false,
            }
    }

    return { ...state }
}

export default accountProfile