import React, { Component } from 'react'
import { connect }          from 'react-redux';
import { withTranslation }  from 'react-i18next'
import config               from '../../../config';

class FileUploadInfo extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            validFileLabels : config.file_upload.IMAGE.VALID_FORMATS.join(),
            validFileSize   : (config.file_upload.IMAGE.MAX_SIZE_LIMIT / 1000000) + 'MB',
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="my-3 alert alert-info">
                    <div><small>{ this.props.t('Supported formats') }: { this.state.validFileLabels }</small></div>
                    <div><small>{ this.props.t('Maximum upload file size is ' + this.state.validFileSize ) }</small></div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData
    } = state.Reports

    return {
        reportSettings,
        reportData
    }
}

export default withTranslation()(connect(mapStateToProps, {})(FileUploadInfo))