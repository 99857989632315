import {
    JWT_SESSION,
    JWT_SESSION_EXPIRED
} from './actionTypes'

export const jwtSessionAction = (params) => ({
    type: JWT_SESSION,
    payload: params
})

export const jwtSessionExpiredAction = (params) => ({
    type: JWT_SESSION_EXPIRED,
    payload: params
})