import {
    LOGIN_USER,
    LOGOUT_USER,
} from './actionTypes'

export const loginUserAction = (params, meta) => ({
    type: LOGIN_USER,
    payload: { params, meta },
});

export const logoutUserAction = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
})
