import { takeEvery, put, call } from 'redux-saga/effects'

import {SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE} from './actionTypes'
import {AJAX_URL_SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_SUBSCRIPTION} from 'app.cms.react/store/actionTypes'

import {buildUrlParams, defaultGetOptions, fetchJSON, getPropTraverse} from 'app.cms.react/helpers/util'
import {subscriptionPlanStripeProductPriceDataAction} from './actions'

function* subscriptionPlanStripeProductPrice({ payload: {params, meta }}) {
    try {
        const attributes = {
            asRecurringOrder: params.asRecurringOrder
        }

        delete params.asRecurringOrder

        const url = AJAX_URL_SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE_SUBSCRIPTION + buildUrlParams(params);

        const response   = yield call(fetchJSON, url, defaultGetOptions());
        const statusCode = getPropTraverse(response, ['statusCode']);
        const data       = getPropTraverse(response, ['response', 'data']);

        yield put(subscriptionPlanStripeProductPriceDataAction(data, attributes))
    } catch (error) {

    }
}

function* SubscriptionPlanStripeSaga() {
    yield takeEvery(SUBSCRIPTION_PLAN_STRIPE_PRODUCT_PRICE, subscriptionPlanStripeProductPrice)
}

export default SubscriptionPlanStripeSaga