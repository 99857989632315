import React, {Component} from 'react'
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next'
import {Elements} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {Alert, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import CardSubscription from 'app.cms.react/components/CardSubscription'
import {paymentGatewayStripeCustomerPortalAction, paymentGatewayStripeCheckoutAction, componentFormOnSubmitAction, subscriptionPlanStripeProductPriceAction} from 'app.cms.react/store/actions'
import SweetAlert from 'react-bootstrap-sweetalert'
import {getPropTraverse, getUrlQuery} from 'app.cms.react/helpers/util'
import {stripe} from 'app.cms.react/config'
import MetaTags from 'react-meta-tags'
import classnames from "classnames"
import DivLoading from '../../components/DivLoading'

const Notification = props => {
    const queryUrl = getUrlQuery()

    return Object.entries(props.data).map(([productKey, productValue]) => {
        return Object.entries(productValue).map(([key, value]) => {
            if (value.product_id == queryUrl.productCode && value.price_id == queryUrl.priceCode && props.show) {
                return (
                    <SweetAlert key={'_notification_' + productKey}
                        success
                        title="Success"
                        confirmBtnBsStyle="primary"
                        confirmBtnText="Close"
                        onConfirm={() => props.handleNotificationClose()}
                    >
                        You have successfully subscribed to <strong>{value.product_name}</strong>
                    </SweetAlert>
                )
            }
        })
    })
}

const CardSubscriptionRender = props => {
    let componentBlock;

    if (getPropTraverse(props, ['data', 'month'])) {
        componentBlock = <RecurringCardRender {...props} />
    } else {
        componentBlock = props.data.map((pricing, key) => (
            <CardSubscription product={pricing} key={'_product_' + key} onClickEvent={props.handleClick} />
        ))
    }

    return (<React.Fragment>{componentBlock}</React.Fragment>)
}

const RecurringCardRender = props => {
    const componentMonthBlock = props.data.month.map((pricing, key) => (
        <CardSubscription product={pricing} key={'_product_' + key} onClickEvent={props.handleClick} />
    ))
    const componentYearBlock = props.data.year.map((pricing, key) => (
        <CardSubscription product={pricing} key={'_product_' + key} onClickEvent={props.handleClick} />
    ))

    const activeTab = (typeof props.activeTab == 'undefined') ? 'month' : props.activeTab

    return (
        <React.Fragment>
            <div className='d-flex justify-content-center'>
                <Nav pills className="navtab-bg">
                    <NavItem>
                        <NavLink className={classnames({active: props.activeTab === 'month'})} onClick={() => {props.toggleTab('month')}}>
                            Monthly
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: props.activeTab === 'year'})} onClick={() => {props.toggleTab('year')}}>
                            Yearly
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>

            <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="month">
                    <Row className="justify-content-center">{componentMonthBlock}</Row>
                </TabPane>
                <TabPane tabId="year">
                    <Row className="justify-content-center">{componentYearBlock}</Row>
                </TabPane>
            </TabContent>
        </React.Fragment>
    )
}

class Plans extends Component {
    constructor(props) {
        super(props)

        this.toggleTab = this.toggleTab.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleNotificationClose = this.handleNotificationClose.bind(this)

        this.props.subscriptionPlanStripeProductPriceAction({
            asRecurringOrder: true
        })

        this.state = {
            activeTab: 'month',
            isActiveTabSet: false,
            stripePromise: loadStripe(stripe.APP_KEY),
            productPrice: this.props.data,
            showNotification: getUrlQuery().status == 'success' && (typeof getUrlQuery().productCode != 'undefined' && getUrlQuery().productCode != '') && (typeof getUrlQuery().priceCode != 'undefined' && getUrlQuery().priceCode != ''),
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    handleNotificationClose() {
        this.setState({showNotification: false})
    }

    async handleClick(productId, priceId) {
        const stripe = await this.state.stripePromise;
        this.props.paymentGatewayStripeCheckoutAction(stripe, productId, priceId)
        this.props.componentFormOnSubmitAction()
    }

    // this handles only one (1) subscription at a time
    setActiveTab() {
        let activeSubscriptions = this.props.activeSubscriptions

        if (Object.keys(activeSubscriptions).length > 0 && this.state.isActiveTabSet == false) {
            activeSubscriptions = activeSubscriptions[0] // get only the first active subscription
            this.setState({
                activeTab: activeSubscriptions.recurring.interval,
                isActiveTabSet: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setActiveTab()
        this.state.showNotification = true
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.props.t('Subscription')}</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Subscription')} breadcrumbItem={this.props.t('Plans')} />
                        {(this.state.showNotification) ?
                            (<Notification
                                data={this.props.data}
                                show={this.state.showNotification}
                                handleNotificationClose={this.handleNotificationClose}
                            />)
                            :
                            null
                        }
                        <Elements stripe={loadStripe(stripe.APP_KEY)}>
                            <Row>
                                {(this.props.isDataTableLoading) ? (
                                    <DivLoading />
                                ) : (Object.keys(this.props.data).length > 0) ? <CardSubscriptionRender subscriptionItems={this.props.subscriptionItems} activeSubscriptions={this.props.activeSubscriptions} data={this.props.data} activeTab={this.state.activeTab} toggleTab={this.toggleTab} handleClick={this.handleClick} /> : (
                                    <DivLoading />
                                )}
                            </Row>
                        </Elements>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Plans.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        data,
        activeSubscriptions,
        isDataTableLoading,
    } = state.SubscriptionPlanStripe

    return {
        data,
        activeSubscriptions,
        isDataTableLoading,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    paymentGatewayStripeCustomerPortalAction,
    paymentGatewayStripeCheckoutAction,
    subscriptionPlanStripeProductPriceAction,
    componentFormOnSubmitAction,
})(Plans))