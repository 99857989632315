import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class DofollowExternalLinks extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeoLinks'])

        this.state = {
            sectionData        : sectionData,
            externalLinksCount : getPropTraverse(sectionData, ['external_links_count']) || 0,
            status             : 'warning',
            message            : 'Error: An error occurred while calling the endpoint service',
            hasGoogleConsoleAccess : getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = 'danger'
            let message = this.props.t('Not good! There is an extensive amount of links found on your website. Please give us full access to your Search Console account so we can check the accurate number of external links.')
            const hasGoogleConsoleAccess = getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true

            if (this.state.externalLinksCount > 0 && hasGoogleConsoleAccess) {
                status  = 'success'
                message = this.props.t('There are ' + this.state.externalLinksCount + ' external links found on your website.')
            } else if (this.state.externalLinksCount > 0 && !hasGoogleConsoleAccess) {
                status  = 'warning'
                message = this.props.t('There are ' + this.state.externalLinksCount + ' external links found on your website using our crawler. But we need to be given full access to your search Console account so we can check the accurate number of external links.')
            }

            this.setState({
                status  : status,
                message : message,
                hasGoogleConsoleAccess : hasGoogleConsoleAccess
            })

            // remove from summary first
            if (typeof reportSummary['success'] != 'undefined') {
                delete reportSummary['success']['do_follow_external_links']
            }
            if (typeof reportSummary['warning'] != 'undefined') {
                delete reportSummary['warning']['do_follow_external_links']
            }
            if (typeof reportSummary['danger'] != 'undefined') {
                delete reportSummary['danger']['do_follow_external_links']
            }

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { do_follow_external_links : message }
            } else {
                reportSummary[status]['do_follow_external_links'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const hasGoogleConsoleAccess = getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == true

        if (hasGoogleConsoleAccess != this.state.hasGoogleConsoleAccess) {
            this.updateReportSummary()
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="dofollowExternalLinksSection" className='mt-4'>
                    <h4>{ this.props.t('Dofollow External Links') }</h4>
                    <p>Dofollow external links are followed by search engines and pass page authority. If you link extensively to irrelevant or low-quality sites, search engines may conclude that your site sells links and participates in other link schemes, and it can get penalized.</p>

                    <div className='p-3 mb-5'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSummary,
        reportSettings
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(DofollowExternalLinks))
