import {
    COMPONENT_DATA_TABLE_LOADING,
    COMPONENT_DATA_TABLE_LOADED,
} from './actionTypes'

export const componentDataTableLoadingAction = (params) => ({
    type: COMPONENT_DATA_TABLE_LOADING,
    payload: params
})

export const componentDataTableLoadedAction = (params) => ({
    type: COMPONENT_DATA_TABLE_LOADED,
    payload: params
})