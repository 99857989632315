import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {subscriptionProductModuleStripeAction, componentModalToggleAction, subscriptionModuleAction, componentDdlbMultiOnChangeAction} from 'store/actions'
import DataTable from '../../DataTable'
import {ddlbParser, numberFormatComma} from 'app.cms.react/helpers/util'
import {Card, CardBody, Col, List, ListInlineItem, Row} from 'reactstrap'
import ModalEditProductModule from './ModalEditProductModule'
import DataTableLoader from '../../DataTableLoader'

const ColFormatProduct = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <strong>{row.product_name}</strong>
            <div>{row.currency.toUpperCase()} {numberFormatComma((Math.round(row.amount) / 100).toFixed(2))} / {row.billing_interval}</div>
        </React.Fragment>
    )
}

const ColFormatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <List type="inline" className="p-0 m-0">
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleEdit(row, e)
                }}><i className="bx bx bx-pencil text-primary" /></ListInlineItem>
            </List>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'product_name',
        text: 'Price',
        formatter: ColFormatProduct,
    },
    {
        dataField: 'product_description',
        text: 'Product Description',
        sort: true,
    },
    {
        dataField: 'price_description',
        text: 'Price Description',
        sort: true,
    },
    {
        dataField: 'product_id',
        text: 'Product ID',
        sort: true,
    },
    {
        dataField: 'price_id',
        text: 'Price ID',
        sort: true,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        formatter: ColFormatActions,
        formatExtraData: {
            toggleEdit: props.toggleEdit,
        }
    },
];

const ExpandRow = (data) => {
    if (data.length == 0) return {}

    let noProductModule = []
    Object.entries(data).map(([key, value]) => {
        if (value.product_module.length == 0) {
            noProductModule.push(value.price_id)
        }
    })

    return {
        renderer: row => (
            <React.Fragment>
                Restrictions: - {console.log(row)}
                <ol>
                {
                    Object.entries(row.product_module).map(([key, val]) => {
                        if (val.usage_cap > 0) {
                            return (<li key={key}>{val.module_description} (<em>{numberFormatComma(val.usage_cap)} usage cap</em>)</li>)
                        } else {
                            return (<li key={key}>{val.module_description}</li>)
                        }
                    })
                }
                </ol>
            </React.Fragment>
        ),
        showExpandColumn: true,
        nonExpandable: noProductModule,
        expandByColumnOnly: true
    }
}

class TableProductModule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                productId: '',
                priceId: '',
                productModule: [],
            }
        }

        this.toggleEdit = this.toggleEdit.bind(this)

        this.props.subscriptionProductModuleStripeAction()
        this.props.subscriptionModuleAction()
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                productId: (typeof row != 'undefined') ? row.product_id : '',
                priceId: (typeof row != 'undefined') ? row.price_id : '',
                productModule: (typeof row != 'undefined') ? this.props.componentDdlbMultiOnChangeAction(ddlbParser(row.product_module, {
                    label: 'module_description',
                    value: 'module_code'
                })) : [],
            }
        })
    }

    toggleEdit(row) {
        this.setStateSelected(row)


        this.props.componentModalToggleAction({
            target: 'edit',
            isOpen: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader data={this.props.data} isDataTableLoading={this.props.isDataTableLoading} dataTable={
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <DataTable
                                            columns={columns({
                                                toggleEdit: this.toggleEdit,
                                            })}
                                            data={this.props.data}
                                            keyField='price_id'
                                            expandRow={ExpandRow(this.props.data)}
                                            isDataTableLoading={this.props.isDataTableLoading}
                                        />
                                        <ModalEditProductModule
                                            productId={this.state.selected.productId}
                                            priceId={this.state.selected.priceId}
                                            ddlbSubscriptionModule={ddlbParser(this.props.subscriptionModule, {
                                                label: 'description',
                                                value: 'code'
                                            })}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                } />
            </React.Fragment>
        )
    }
}

TableProductModule.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        data
    } = state.SubscriptionProductModuleStripe

    const subscriptionModule = {
        ...state.SubscriptionModule.data
    }

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        data,
        isDataTableLoading,
        subscriptionModule
    }
}

export default withTranslation()(connect(mapStateToProps, {
    subscriptionProductModuleStripeAction,
    subscriptionModuleAction,
    componentModalToggleAction,
    componentDdlbMultiOnChangeAction,
})(TableProductModule))