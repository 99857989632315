import {
    SUBSCRIPTION_CHECKER,
    SUBSCRIPTION_CHECKER_DATA,
    SUBSCRIPTION_CHECKER_NOTIFICATION,
} from './actionTypes'

export const subscriptionCheckerAction = (params) => ({
    type: SUBSCRIPTION_CHECKER,
    payload: { params }
})

export const subscriptionCheckerDataAction = (data) => ({
    type: SUBSCRIPTION_CHECKER_DATA,
    payload: { data },
})

export const subscriptionCheckerNotificationAction = (params) => ({
    type: SUBSCRIPTION_CHECKER_NOTIFICATION,
    payload: { params }
})