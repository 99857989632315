import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class IndexStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            indexCount  : null,
            reportUrl   : '',
            status      : null,
            message     : null
        }
    }

    sanitizeFilename(str) {
        str = str.replace('https://', '').replace('http://', '').replace('www.', '')
        if (str.endsWith('/')) {
            str = str.slice(0, -1)
        }

        return str
    }

    componentDidUpdate(prevProps) {
        const indexedPagesCount = getPropTraverse(this.props.reportSettings, ['indexedPagesCount']) || null;

        if (this.state.indexCount != null && indexedPagesCount != null && this.state.indexCount != indexedPagesCount) {
            this.updateReportSummary()
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        const sectionData     = getPropTraverse(this.props.reportData, ['report', 'indexingAndCrawlability']) || []
        let indexedPagesCount = getPropTraverse(this.props.reportSettings, ['indexedPagesCount']) || '';
        let reportSummary     = this.props.reportSummary
        let isIndexed         = (indexedPagesCount != '' && indexedPagesCount > 0)
        let status            = 'warning'
        let message           = 'Error: An error occurred while calling the endpoint service'

        if (indexedPagesCount == '') {
            indexedPagesCount = getPropTraverse(sectionData, ['indexStatus', 'index_count']) || 0
            isIndexed         = getPropTraverse(sectionData, ['indexStatus', 'is_indexed']) || false
        }

        status  = isIndexed ? 'success' : 'danger'
        message = isIndexed ? `The site has ${indexedPagesCount} page${indexedPagesCount > 1 ? 's' : ''} indexed by Google.` : 'The site has no pages being indexed by Google.'

        this.setState({
            indexCount : indexedPagesCount,
            status     : status,
            message    : message
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['index_status']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['index_status']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['index_status']
        }

        if (typeof reportSummary[status] == 'undefined') {
            reportSummary[status] = { index_status : message }
        } else {
            reportSummary[status]['index_status'] = message
        }

        this.props.saveReportSummary(reportSummary)
    }

    render() {

        return (
            <React.Fragment>
                <div id="indexStatusSection" className='mt-4'>
                    <h4>{ this.props.t('Index Status') }</h4>
                    <p>Using <em>site: { this.state.reportUrl }</em> protocol, we checked for the numbers of indexed pages in Google.com.</p>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'>{ (this.state.status != null ? <ReportIcon type={ this.state.status } /> : '')}</th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
};

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(IndexStatus));
