import React, {Component} from 'react'
import {connect} from "react-redux";
import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Col, Container, Row, Card, CardBody} from 'reactstrap'
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo.svg'
import lightlogo from 'assets/images/logo-light.svg'
import {pluckRouteFromName} from '../../helpers/util'
import FormRegister from 'app.cms.react/components/Authentication/FormRegister'

class Register extends Component {
    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Registration</title>
                </MetaTags>
                <div className="account-pages pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Sign up!</h5>
                                                    <p>Get your free account now.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={lightlogo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                            <Link to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="rounded-circle" height="34"/>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <FormRegister />
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="text-center">
                                    <p>
                                        Already have an account ?{" "}{" "}
                                        <Link to={pluckRouteFromName('login')} className="fw-medium text-primary">Login</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
};

export default connect(mapStateToProps, {})(Register)