import { takeEvery, put, call } from 'redux-saga/effects'
import fileDownload       from 'js-file-download'

import {
    fetchJSON,
    getPropTraverse,
    defaultGetOptions,
    buildUrlParams,
    defaultPutOptions,
    defaultPostOptions,
    defaultDeleteOptions,
    formatFormData,
} from '../../app.cms.react/helpers/util'


import {
    REPORTS,
    REPORTS_DATA,
    REPORT_DETAILS,
    REPORT_DETAILS_DATA,
    REPORTS_DELETE,
    REPORT_SETTINGS,
    GENERATE_REPORT,
    SAVE_REPORT,
    SAVE_REPORT_DATA,
    DOWNLOAD_REPORT,
    CHECK_TOKEN_STATUS,
    DOWNLOAD_REPORT_FILE
} from './actionTypes'

import {
    AJAX_URL_REPORTS,
    AJAX_URL_REPORT_DETAILS,
    AJAX_URL_GENERATE_REPORT,
    AJAX_URL_ENQUEUE_DOWNLOAD_REPORT,
    AJAX_URL_DOWNLOAD_REQUEST,
    AJAX_URL_CHECK_STATUS
} from '../actionTypes'

import {componentFormOnSubmitDoneAction} from '../../app.cms.react/store/components/form/actions';

import {
    reportsDataAction,
    reportDetailsDataAction,
    saveReportSettings,
    generateReportDataAction,
    reportsDeleteDoneAction,
    saveReportDataAction,
    downloadReportDataAction,
    checkTokenStatusDataAction,
    downloadReportFileDataAction,
    reportsDeleteByIdAction
} from './actions'

function* reports({ payload: { params, history}}) {
    let data = [];
    let polling = false;

    try {
        const url        = AJAX_URL_REPORTS + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultGetOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        data = getPropTraverse(response, ['response', 'data'])

        for (let i = 0; i < 10 && i < data.length; i++) {
            polling = data[i]?.status?.tokenize_request_status_code === 'in-progress' && true

            if (polling) break;
        }

    } catch (error) {

    }

    yield put(reportsDataAction(data, polling))
}

function* reportDetails({ payload: { params, history }}) {
    let data = [];

    try {
        const url = AJAX_URL_REPORT_DETAILS + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultGetOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        data = getPropTraverse(response, ['response', 'data'])

    } catch (error) {

    }

    yield put(reportDetailsDataAction(data))
}

function* reportDelete({ payload: { params, meta}}) {
    let componentResponse = {}

    if (params.primaryKey) {
        params = {
            report_id: params.primaryKey
        }
    }

    try {
        const url = AJAX_URL_REPORTS + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultDeleteOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data'])
        }

        yield put(reportsDeleteByIdAction(params.report_id))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* generateReport({ payload: { params }}) {
    let data = [];

    try {
        const url        = AJAX_URL_GENERATE_REPORT + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultPostOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        data = getPropTraverse(response, ['response', 'data'])
    } catch (e) {

    }

    yield put(generateReportDataAction(data))
}

function* saveReport({ payload: { params }}) {
    const formData = new FormData();
    let componentResponse = {}

    for ( var key in params ) {
        formData.append(key, params[key]);
    }

    let options = {
        method: 'POST',
        headers: new Headers({
            'X-Requested-With' : 'XMLHttpRequest',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
        credentials: 'same-origin',
        body: formData
    }

    try {
        const response   = yield call(fetchJSON, AJAX_URL_REPORT_DETAILS, options)
        const statusCode = getPropTraverse(response, ['statusCode'])

        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data'])
        }

    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(saveReportDataAction(componentResponse))
}

function* downloadReport({ payload: { params }}) {
    let data = [];

    try {
        const url        = AJAX_URL_ENQUEUE_DOWNLOAD_REPORT + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultPostOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        data = getPropTraverse(response, ['response', 'data'])
    } catch (e) {

    }

    yield put(downloadReportDataAction(data))
}

function* checkTokenStatus({ payload: { params }}) {
    let data = [];

    try {
        const url        = AJAX_URL_CHECK_STATUS + buildUrlParams(params)
        const response   = yield call(fetchJSON, url, defaultPostOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])

        data = getPropTraverse(response, ['response', 'data'])
    } catch (e) {

    }

    yield put(checkTokenStatusDataAction(data))
}

function* downloadReportFile({ payload: { params }}) {

    let data = [];
    let options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type'     : 'application/pdf',
            'Authorization'    : 'Bearer ' + localStorage.getItem('jwt')
        }),
    }

    try {
        const url = AJAX_URL_DOWNLOAD_REQUEST + buildUrlParams(params)
        fetch(url, options)
        .then(response => response.blob()).then(response => {
            fileDownload(response, params.filename)
        })
    } catch (error) {

    }

    yield put(downloadReportFileDataAction())
}


function* ReportsSaga() {
    yield takeEvery(REPORTS, reports)
    yield takeEvery(REPORT_DETAILS, reportDetails)
    yield takeEvery(REPORTS_DELETE, reportDelete)
    yield takeEvery(GENERATE_REPORT, generateReport)
    yield takeEvery(SAVE_REPORT, saveReport)
    yield takeEvery(DOWNLOAD_REPORT, downloadReport)
    yield takeEvery(CHECK_TOKEN_STATUS, checkTokenStatus)
    yield takeEvery(DOWNLOAD_REPORT_FILE, downloadReportFile)
}

export default ReportsSaga
