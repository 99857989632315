import React, {Component} from 'react'
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {paymentGatewayStripeCustomerPortalAction} from 'app.cms.react/store/actions'
import Breadcrumbs from 'components/Common/Breadcrumb'
import {Container} from 'reactstrap'
import MetaTags from 'react-meta-tags'

class Billing extends Component {
    constructor(props) {
        super(props)
        this.props.paymentGatewayStripeCustomerPortalAction()
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.props.t('Billing')}</title>
                </MetaTags>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Subscription')} breadcrumbItem={this.props.t('Billing')} />

                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Billing.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
    paymentGatewayStripeCustomerPortalAction,
})(Billing))