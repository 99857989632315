import React, {Component} from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next"
import ReportIcon          from '../../../Common/ReportIcon'
class SummaryStatus extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const icon = this.props.icon
        return (
            <React.Fragment>
                <div className="mb-4">
                    <h4 className='mb-1'>{ this.props.title }</h4>
                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                            { Object.keys(this.props.items).map((item, key) => (
                                ((item == 'above_the_fold') ? 
                                    <React.Fragment>
                                        <tr key={`${icon}_${key}_list`}>
                                            <th className="text-center fw-50"><ReportIcon type={icon} /></th>
                                            {
                                                 (this.props.items[item]['list']) ? (
                                                    <React.Fragment>
                                                        <td>
                                                            <p className='mb-0 pb-0'>{this.props.t(this.props.items[item]['list_before'])}:</p>
                                                            <ul className="ms-3">
                                                                { Object.keys(this.props.items[item]['list']).map((listItem, listKey) => ( 
                                                                    <li key={`${listKey}`}>{this.props.t(this.props.items[item]['list'][listItem])}</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                    </React.Fragment>
                                                ) : ''
                                            }
                                        </tr>
                                    </React.Fragment>
                                : (item === 'html_sitemap' || item === 'robots' || item === 'xml_sitemap') ? (
                                    ((typeof this.props.items[item]['message'] === 'string') &&
                                        (<React.Fragment>
                                        <tr key={`${icon}_${key}_link`}>
                                            <th className="text-center fw-50" rowSpan={2}><ReportIcon type={icon} /></th>
                                            {
                                                (this.props.items[item]['links']) && (
                                                    <td>{this.props.items[item]['links']}</td>
                                                )
                                            }
                                        </tr>
                                        { (this.props.items[item]['message'] != '') ? 
                                            <React.Fragment><tr key={`${icon}_${key}_custom_msg`}><td>{this.props.t(this.props.items[item]['message'])}</td></tr></React.Fragment> 
                                            : ''
                                        }
                                        </React.Fragment>)
                                    )
                                ) : (
                                    (typeof this.props.items[item] === 'string') && (
                                        <React.Fragment>
                                            <tr key={`${key}`}>
                                                <th className="text-center fw-50"><ReportIcon type={icon} /></th>
                                                <td>{this.props.t(this.props.items[item])}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                ))
                                
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSummary
    } = state.Reports

    return {
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps)(SummaryStatus))
