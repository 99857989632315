import React, {Component} from 'react'
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next'
import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import TablePermissions from 'app.cms.react/components/Acl/Permissions/TablePermissions'
import MetaTags from 'react-meta-tags'

class Permissions extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t('Permissions')}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Access Control List')} breadcrumbItem={this.props.t('Permissions')} />
                        <TablePermissions />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Permissions.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {

})(Permissions))