import React, {Component} from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import {Link} from 'react-router-dom'
import {Card, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'

class TableOfContent extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const websiteOptimization = getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == 'true' || getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == true
        const organicSeo          = getPropTraverse(this.props.reportSettings, ['organicSeo']) == 'true' || getPropTraverse(this.props.reportSettings, ['organicSeo']) == true

        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h3 className='mb-0'>{ this.props.t('Table of Contents') }</h3>
                </div>

                <div className='my-auto toc'>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#summarySection">{ this.props.t('Summary')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#indexingAndCrawlabilitySection">{ this.props.t('Indexing and Crawlability')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#indexStatusSection">{ this.props.t('Index Status') }</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#httpVersionSection">{ this.props.t('HTTP/HTTPS Versions') }</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#wwwVersionSection">{ this.props.t('WWW and non-WWW Versions')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#technicalSeoSection">{ this.props.t('Technical SEO')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#googleAnalyticsSection">{ this.props.t('Google Analytics')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#googleSearchConsoleSection">{ this.props.t('Google Search Console')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#mobileFriendlySection">{ this.props.t('Mobile Friendly Check')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#pageLoadSpeedSection">{ this.props.t('Page Load Speed')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#webCoreVitalsSection">{ this.props.t('Web Core Vitals')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#pageStatusCodeSection">{ this.props.t('4xx Status Code')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#xmlSiteMapSection">{ this.props.t('XML Sitemap')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#robotsSection">{ this.props.t('Robots Exclusion Protocol (Robots.txt)')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#onPageSeoSection">{ this.props.t('On-page SEO')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#urlStructureSection">{ this.props.t('URL Structure')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#rankingKeywordSection">{ this.props.t('Ranking Keywords')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#aboveTheFoldSection">{ this.props.t('Above-the-Fold Page Section')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#headingsSection">{ this.props.t('Headings')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#metaTitleSection">{ this.props.t('Meta Title')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#metaDescriptionSection">{ this.props.t('Meta Description')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#internalLinksSection">{ this.props.t('Internal Links')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#dofollowExternalLinksSection">{ this.props.t('Dofollow External Links')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#imageAltTextSection">{ this.props.t('Image Alt Text')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#offPageSeoSection">{ this.props.t('Off-page SEO')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#backlinkCountSection">{ this.props.t('Backlink count')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#anchorCloudSection">{ this.props.t('Anchors cloud')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#reputationSignalsSection">{ this.props.t('Reputation Signals')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#malwareCheckSection">{ this.props.t('Malware Check')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#socialMediaButtonsSection">{ this.props.t('Social Media Buttons')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6><a href="#schemaMarkupSection">{ this.props.t('JSON-LD Schema Markup')}</a></h6>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xl={12}>
                            <h6 className="ms-4"><a href="#schemaMarkupSection">{ this.props.t('Site-wide Schema Integration')}</a></h6>
                        </Col>
                    </Row>
                    { (websiteOptimization && organicSeo) && (
                        <Row className='g-0'>
                            <Col xl={12}>
                                <h6><a href="#recommendationSection">{ this.props.t('Recommendations')}</a></h6>
                            </Col>
                        </Row>
                    ) }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings
    } = state.Reports

    return {
        reportData,
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps)(TableOfContent))
