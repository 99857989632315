import { takeEvery, put, call } from 'redux-saga/effects'
import {ACCOUNT_PASSWORD_UPDATE} from './actionTypes'
import {AJAX_URL_ACCOUNT_PASSWORD} from 'app.cms.react/store/actionTypes'
import {defaultPutOptions, fetchJSON, formatFormData, getPropTraverse} from 'app.cms.react/helpers/util'
import {componentFormOnSubmitDoneAction} from 'app.cms.react/store/actions'

function* accountPasswordUpdate({ payload: { params }}) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        current_password: params.current_password,
        password: params.password,
        password_confirmation: params.password_confirmation,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACCOUNT_PASSWORD, options)
        const statusCode = getPropTraverse(response, ['statusCode']);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* AccountPasswordSaga() {
    yield takeEvery(ACCOUNT_PASSWORD_UPDATE, accountPasswordUpdate)
}

export default AccountPasswordSaga