import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'
import { withTranslation } from "react-i18next"

// section's parts
import UrlStructure          from './UrlStructure'
import RankingKeywords       from './RankingKeywords'
import AboveTheFold          from './AboveTheFold'
import Headings              from './Headings'
import MetaTitle             from './MetaTitle'
import MetaDescription       from './MetaDescription'
import InternalLinks         from './InternalLinks'
import DofollowExternalLinks from './DofollowExternalLinks'
import ImageAltText          from './ImageAltText'

class Main extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>{ this.props.t('On-page SEO') }</h2>
                </div>

                <UrlStructure />
                <RankingKeywords />
                <AboveTheFold />
                <Headings />
                <MetaTitle />
                <MetaDescription />
                <InternalLinks />
                <DofollowExternalLinks />
                <ImageAltText />
            </React.Fragment>
        )
    }
}

export default withTranslation()(Main);
