// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  ACCOUNT_NOTIFICATION,
  ACCOUNT_NOTIFICATION_DATA,
  ACCOUNT_READ_NOTIFICATION,
  ACCOUNT_READ_NOTIFICATION_DATA
} from "./actionTypes"

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarThemeImage: "none",
  leftSideBarType: "default",
  topbarTheme: "light",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  notifications: [],
  isLoading: false,
  notificationUpdated: false,
  motifFirstLoad: false
}

import {getPropTraverse} from '../../app.cms.react/helpers/util'

const Layout = (state = INIT_STATE, action) => {

  const { type, payload } = action
  
  switch (type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      }
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      }
    case ACCOUNT_NOTIFICATION:
        return {
            ...state,
            isLoading: true
        }
    case ACCOUNT_NOTIFICATION_DATA:
        let notifData = getPropTraverse(payload, ['data']);
        let notificationUpdated = false

        if (state.motifFirstLoad) {
            notificationUpdated = (state.notifications.length != notifData.length)
        }

        return {
            ...state,
            isLoading: false,
            motifFirstLoad: true,
            notificationUpdated: notificationUpdated,
            notifications: notifData
        }
    case ACCOUNT_READ_NOTIFICATION:
        return {
            ...state,
            isLoading: true,
        }
    case ACCOUNT_READ_NOTIFICATION_DATA:
        return {
            ...state,
            isLoading: false,
            notifications: getPropTraverse(payload, ['data']),
        }
    default:
      return state
  }
}

export default Layout
