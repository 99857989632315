import {connect}            from 'react-redux';
import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'
import i18n from "../../../i18n"

class LanguageSettings extends Component {
    constructor(props) {
        super(props)
        let lang =  this.props.reportSettings.language || 'en';

        this.state = {
            language : lang,
        }

        i18n.changeLanguage(lang)
        this.updateLanguageSettings = this.updateLanguageSettings.bind(this)
    }

    updateLanguageSettings(value) {
        const reportSettings = this.props.reportSettings
        const data = {
            language : this.state.language,
        }

        i18n.changeLanguage(value)
        this.setState({ ...data, 'language' : value})
        reportSettings['language'] = value
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportSettings, token: this.props.token})
    }

    render(){
        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="usLanguage"
                                    checked={this.state.language == 'en'}
                                    onChange={e => {
                                        this.updateLanguageSettings('en')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="usLanguage"
                                    >
                                    { this.props.t('US') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="ukLanguage"
                                    checked={this.state.language == 'uk'}
                                    onChange={e => {
                                        this.updateLanguageSettings('uk')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="ukLanguage"
                                    >
                                    { this.props.t('UK') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

LanguageSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportSettings
    } = state.Reports

    return {
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(LanguageSettings))
