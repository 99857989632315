import React, {Component} from 'react'
import {connect}          from "react-redux";
import PropTypes          from 'prop-types'
import MetaTags           from 'react-meta-tags';
import SweetAlert         from "react-bootstrap-sweetalert"
import toastr             from "toastr"
import ReactDrawer        from 'react-drawer';
import { Redirect, Link }       from 'react-router-dom';
import DownloadLink       from "react-download-link";
import {Card, Button, Col, Container, Row, Spinner} from 'reactstrap'
import fileDownload       from 'js-file-download'
import moment             from 'moment';
import qs                 from 'qs'
import 'react-drawer/lib/react-drawer.css';
import 'toastr/build/toastr.min.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getPropTraverse, pluckRouteFromName } from '../../app.cms.react/helpers/util'
import { reportDetailsAction, reportDetailsDataAction, reportsDeleteAction, downloadReportAction, checkTokenStatusAction, downloadReportFileAction } from '../../store/actions'

//i18n
import { withTranslation } from "react-i18next"
import ReportSettings          from '../../components/Report/Settings'
import ReportEditDisplay       from './ReportEditDisplay'
import Pages404                from '../Utility/pages-404'
import ReportViewDoc           from '../../pages/Report/ReportView'

class ReportView extends Component {
    constructor(props) {
        super(props)

        const reportToken = props.match.params.any
        const testDownload = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).test || 0

        this.state = {
            report_id: false,
            token: reportToken,
            confirm_delete: false,
            success_dlg: false,
            dynamic_title: '',
            dynamic_description: '',
            setting_open: false,
            setting_position: 'right',
            redirect: '',
            requestInterval : null,
            reportUrl: '',
            testDownload: testDownload
        }

        this.onSettingClose = this.onSettingClose.bind(this);
        this.deleteReport   = this.deleteReport.bind(this)
        this.getReportDetails()
    }

    componentDidUpdate(prevProps)
    {
        const prevStatus           = getPropTraverse(prevProps.reportData, ['status', 'tokenize_request_status_code'])
        const status               = getPropTraverse(this.props.reportData, ['status', 'tokenize_request_status_code'])
        const downloadTokenStatus  = this.props.downloadTokenStatus
        const downloadErrorMessage = this.props.downloadErrorMessage
        let reportUrl              = getPropTraverse(this.props.reportData, ['url'])

        if (reportUrl) {
            reportUrl = this.sanitizeFilename(reportUrl)
        }

        if (this.state.token !== this.props.match.params.any) {
            this.setState({
                token: this.props.match.params.any
            })

            window.location.reload()
        }

        if (status == 'open' || status == 'in-progress') {
            setTimeout(() => {
                this.getReportDetails()
            }, 5000)
        }

        if (prevProps.downloadTokenStatus != this.props.downloadTokenStatus) {
            switch (this.props.downloadTokenStatus) {
                case 'completed':
                    this.props.downloadReportFileAction({
                        token: this.props.downloadToken,
                        filename: reportUrl + '-' + moment().format('YYYYMMDDHHmmss') + '.pdf',
                        report_token: this.state.token
                    })
                    break;
                case 'done':
                    this.showToaster('success', 'Download Success', 'Report successfully downloaded!')
                    break;
                case 'failed':
                    if (downloadErrorMessage) {
                        this.showToaster('error', 'Download Error', downloadErrorMessage)
                    }
                    break;
                case 'open':
                case 'in-progress':
                    setTimeout(() => {
                        if (this.props.downloadToken != null) {
                            this.props.checkTokenStatusAction({
                                token : this.props.downloadToken,
                            })
                        }
                    }, 5000)
                    break;
            }
        }
    }

    deleteReport(e)
    {
        const reportId = getPropTraverse(this.props.reportData, ['report_id'])
        this.props.reportsDeleteAction({
            report_id: reportId
        })

        this.setState({
            confirm_delete: false,
            success_dlg: true,
            dynamic_title: "Deleted",
            dynamic_description: "Report has been deleted.",
        })
    }

    getReportDetails()
    {
        this.props.reportDetailsAction({
            token : this.state.token
        })
    }

    onSettingClose() {
        this.setState({ setting_open: false });
    }

    showToaster(type, title, message)
    {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            timeOut: 5000,
            extendedTimeOut: 1000,
            closeButton: true,
            behaviorButton: true,
            progressBar: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
        }

        switch (type) {
            case 'info':
                toastr.info(message, title)
                break;
            case 'warning' :
                toastr.warning(message, title)
                break;
            case 'error' :
                toastr.error(message, title)
                break;
            default :
                toastr.success(message, title)
                break;
        }
    }

    downloadReport(url) {
        this.props.downloadReportAction({
            url     : process.env.REACT_APP_BASE_URL + 'reports/web/' + this.state.token + '?t' + moment().valueOf(),
            name    : url + '.pdf',
            url_box : 1,
            test    : this.state.testDownload,
            report_token   : this.state.token
        })
    }

    settingsCallback = (value) => {
        this.setState({
            setting_open : value
        })
    }

    sanitizeFilename(str) {
        str = str.replace('https://', '').replace('http://', '')
        if (str.endsWith('/')) {
            str = str.slice(0, -1)
        }

        return str
    }

    render() {
        const reportData      = this.props.reportData;
        const downloadLoading = this.props.downloadLoading
        const status          = getPropTraverse(reportData, ['status', 'tokenize_request_status_code'])
        let reportUrl         = getPropTraverse(reportData, ['url'])

        if (reportUrl) {
            reportUrl = this.sanitizeFilename(reportUrl)
        }

        return (
            <React.Fragment>
                { this.props.pageIsLoading ? (
                    <div className="page-content">
                        <Container fluid className="text-center p-5 mt-5">
                            <div
                                className="spinner-grow text-primary m-1"
                                role="status"
                                >
                                <span className="sr-only">{ this.props.t('loading') }</span>
                            </div>
                            <div
                                className="spinner-grow text-primary m-1"
                                role="status"
                                >
                                <span className="sr-only">{ this.props.t('loading') }</span>
                            </div>
                            <div
                                className="spinner-grow text-primary m-1"
                                role="status"
                                >
                                <span className="sr-only">{ this.props.t('loading') }</span>
                            </div>
                            <h4 className="mt-3 mb-5">{ this.props.t('Please wait while we generate the report') }</h4>
                        </Container>
                    </div>
                ) : (
                <React.Fragment>
                    <ReactDrawer
                        open={ this.state.setting_open }
                        position={this.state.setting_position}
                        onClose={this.onSettingClose}
                        >
                        <ReportSettings token={this.state.token} onClose={this.onSettingClose} />
                    </ReactDrawer>
                    <div className="page-content">
                        <Container fluid>
                            {/* Render Breadcrumb */}
                            <Breadcrumbs
                                title={this.props.t("Dashboard")}
                                breadcrumbItem={ getPropTraverse(this.props.reportData, ['name']) || '' }
                            />
                        </Container>

                        {this.state.success_dlg ? (
                          <SweetAlert
                            success
                            title={this.state.dynamic_title}
                            onConfirm={() => this.setState({ success_dlg: false })}
                          >
                            {this.state.dynamic_description}
                          </SweetAlert>
                        ) : null}

                        { (Object.keys(this.props.reportData).length > 0) && (
                        <React.Fragment>
                            { status == 'completed' ? (
                                <React.Fragment>
                                    <div className='text-center mb-3 button-items position-fixed d-grid bottom-0 end-0 report-buttons'>
                                        <button type="button" className="btn btn-info btn-label" onClick={() => this.setState({ setting_open: !this.state.setting_open })}>
                                            <i className="bx bx-pencil label-icon" /> {this.props.t('Edit Report')}
                                        </button>
                                        { downloadLoading ? (
                                            <button type="button" className="btn btn-primary btn-label" disabled>
                                                <span className="label-icon"><i className="bx bx-loader bx-spin " /></span> {this.props.t('Downloading...')}
                                            </button>
                                        ) : (
                                            <button type="button" className="btn btn-primary btn-label" onClick={() => this.downloadReport(reportUrl)}>
                                                <i className="bx bx-download label-icon" /> {this.props.t('Download')}
                                            </button>
                                        )}
                                        <button type="button" className="btn btn-danger btn-label" onClick={() => this.setState({ confirm_delete: true })}>
                                            <i className="bx bx-trash label-icon" /> {this.props.t('Delete')}
                                        </button>
                                    </div>
                                    {this.state.confirm_delete ? (
                                      <SweetAlert
                                            title={this.props.t('Are you sure?')}
                                            warning
                                            showCancel
                                            confirmButtonText={this.props.t('Yes, delete it!')}
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="danger"
                                            onConfirm={this.deleteReport}
                                            onCancel={() =>
                                                this.setState({
                                                    confirm_delete: false,
                                                })
                                            }
                                      >
                                        {this.props.t('You won\'t be able to revert this!')}
                                      </SweetAlert>
                                    ) : null}
                                    <ReportDisplay reportData={ this.props.reportData } />
                                </React.Fragment>
                            ) : (
                                <div className="page-content">
                                    <Container fluid className="text-center p-5 mt-5">
                                        <h1><span className='text-warning'><i className='fas fa-exclamation-triangle'></i></span></h1>
                                        <h4 className="mt-3 mb-3">There&apos;s an error while generating your report, please try again later.</h4>
                                        <Link className="btn btn-primary" to={pluckRouteFromName('reports')}>
                                            {this.props.t('Back to Reports')}
                                        </Link>
                                    </Container>
                                </div>
                            )}
                        </React.Fragment>)}
                    </div>
                </React.Fragment>)}

            </React.Fragment>
        )
    }
}

function ReportDisplay(props) {
    if (Object.keys(props.reportData).length) {
        return (
            <React.Fragment>
                <Container fluid className="report-page">
                    <ReportEditDisplay />
                </Container>
            </React.Fragment>
        )
    } else {
        return <Pages404 />
    }
}

const mapStateToProps = (state) => {
    const {
        reportData,
        pageIsLoading,
        response,
        alertType,
        downloadLoading,
        downloadToken,
        downloadTokenStatus,
        downloadErrorMessage
    } = state.Reports

    return {
        reportData,
        pageIsLoading,
        response,
        alertType,
        downloadLoading,
        downloadToken,
        downloadTokenStatus,
        downloadErrorMessage
    }
}

export default withTranslation()(connect(mapStateToProps, {
    reportDetailsAction,
    reportDetailsDataAction,
    reportsDeleteAction,
    downloadReportAction,
    checkTokenStatusAction,
    downloadReportFileAction
})(ReportView));
