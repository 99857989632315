import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class ImageAltText extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePage']) || []
        let noAltsCount   = getPropTraverse(sectionData, ['images', 'noAltsCount']) || false

        if (noAltsCount == false) {
            noAltsCount = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoImageResources', 'no_alts_images_count']) || 0
        }

        this.state = {
            sectionData : sectionData,
            noAltsCount : noAltsCount,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = (this.state.noAltsCount == 0) ? 'success' : 'danger'
            let message = (this.state.noAltsCount == 0)
                        ? 'Alt text attributes are found on all images.'
                        : 'Not good! We found ' + this.state.noAltsCount + ' image' + (this.state.noAltsCount > 1 ? 's' : '') + ' with empty alt attributes.'

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { image_alt_text : message }
            } else {
                reportSummary[status]['image_alt_text'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="imageAltTextSection" className='mt-4'>
                    <h4>{ this.props.t('Image Alt Text') }</h4>
                    <p>{ this.props.t('ALT tags provide a text alternative to an image. The most important function of an ALT tag is to explain to a blind user what an image is displaying. Search engine crawlers also use ALT tags to decipher what an image is or what it is representing.') }</p>

                    <div className='p-3 mb-5'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type={ this.state.status } /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(ImageAltText))
