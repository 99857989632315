import React, {Component} from 'react'
import {connect} from "react-redux";
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'
import { Col, Row } from 'reactstrap'

class SocialMediaButtons extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sectionData  : [],
            hasFacebook  : null,
            hasTwitter   : null,
            hasLinkedIn  : null,
            hasYoutube   : null,
            hasPinterest : null,
            hasInstagram : null,
            hasYelp      : null,
            status       : null,
            message      : null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let hasFacebook = getPropTraverse(this.props.reportSettings, ['hasFacebook']) != null ? getPropTraverse(this.props.reportSettings, ['hasFacebook']) : this.state.hasFacebook
        let hasTwitter  = getPropTraverse(this.props.reportSettings, ['hasTwitter']) != null ? getPropTraverse(this.props.reportSettings, ['hasTwitter']) : this.state.hasTwitter
        let hasLinkedIn = getPropTraverse(this.props.reportSettings, ['hasLinkedIn']) != null ? getPropTraverse(this.props.reportSettings, ['hasLinkedIn']) : this.state.hasLinkedIn
        let hasYoutube  = getPropTraverse(this.props.reportSettings, ['hasYoutube']) != null ? getPropTraverse(this.props.reportSettings, ['hasYoutube']) : this.state.hasYoutube
        let hasPinterest = getPropTraverse(this.props.reportSettings, ['hasPinterest']) != null ? getPropTraverse(this.props.reportSettings, ['hasPinterest']) : this.state.hasPinterest
        let hasInstagram = getPropTraverse(this.props.reportSettings, ['hasInstagram']) != null ? getPropTraverse(this.props.reportSettings, ['hasInstagram']) : this.state.hasInstagram
        let hasYelp      = getPropTraverse(this.props.reportSettings, ['hasYelp']) != null ? getPropTraverse(this.props.reportSettings, ['hasYelp']) : this.state.hasYelp

        if (typeof hasFacebook == 'string' && hasFacebook.trim() != '') {
            hasFacebook = (hasFacebook === 'true')
        }
        
        if (typeof hasTwitter == 'string' && hasTwitter.trim() != '') {
            hasTwitter = (hasTwitter === 'true')
        }

        if (typeof hasLinkedIn == 'string' && hasLinkedIn.trim() != '') {
            hasLinkedIn = (hasLinkedIn === 'true')
        }

        if (typeof hasYoutube == 'string' && hasYoutube.trim() != '') {
            hasYoutube = (hasYoutube === 'true')
        }

        if (typeof hasPinterest == 'string' && hasPinterest.trim() != '') {
            hasPinterest = (hasPinterest === 'true')
        }

        if (typeof hasInstagram == 'string' && hasInstagram.trim() != '') {
            hasInstagram = (hasInstagram === 'true')
        }

        if (typeof hasYelp == 'string' && hasYelp.trim() != '') {
            hasYelp = (hasYelp === 'true')
        }

        if ((this.state.hasFacebook != null && hasFacebook != this.state.hasFacebook) ||
            (this.state.hasTwitter != null && hasTwitter != this.state.hasTwitter) || 
            (this.state.hasLinkedIn != null && hasLinkedIn != this.state.hasLinkedIn) || 
            (this.state.hasYoutube != null && hasYoutube != this.state.hasYoutube) || 
            (this.state.hasPinterest != null && hasPinterest != this.state.hasPinterest) || 
            (this.state.hasInstagram != null && hasInstagram != this.state.hasInstagram) || 
            (this.state.hasYelp != null && hasYelp != this.state.hasYelp)
        ) {
            this.updateReportSummary()
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let hasFacebook    = (typeof this.props.reportSettings.hasFacebook != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasFacebook']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'facebook']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'facebook']).length > 0)
        let hasTwitter     = (typeof this.props.reportSettings.hasTwitter != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasTwitter']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'twitter']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'twitter']).length > 0)
        let hasLinkedIn    = (typeof this.props.reportSettings.hasLinkedIn != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasLinkedIn']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'linkedin']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'linkedin']).length > 0)
        let hasYoutube     = (typeof this.props.reportSettings.hasYoutube != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasYoutube']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'youtube']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'youtube']).length > 0)
        let hasPinterest   = (typeof this.props.reportSettings.hasPinterest != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasPinterest']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'pinterest']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'pinterest']).length > 0)
        let hasInstagram   = (typeof this.props.reportSettings.hasInstagram != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasInstagram']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'instagram']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'instagram']).length > 0)
        let hasYelp        = (typeof this.props.reportSettings.hasYelp != 'undefined') ? getPropTraverse(this.props.reportSettings, ['hasYelp']) : (getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'yelp']) != null && getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'dataForSeoSocialLinks', 'yelp']).length > 0)
        let reportSummary  = this.props.reportSummary
        let message        = 'We found no social media buttons on the site. Please verify if there are have social media profiles that we can add a link from the site.'
        let status         = 'danger'

        if (typeof hasFacebook == 'string' && hasFacebook.trim() != '') {
            hasFacebook = (hasFacebook === 'true')
        }
        
        if (typeof hasTwitter == 'string' && hasTwitter.trim() != '') {
            hasTwitter = (hasTwitter === 'true')
        }

        if (typeof hasLinkedIn == 'string' && hasLinkedIn.trim() != '') {
            hasLinkedIn = (hasLinkedIn === 'true')
        }

        if (typeof hasYoutube == 'string' && hasYoutube.trim() != '') {
            hasYoutube = (hasYoutube === 'true')
        }

        if (typeof hasPinterest == 'string' && hasPinterest.trim() != '') {
            hasPinterest = (hasPinterest === 'true')
        }

        if (typeof hasInstagram == 'string' && hasInstagram.trim() != '') {
            hasInstagram = (hasInstagram === 'true')
        }

        if (typeof hasYelp == 'string' && hasYelp.trim() != '') {
            hasYelp = (hasYelp === 'true')
        }

        if (hasFacebook && hasTwitter && hasLinkedIn && hasYoutube && hasPinterest && hasInstagram && hasYelp) {
            status  = 'success'
            message = ' Social media buttons are present on the site and are linking to the correct social media profiles.'
        } else if (hasFacebook || hasTwitter || hasLinkedIn || hasYoutube || hasPinterest || hasInstagram || hasYelp) {
            status  = 'warning'
            message = 'We found social media buttons on the site but not all of the highly recommended ones are present. Please verify if there are other social media profiles that we can add a link to from the site.'
        }

        this.setState({
            status       : status,
            message      : message,
            hasFacebook  : hasFacebook,
            hasTwitter   : hasTwitter,
            hasLinkedIn  : hasLinkedIn,
            hasYoutube   : hasYoutube,
            hasPinterest : hasPinterest,
            hasInstagram : hasInstagram,
            hasYelp      : hasYelp,
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['social_media_buttons']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['social_media_buttons']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['social_media_buttons']
        }
       
        if (typeof reportSummary[status] == 'undefined') {
            reportSummary[status] = { social_media_buttons : message }
        } else {
            reportSummary[status]['social_media_buttons'] = message
        }

        this.props.saveReportSummary(reportSummary)
    }

    render() {
        return (
            <React.Fragment>
                <div id="socialMediaButtonsSection" className='mt-4'>
                    <h4>Social Media Buttons</h4>
                    <p>Social Media can give increased brand awareness, which translates to higher visibility and word of mouth advertising.</p>

                    <Row className='g-0 p-3'>
                        <Col xs={7}>
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className='text-center fw-50'>
                                            { (this.state.status != null) ? <ReportIcon type={this.state.status} /> : ''}
                                        </th>
                                        <td>{this.state.message}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col xs={4} className="ps-3">
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-facebook-square'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Facebook
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasFacebook ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-twitter-square'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Twitter
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasTwitter ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-linkedin-in'></i>
                                    </Col>
                                    <Col xs={6}>
                                        LinkedIn
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasLinkedIn ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-youtube'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Youtube
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasYoutube ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-pinterest'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Pinterest
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasPinterest ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-instagram'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Instagram
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasInstagram ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                              <Row className="mb-2">
                                    <Col xs={2}>
                                        <i className='fab fa-yelp'></i>
                                    </Col>
                                    <Col xs={6}>
                                        Yelp
                                    </Col>
                                    <Col xs={4}>
                                        <ReportIcon type={ this.state.hasYelp ? 'success' : 'warning' } />
                                    </Col>
                              </Row>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(SocialMediaButtons));
