import { takeEvery, put, call } from 'redux-saga/effects'
import {REGISTER_USER} from './actionTypes'
import {defaultPostOptions, fetchJSON, formArgsExcludeEmpty, formatFormData, getPropTraverse,} from 'app.cms.react/helpers/util'
import {AJAX_URL_REGISTER} from 'app.cms.react/store/actionTypes'
import {setSession} from 'app.cms.react/helpers/authUtils'
import {componentFormOnSubmitDoneAction, gtmDataAction, registerUserSuccessAction} from 'app.cms.react/store/actions'

function* registerUser({ payload: { params, meta } }) {
    let options  = defaultPostOptions()
    let componentResponse = {}

    options.body = formatFormData(formArgsExcludeEmpty({
        first_name: params.firstName,
        last_name: params.lastName,
        email: params.email,
        company_name: params.companyName,
        password: params.password,
        password_confirmation: params.passwordConfirmation,
    }));

    try {
        const response = yield call(fetchJSON, AJAX_URL_REGISTER, options);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        const responseData = getPropTraverse(response, ['response', 'data']);
        yield put(registerUserSuccessAction(responseData))
        setSession(response)
        yield put(gtmDataAction(responseData))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* RegisterSaga() {
    yield takeEvery(REGISTER_USER, registerUser)
}

export default RegisterSaga