import { takeEvery, put, call } from 'redux-saga/effects'
import {defaultPutOptions, formatFormData, buildUrlParams, fetchJSON, defaultGetOptions, getPropTraverse} from 'app.cms.react/helpers/util'
import {ACCOUNT_PROFILE, ACCOUNT_PROFILE_UPDATE} from './actionTypes'
import {AJAX_URL_ACCOUNT_PROFILE} from '../../actionTypes'
import {accountProfileDataAction, componentFormOnSubmitDoneAction} from 'app.cms.react/store/actions'

function* accountProfile({ payload: { params, history } }) {
    try {
        const url = AJAX_URL_ACCOUNT_PROFILE + buildUrlParams(params)

        const response   = yield call(fetchJSON, url, defaultGetOptions())
        const statusCode = getPropTraverse(response, ['statusCode'])
        const data       = getPropTraverse(response, ['response', 'data'])

        yield put(accountProfileDataAction(data))
    } catch (error) {
        console.error(error)
    }
}

function* accountProfileUpdate({ payload: { params, meta } }) {
    let options  = defaultPutOptions();
    let componentResponse = {}

    options.body = formatFormData({
        id: params.id,
        first_name: params.first_name,
        last_name: params.last_name,
        company_name: params.company_name,
    });

    try {
        const response   = yield call(fetchJSON, AJAX_URL_ACCOUNT_PROFILE, options)
        const statusCode = getPropTraverse(response, ['statusCode']);
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getPropTraverse(error, ['data', 'response', 'error']),
        }
    }

    yield put(componentFormOnSubmitDoneAction(componentResponse))
}

function* AccountProfileSaga() {
    yield takeEvery(ACCOUNT_PROFILE, accountProfile)
    yield takeEvery(ACCOUNT_PROFILE_UPDATE, accountProfileUpdate)
}

export default AccountProfileSaga