import {
    ACCOUNT_PROFILE,
    ACCOUNT_PROFILE_DATA,
    ACCOUNT_PROFILE_UPDATE,
} from './actionTypes'

export const accountProfileAction = (params) => ({
    type: ACCOUNT_PROFILE,
    payload: { params },
})

export const accountProfileDataAction = (data) => ({
    type: ACCOUNT_PROFILE_DATA,
    payload: { data },
})
export const accountProfileUpdateAction = (params, meta) => ({
    type: ACCOUNT_PROFILE_UPDATE,
    payload: { params, meta },
})