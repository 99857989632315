import React, {Component} from 'react'
import {connect} from "react-redux";
//i18n
import { withTranslation } from "react-i18next"
import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'

import IndexStatus  from './IndexStatus'
import HttpVersions from './HttpVersions'
import WwwVersions  from './WwwVersions'

class IndexingAndCrawlability extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>{ this.props.t('Indexing and Crawlability') }</h2>
                </div>
                <IndexStatus />
                <HttpVersions />
                <WwwVersions />
            </React.Fragment>
        )
    }
}

export default withTranslation()(IndexingAndCrawlability)
