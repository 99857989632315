import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import NothingHere from 'app.cms.react/assets/images/nothing-here.png'

class PlaceholderEmptyState extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="mt-2 text-center">
                    {(typeof this.props.header != 'undefined') ? (<h3>{this.props.header}</h3>) : (<h3>Nothing here</h3>)}
                    {(typeof this.props.context != 'undefined') ? (<p>{this.props.context}</p>) : (<p>You haven't added any records yet.</p>)}
                    <img src={NothingHere} width="400" />
                </div>
            </React.Fragment>
        )
    }
}

PlaceholderEmptyState.propTypes = {
    t: PropTypes.any,
    header: PropTypes.any,
    context: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
})(PlaceholderEmptyState))