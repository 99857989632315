import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Card, CardBody, Col, Container, List, ListInlineItem, Row} from 'reactstrap'
import DataTable from '../../DataTable'
import {ddlbParser} from 'app.cms.react/helpers/util'
import ModalAddRole from './ModalAddRole'
import ModalEditRole from './ModalEditRole'
import ModalDelete from '../../ModalDelete'
import {aclRolesAction, aclRolesDeleteAction, aclPermissionsAction, componentModalInitAction, componentModalToggleAction, componentDdlbMultiOnChangeAction} from 'app.cms.react/store/actions'
import DataTableLoader from '../../DataTableLoader'

const ColFormatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <List type="inline" className="p-0 m-0">
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleEdit(row, e)
                }}><i className="bx bx bx-pencil text-primary" /></ListInlineItem>
                <ListInlineItem onClick={(e) => {
                    formatExtraData.toggleDelete(row, e)
                }}><i className="bx bx-trash text-danger" /></ListInlineItem>
            </List>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        formatter: ColFormatActions,
        formatExtraData: {
            toggleEdit: props.toggleEdit,
            toggleDelete: props.toggleDelete,
        }
    },
];

class TableRoles extends Component {
    constructor(props) {
        super(props);

        this.props.aclRolesAction()
        this.props.aclPermissionsAction()

        this.toggleEdit = this.toggleEdit.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)

        this.state = {
            selected: {
                id: '',
                slug: '',
                name: '',
            }
        }
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
                slug: (typeof row != 'undefined') ? row.slug : '',
                name: (typeof row != 'undefined') ? row.name : '',
                permissions: (typeof row != 'undefined') ? this.props.componentDdlbMultiOnChangeAction(ddlbParser(row.permissions, {
                    label: 'name',
                    value: 'slug'
                })) : [],
            }
        })
    }

    toggleEdit(row) {
        this.setStateSelected(row)

        this.props.componentModalToggleAction({
            target: 'edit',
            isOpen: true
        })
    }

    toggleDelete(row) {
        this.setStateSelected(row)

        this.props.componentModalToggleAction({
            target: 'delete',
            isOpen: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader data={this.props.data} isDataTableLoading={this.props.isDataTableLoading} dataTable={
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <DataTable
                                            columns={columns({
                                                toggleEdit: this.toggleEdit,
                                                toggleDelete: this.toggleDelete,
                                            })}
                                            data={this.props.data}
                                            toggleAddModal={{
                                                target: 'add',
                                                isOpen: true
                                            }}
                                            isDataTableLoading={this.props.isDataTableLoading}
                                        />
                                        <ModalAddRole />
                                        <ModalEditRole
                                            id={this.state.selected.id}
                                            name={this.state.selected.name}
                                            ddlbPermissions={ddlbParser(this.props.permissions, {
                                                label: 'name',
                                                value: 'slug'
                                            })}
                                        />
                                        <ModalDelete
                                            primaryKey={
                                                {
                                                    id: this.state.selected.id
                                                }
                                            }
                                            deleteAction={this.props.aclRolesDeleteAction}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                } />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        data,
    } = state.AclRoles

    const permissions = {
        ...state.AclPermissions.data
    }

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        data,
        isDataTableLoading,
        permissions,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    aclRolesAction,
    aclRolesDeleteAction,
    aclPermissionsAction,
    componentDdlbMultiOnChangeAction,
    componentModalInitAction,
    componentModalToggleAction,
})(TableRoles))