import React, { Component } from 'react'
import MetaTags from 'react-meta-tags';
import { withTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Input
} from "reactstrap"
import { Link } from "react-router-dom"
import GenerateReport from '../../components/Report/GenerateReport'
import Breadcrumbs from '../../components/Common/Breadcrumb'

//import Charts
import StackedColumnChart from "./StackedColumnChart"
// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"

class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [
                { title: "Reports", iconClass: "bx-copy-alt", description: "55" },
                {
                    title: "Downloads",
                    iconClass: "bx-archive-in",
                    description: "100",
                },
                {
                    title: "Sent",
                    iconClass: "bx-purchase-tag-alt",
                    description: "20",
                },
            ],
            email: [
                { title: "Week", linkto: "#", isActive: false },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: true },
            ],
            modal: false,
            subscribemodal: false,
        }

        this.togglemodal.bind(this)
        this.togglesubscribemodal.bind(this)
    }

    componentDidMount() {
        setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    }

    togglemodal = () => {
        this.setState(prevState => ({
          modal: !prevState.modal,
        }))
    }

    togglesubscribemodal = () => {
        this.setState(prevState => ({
          subscribemodal: !prevState.subscribemodal,
        }))
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Dashboard</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Home')} breadcrumbItem={this.props.t('Dashboard')} />
                        <Row>
                            <Col>
                                <GenerateReport />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4">
                                <MonthlyEarning />
                                <ActivityComp />
                            </Col>
                            <Col xl="8">
                                <Row>
                                  {this.state.reports.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                      <Card className="mini-stats-wid">
                                        <CardBody>
                                          <Media>
                                            <Media body>
                                              <p className="text-muted fw-medium">
                                                {report.title}
                                              </p>
                                              <h4 className="mb-0">{report.description}</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                              <span className="avatar-title">
                                                <i
                                                  className={
                                                    "bx " + report.iconClass + " font-size-24"
                                                  }
                                                />
                                              </span>
                                            </div>
                                          </Media>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))}
                                </Row>
                                <Card>
                                    <CardBody>
                                        <div className="d-sm-flex flex-wrap">
                                         <CardTitle className="card-title mb-4 h4">Report Generated</CardTitle>
                                         <div className="ms-auto">
                                            <ul className="nav nav-pills">
                                                {this.state.email.map((mail, key) => (
                                                    <li className="nav-item" key={"_li_" + key}>
                                                        <Link className={
                                                            mail.isActive ? "nav-link active" : "nav-link"
                                                        } to={mail.linkto} >
                                                        {mail.title}
                                                        </Link>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        <div className="clearfix" />
                                        <StackedColumnChart />
                                    </CardBody>
                               </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(index)
