// @flow
import jwtDecode from 'jwt-decode'
import {getPropTraverse, pluckGatedRouteFromPath, pluckRouteFromName} from './util'

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user      = getLoggedInUser(),
        jwtToken    = getJWTToken(),
        decoded     = (jwtToken !== false) ? jwtDecode(jwtToken) : null,
        currentTime = Date.now() / 1000
    let isAuthenticated = true

    if ((pluckGatedRouteFromPath(location.pathname) != '#') && ((decoded != null && decoded.exp < currentTime && !user) ||
        (decoded != null && decoded.exp < currentTime) ||
        (decoded != null && !user) ||
        (decoded == null))) {

        unsetSession()
        isAuthenticated = false;
    }

    return isAuthenticated;
}

const isSuperAdmin = () => {
    const user = getLoggedInUser()
    let roles = [], isSuperAdmin = false

    if (getPropTraverse(user, ['roles']) != null) {
        roles = getPropTraverse(user, ['roles'])
        Object.entries(roles).map(([k, v]) => {
            if (v.role_name == 'super-admin') {
                isSuperAdmin = true
            }
        })
    }

    return isSuperAdmin
}

const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    return (user != null && user != '') ? JSON.parse(user) : false;
}

const getJWTToken = () => {
    let jwt = localStorage.getItem('jwt');
    return (jwt != null && jwt != '') ? jwt : false;
}

const setLoggedInUser = (data) => {
    localStorage.setItem('authUser', data);
}

const setJWTToken = (data) => {
    localStorage.setItem('jwt', data);
}

const unsetLoggedInUser = () => {
    localStorage.removeItem('authUser');
}

const unsetJWTToken = () => {
    localStorage.removeItem('jwt');
}

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (response) => {
    const userData = getPropTraverse(response, ['response', 'data', 'userData', 0]),
        token    = getPropTraverse(response, ['response', 'data', 'token']);

    setLoggedInUser(JSON.stringify(userData));
    setJWTToken(token);
}

const unsetSession = () => {
    unsetLoggedInUser();
    unsetJWTToken();
}

export {
    isUserAuthenticated,
    isSuperAdmin,
    getLoggedInUser,
    getJWTToken,
    setLoggedInUser,
    setJWTToken,
    setSession,
    unsetLoggedInUser,
    unsetJWTToken,
    unsetSession
}