import {
    COMPONENT_DDLB_ON_HANDLE_CHANGE,
    COMPONENT_DDLB_MULTI_ON_HANDLE_CHANGE,
} from './actionTypes'

export const componentDdlbOnChangeAction = (params) => ({
    type: COMPONENT_DDLB_ON_HANDLE_CHANGE,
    payload: params
})

export const componentDdlbMultiOnChangeAction = (params) => ({
    type: COMPONENT_DDLB_MULTI_ON_HANDLE_CHANGE,
    payload: params
})