import { all, fork } from 'redux-saga/effects'

import LayoutSaga from './layout/saga'
import {
    AccountProfileSaga,
    AuthSaga,
    ForgotPasswordSaga,
    PasswordResetSaga,
    RegisterSaga,
    SocialSaga,
    AclRolesSaga,
    AclPermissionsSaga,
    AclUsersSaga,
    PaymentGatewayStripeSaga,
    SubscriptionPlanStripeSaga,
} from '../app.cms.react/store/sagas'
import ReportsSaga from './reports/saga'

export default function* rootSaga() {
    yield all([
        fork(AccountProfileSaga),
        fork(AuthSaga),
        fork(LayoutSaga),
        fork(ForgotPasswordSaga),
        fork(PasswordResetSaga),
        fork(RegisterSaga),
        fork(SocialSaga),
        fork(AclRolesSaga),
        fork(AclPermissionsSaga),
        fork(AclUsersSaga),
        fork(PaymentGatewayStripeSaga),
        fork(SubscriptionPlanStripeSaga),
        fork(ReportsSaga)
    ])
}
