import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import SocialLoginButton from '../SocialLoginButton'
import {facebook, google} from 'app.cms.react/config'
import {socialAuthCallbackAction} from 'app.cms.react/store/actions'

class FormSocialLoginSignup extends Component {
    constructor(props) {
        super(props)

        this.handleSocialLogin = this.handleSocialLogin.bind(this)
        this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this)
    }

    handleSocialLogin(user) {
        this.props.socialAuthCallbackAction(user)
    }

    handleSocialLoginFailure(err) {
        // console.log(err)
    }

    render() {
        return (
            <React.Fragment>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <SocialLoginButton
                            provider='facebook'
                            appId={facebook.APP_ID}
                            type='span'
                            class='social-list-item bg-primary text-white border-primary'
                            onLoginSuccess={this.handleSocialLogin}
                            onLoginFailure={this.handleSocialLoginFailure}
                        >
                            <i className="mdi mdi-facebook"></i>
                        </SocialLoginButton>
                    </li>
                    <li className="list-inline-item">
                        <SocialLoginButton
                            provider='google'
                            appId={google.API_KEY}
                            type='span'
                            class='social-list-item bg-danger text-white border-danger'
                            onLoginSuccess={this.handleSocialLogin}
                            onLoginFailure={this.handleSocialLoginFailure}
                        >
                            <i className="mdi mdi-google"></i>
                        </SocialLoginButton>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
    socialAuthCallbackAction,
})(FormSocialLoginSignup))