import {
    ACL_PERMISSIONS,
    ACL_PERMISSIONS_DATA,
    ACL_PERMISSIONS_UPDATE,
    ACL_PERMISSIONS_ADD,
    ACL_PERMISSIONS_DELETE,
} from './actionTypes'

export const aclPermissionsAction = (params) => ({
    type: ACL_PERMISSIONS,
    payload: { params },
})

export const aclPermissionsDataAction = (data) => ({
    type: ACL_PERMISSIONS_DATA,
    payload: { data },
})

export const aclPermissionsUpdateAction = (params, meta) => ({
    type: ACL_PERMISSIONS_UPDATE,
    payload: { params, meta },
})

export const aclPermissionsAddAction = (params, meta) => ({
    type: ACL_PERMISSIONS_ADD,
    payload: { params, meta },
})

export const aclPermissionsDeleteAction = (params, meta) => ({
    type: ACL_PERMISSIONS_DELETE,
    payload: { params, meta },
})