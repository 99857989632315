import React, {Component} from 'react'
import {connect} from "react-redux";
import { Col, Row } from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class Robots extends Component {
    constructor(props) {
        super(props)

        const sectionData = getPropTraverse(this.props.reportData, ['report', 'technicalSeo']) || []
        let reportUrl     = getPropTraverse(this.props.reportData, ['url'])
        const hasRobot    = getPropTraverse(sectionData, ['robotsExclusionProtocol', 'has_robot']) || false;

        if (reportUrl.endsWith('/')) {
            reportUrl = reportUrl.slice(0, -1)
        }

        this.state = {
            sectionData : sectionData,
            hasRobot    : hasRobot,
            status      : 'warning',
            message     : 'Error: An error occurred while calling the endpoint service',
            reportUrl   : reportUrl,
            robotLinks  : (hasRobot) ? `${reportUrl}/robots.txt` : '',
            hasGoogleConsoleAccess : getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        let reportSummary = this.props.reportSummary
        const robots                 = getPropTraverse(this.props.reportSettings, ['robots']) || this.state.status
        const hasGoogleConsoleAccess = getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true
        let message = 'robotsHasNoRobotDanger'
        let status  = 'danger'

        if (this.state.hasRobot && hasGoogleConsoleAccess) {
            status  = 'success'
            message = 'robotsHasRobotSuccess';
        } else if (this.state.hasRobot && !hasGoogleConsoleAccess) {
            status  = 'warning'
            message = 'robotsHasRobotWarning';
        }

        if (robots != null && robots != this.state.status) {
            status  = robots
            switch (robots) {
                case 'success' :
                    message = 'robotsHasRobotSuccess';
                    break;
                case 'warning' :
                    message = 'robotsHasRobotWarning';
                    break;
                default:
                    message = 'robotsHasNoRobotDanger'
                    break;
            }
        }

        this.setState({
            status  : status,
            message : message,
            hasGoogleConsoleAccess : hasGoogleConsoleAccess
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['robots']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['robots']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['robots']
        }

        reportSummary[status]['robots'] = {
            message,
            links: this.state.robotLinks
        }

        this.props.saveReportSummary(reportSummary)
    }

    componentDidUpdate(prevProps, prevState) {
        const hasGoogleConsoleAccess = getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(prevProps.reportSettings, ['hasGoogleConsoleAccess']) == true
        const robots                 = getPropTraverse(this.props.reportSettings, ['robots']) || null

        if (typeof this.props.reportSettings.robots != 'undefined' && (hasGoogleConsoleAccess != this.state.hasGoogleConsoleAccess || robots != this.state.status)) {
            this.updateReportSummary()
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="robotsSection" className='mt-4'>
                    <h4>{ this.props.t('Robots Exclusion Protocol (Robots.txt)') }</h4>
                    <p>If you have files, directories, or pages that you don’t want indexed by crawlers, you can use a robots.txt file to define where the robots should not go. It's important, however, to make sure that you don't accidentally block the crawler from visiting your website.</p>

                    <Row className='g-0'>
                        <Col xs={6}>
                            <div className='bg-light bg-gradient p-4'>
                                <p><strong>To exclude directories from your site, the syntax should be:</strong></p>
                                <code>
                                    User-agent: *<br/>
                                    Disallow: /[Folder you want excluded] <br />
                                    Sitemap: {this.state.reportUrl}/sitemap.xml
                                </code>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='bg-light bg-gradient p-4'>
                                <p><strong>To allow all robots complete access, the syntax should be:</strong></p>
                                <code>
                                    User-agent: *<br/>
                                    Disallow: <br />
                                    Sitemap: {this.state.reportUrl}/sitemap.xml
                                </code>
                            </div>
                        </Col>
                    </Row>

                    <div className='p-3'>
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                {(this.state.hasRobot) && (
                                    <tr>
                                        <td colSpan={2}>
                                            <a href={this.state.robotLinks} target="_blank">{ this.state.robotLinks }</a>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                    <td dangerouslySetInnerHTML={{__html: this.props.t(this.state.message)}}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings,
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportSettings,
        reportData,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(Robots));
