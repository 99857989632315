import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
    userData: {}
}

const register = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case REGISTER_USER:
            return { ...state }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                userData: payload.params.userData[0]
            }
    }

    return { ...state };
}

export default register