import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {AvForm, AvField} from 'availity-reactstrap-validation'
import {Link} from 'react-router-dom'
import {pluckRouteFromName} from '../../helpers/util'
import FormAlert from '../FormAlert'
import ButtonLoading from '../ButtonLoading'
import FormSocialLoginSignup from './FormSocialLoginSignup'
import { loginUserAction, jwtSessionExpiredAction, componentFormInitAction, componentFormOnSubmitAction } from 'app.cms.react/store/actions'
import {Col, Row} from 'reactstrap'
import PropTypes from 'prop-types'

const FormAlertFragment = (props) => {
    if (props.formSubmit && props.alertType == 'danger') {
        return (
            <FormAlert response={props.response} alertType={props.alertType} validationType='notification'/>
        )
    } else if (props.formSubmit && props.alertType == 'success') {
        return (
            <FormAlert response="Login successful" alertType={props.alertType} validationType='notification' />
        )
    }

    return ''
}

const RenderSocialSignup = (props) => {
    if (typeof props.hasSocialSignup == 'undefined' || props.hasSocialSignup == true) {
        return (
            <React.Fragment>
                <div className="p-2 text-center">
                    Or sign in with
                </div>
                <div className="p-2 text-center">
                    <FormSocialLoginSignup />
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="p-2 text-center"></div>
        </React.Fragment>
    )
}

const RenderSignup = (props) => {
    if (typeof props.hasSignup == 'undefined' || props.hasSignup == true) {
        return (
            <React.Fragment>
                <Row>
                    <Col className="mt-5 text-center">
                        Don't have an account? <Link to={pluckRouteFromName('register')} className="text-primary">Signup now</Link>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    return null
}


class FormLogin extends Component {
    constructor(props) {
        super(props)

        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        this.props.loginUserAction(values, this.props.history)
        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlertFragment {...this.props} formSubmit={this.props.formSubmit} />
                    <div className="mb-3">
                        <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            name="password"
                            label="Password"
                            value=""
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            required
                            disabled={this.props.disableFields}
                        />
                    </div>
                    <div className="mt-3 d-grid">
                        <ButtonLoading
                            formLoading={this.props.formLoading}
                            buttonClassName="btn btn-primary btn-block"
                            buttonName="Login"
                        />
                    </div>
                </AvForm>
                <RenderSocialSignup {...this.props} />
                <div className="pb-2 text-center">
                    <Link to={pluckRouteFromName('forgot-password')} className="text-muted"><i className="bx bx bx-lock" /> Forgot password?</Link>
                </div>
                <RenderSignup {...this.props} />
            </React.Fragment>
        )
    }
}

FormLogin.propTypes = {
    hasSocialSignup: PropTypes.bool,
    hasSignup: PropTypes.bool,
}

const mapStateToProps = state => {
    let {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const jwtSession = {
        alertType: state.JwtSession.alertType,
        response: state.JwtSession.response,
    }

    if (alertType.length == 0) {
        alertType = jwtSession.alertType
        response = jwtSession.response
        formSubmit = true
    }

    return {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        jwtSession,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    loginUserAction,
    jwtSessionExpiredAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(FormLogin))