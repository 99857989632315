import React, {Component} from 'react'
import {connect} from "react-redux";

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import ReportIcon from '../../../../components/Common/ReportIcon'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../../store/reports/actions'

class MetaTitle extends Component {
    constructor(props) {
        super(props)

        const sectionDataForSeo = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePageDataForSeo'])
        let metaTitle           = getPropTraverse(sectionDataForSeo, ['meta', 'title']) || ''
        let canonical           = getPropTraverse(sectionDataForSeo, ['meta', 'canonical']) || ''
        let reportUrl           = getPropTraverse(this.props.reportData, ['url'])

        if (canonical == '') {
            canonical = reportUrl
        }

        this.state = {
            sectionData     : getPropTraverse(sectionDataForSeo, ['meta', 'title']) || false,
            canonical       : canonical,
            metaTitle       : metaTitle,
            metaTitleLength : metaTitle.length,
            status          : 'warning',
            message         : 'Error: An error occurred while calling the endpoint service'
        }
    }

    componentDidMount() {
        let reportSummary = this.props.reportSummary

        if (this.state.sectionData) {
            let status  = 'danger'
            let message = 'Not good! Your page is missing the meta title element.'

            if (this.state.metaTitleLength > 0) {
                if (this.state.metaTitleLength >= 10 && this.state.metaTitleLength <= 70) {
                    status  = 'success'
                    message = 'metaTitleGood'
                } else if (this.state.metaTitleLength < 10) {
                    status  = 'warning'
                    message = 'metaTileWarning'
                } else {
                    message = 'Not good! Your page meta title is longer than 70 characters.'
                }
            }

            this.setState({
                status  : status,
                message : message
            })

            if (typeof reportSummary[status] == 'undefined') {
                reportSummary[status] = { meta_title : message }
            } else {
                reportSummary[status]['meta_title'] = message
            }

            this.props.saveReportSummary(reportSummary)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="metaTitleSection" className='mt-4'>
                    <h4>{ this.props.t('Meta Title') }</h4>
                    <p>{ this.props.t('Meta title is a strong relevancy signal for search engines because it tells them what the page is about. The most effective page titles are about 10-70 characters long, including spaces.') }</p>

                    { this.state.sectionData ? (
                    <div className='p-3 mb-5'>
                        <h5>{ this.props.t('Homepage') }:</h5>

                        { this.state.metaTitleLength > 0 ? (
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className='text-center'>{ this.props.t('URL') }</th>
                                        <th>{ this.state.canonical }</th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>{ this.props.t('Char') }.</th>
                                        <th>{ this.props.t('Meta Title') }</th>
                                    </tr>

                                    <tr>
                                        <th className='text-center'>{ this.state.metaTitleLength }</th>
                                        <td>{ this.state.metaTitle }</td>
                                    </tr>
                                    <tr>
                                        <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                        <td>{ this.props.t(this.state.message) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className='table mb-0 table-bordered align-middle'>
                                <tbody>
                                    <tr>
                                        <th className='text-center fw-50'><ReportIcon type={ this.state.status } /></th>
                                        <td>{ this.props.t(this.state.message) }</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>)
                    : (
                        <table className='table mt-4 mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className='text-center fw-50'><ReportIcon type="warning" /></th>
                                    <td>{ this.props.t('Error: An error occurred while calling the endpoint service') }</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(MetaTitle))
