import PropTypes            from 'prop-types'
import {connect}            from 'react-redux';
import React, { Component } from 'react'
import { Alert, Card, CardBody, CardTitle, CardText } from 'reactstrap'
//i18n
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSettings, saveReportAction } from '../../../store/reports/actions'

class TechnicalSeoSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            xmlSitemap               : false,
            robots                   : false,
            gaIsInstalled            : false,
            gcsIsInstalled           : false,
            hasGoogleAnalyticsAccess : false,
            hasGoogleConsoleAccess   : false
        }

        this.updateGoogleAnalyticsSettings          = this.updateGoogleAnalyticsSettings.bind(this)
        this.updateGoogleConsoleSettings            = this.updateGoogleConsoleSettings.bind(this)
        this.updateGoogleAnalyticsInstalledSettings = this.updateGoogleAnalyticsInstalledSettings.bind(this);
        this.updateGoogleConsoleInstalledSettings   = this.updateGoogleConsoleInstalledSettings.bind(this);
        this.updateTechnicalSeoSettings             = this.updateTechnicalSeoSettings.bind(this)
    }

    updateTechnicalSeoSettings(meta, value) {
        const reportSettings = this.props.reportSettings
        const data           = this.state
        let reportData       = {}

        this.setState({ ...data, [meta]: value})
        reportSettings[meta] = value

        reportData = reportSettings;

        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    updateGoogleAnalyticsSettings(value) {
        const reportSettings = this.props.reportSettings
        this.setState({
            hasGoogleAnalyticsAccess : value
        })

        reportSettings.hasGoogleAnalyticsAccess  = value
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportSettings, token: this.props.token})
    }

    updateGoogleAnalyticsInstalledSettings(value) {
        const reportSettings = this.props.reportSettings
        let reportData       = {}
        const updateStateParam = {
            gaIsInstalled            : value,
            googleAnalyticsInstalled : value
        };

        if (!value) {
            updateStateParam['hasGoogleAnalyticsAccess'] = false;
            reportSettings.hasGoogleAnalyticsAccess = false;
        }

        this.setState(updateStateParam)

        reportSettings.googleAnalyticsInstalled  = value;

        reportData = reportSettings;
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    componentDidMount() {
        this.updateSectionData()
    }

    updateSectionData() {
        const reportSettings = this.props.reportSettings;
        let gaIsInstalled  = getPropTraverse(this.props.reportData, ['report', 'technicalSeo', 'googleAnalytics', 'code']) ? true : false
        let gcsIsInstalled = getPropTraverse(this.props.reportData, ['report', 'technicalSeo', 'googleSearchConsole', 'code']) ? true : false
        let xmlSitemap     = getPropTraverse(reportSettings, ['xmlSitemap']) || '';
        let robots         = getPropTraverse(reportSettings, ['robots']) || '';

        if (typeof reportSettings.googleAnalyticsInstalled !== 'undefined') {
            gaIsInstalled = reportSettings.googleAnalyticsInstalled;
        }

        if (typeof reportSettings.googleSearchConsoleInstalled !== 'undefined') {
            gcsIsInstalled = reportSettings.googleSearchConsoleInstalled;
        }

        if (xmlSitemap == '') {
            xmlSitemap = (getPropTraverse(this.props.reportData, ['report', 'technicalSeo', 'xmlSiteMap', 'url']) != null && (this.props.reportData, ['report', 'technicalSeo', 'xmlSiteMap', 'url']).length > 0) ? 'success' : 'danger'
        }

        if (robots == '') {
            robots = (getPropTraverse(this.props.reportData, ['report', 'technicalSeo', 'robotsExclusionProtocol', 'has_robot']) == true) ? 'success' : 'danger'
        }

        if (xmlSitemap == 'success' && gcsIsInstalled == false) {
            xmlSitemap = 'warning'
        }

        if (robots == 'success' && gcsIsInstalled == false) {
            robots = 'warning'
        }

        this.setState({
            xmlSitemap               : xmlSitemap,
            robots                   : robots,
            gaIsInstalled            : gaIsInstalled,
            gcsIsInstalled           : gcsIsInstalled,
            hasGoogleAnalyticsAccess : gaIsInstalled && (getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleAnalyticsAccess']) == true),
            hasGoogleConsoleAccess   : gcsIsInstalled && (getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == 'true' || getPropTraverse(this.props.reportSettings, ['hasGoogleConsoleAccess']) == true)
        })
    }

    updateGoogleConsoleInstalledSettings(value) {
        const reportSettings = this.props.reportSettings
        let reportData       = {}
        const updateStateParam = {
            gcsIsInstalled               : value,
            googleSearchConsoleInstalled : value
        };

        if (!value) {
            updateStateParam['hasGoogleConsoleAccess'] = false;
            reportSettings.hasGoogleConsoleAccess = false;
        }

        this.setState(updateStateParam);

        reportSettings.googleSearchConsoleInstalled  = value;

        reportData = reportSettings;
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportData, token: this.props.token})
    }

    updateGoogleConsoleSettings(value) {
        const reportSettings = this.props.reportSettings

        this.setState({
            hasGoogleConsoleAccess : value
        })

        reportSettings.hasGoogleConsoleAccess = value
        this.props.saveReportSettings()
        this.props.saveReportAction({ ...reportSettings, token: this.props.token})
    }

    render() {
        const gaIsInstalled  = (this.state.gaIsInstalled != '' && (this.state.gaIsInstalled == 'true' || this.state.gaIsInstalled == true))
        const gcsIsInstalled = (this.state.gcsIsInstalled != '' && (this.state.gcsIsInstalled == 'true' || this.state.gcsIsInstalled == true))

        return (
            <React.Fragment>
                <div className="accordion-body report-section-settings">
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Google Analytics')}</CardTitle>

                            <CardText>{ this.props.t('Does the page has Google Analytics installed?') }</CardText>
                            <CardText className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasGoogleAnalyticsInstalled"
                                    switch="bool"
                                    checked={ gaIsInstalled }
                                    onChange={() =>
                                        this.updateGoogleAnalyticsInstalledSettings(!gaIsInstalled)
                                    }
                                />
                                <label
                                    htmlFor="hasGoogleAnalyticsInstalled"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </CardText>

                            <CardText>{ this.props.t('Were you able to have full access to the Google Analytics Account?') }</CardText>
                            { !gaIsInstalled && (
                                <Alert color="danger" role="alert">
                                    { this.props.t('No Google Analytics tag found') }
                                </Alert>
                            )}

                            <CardText className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasGoogleAnalyticsAccess"
                                    switch="bool"
                                    disabled={!gaIsInstalled }
                                    checked={ this.state.hasGoogleAnalyticsAccess }
                                    onChange={() =>
                                        this.updateGoogleAnalyticsSettings(!this.state.hasGoogleAnalyticsAccess )
                                    }
                                />
                                <label
                                    htmlFor="hasGoogleAnalyticsAccess"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    />
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Google Search Console')}</CardTitle>
                            <CardText>{ this.props.t('Does the page has Google Search Console tag?') }</CardText>
                            <CardText className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasGoogleConsoleInstalled"
                                    switch="bool"
                                    checked={ gcsIsInstalled }
                                    onChange={() =>
                                        this.updateGoogleConsoleInstalledSettings(!gcsIsInstalled)
                                    }
                                />
                                <label
                                    htmlFor="hasGoogleConsoleInstalled"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                />
                            </CardText>

                            <CardText>{ this.props.t('Were you able to have full access to the Google Search Console Account?') }</CardText>
                            { !gcsIsInstalled && (
                                <Alert color="danger" role="alert">
                                    { this.props.t('No Google Search Console tag found') }
                                </Alert>
                            )}
                            <CardText className="square-switch">
                                <input
                                    type="checkbox"
                                    id="hasGoogleConsoleAccess"
                                    switch="bool"
                                    disabled={ !gcsIsInstalled }
                                    checked={ this.state.hasGoogleConsoleAccess }
                                    onChange={() =>
                                        this.updateGoogleConsoleSettings(!this.state.hasGoogleConsoleAccess )
                                    }
                                />
                                <label
                                    htmlFor="hasGoogleConsoleAccess"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    />
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('XML Sitemap')}</CardTitle>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="successXmlSitemap"
                                    checked={ Boolean(this.state.xmlSitemap == 'success') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('xmlSitemap', 'success')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="successXmlSitemap"
                                    >
                                    { this.props.t('Low') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="warningXmlSitemap"
                                    checked={ Boolean(this.state.xmlSitemap == 'warning') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('xmlSitemap', 'warning')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="warningXmlSitemap"
                                    >
                                    { this.props.t('Medium') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="dangerXmlSitemap"
                                    checked={ Boolean(this.state.xmlSitemap == 'danger') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('xmlSitemap','danger')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="dangerXmlSitemap"
                                    >
                                    { this.props.t('High') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle className="h5">{ this.props.t('Robots.txt')}</CardTitle>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="successRobots"
                                    checked={ Boolean(this.state.robots == 'success') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('robots', 'success')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="successRobots"
                                    >
                                    { this.props.t('Low') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="warningRobots"
                                    checked={ Boolean(this.state.robots == 'warning') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('robots', 'warning')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="warningRobots"
                                    >
                                    { this.props.t('Medium') }
                                </label>
                            </CardText>
                            <CardText className="form-check form-check-outline form-check-primary mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="dangerRobots"
                                    checked={ Boolean(this.state.robots == 'danger') }
                                    onChange={e => {
                                        this.updateTechnicalSeoSettings('robots','danger')
                                    }}
                                    />
                                <label
                                    className="form-check-label"
                                    htmlFor="dangerRobots"
                                    >
                                    { this.props.t('High') }
                                </label>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        )
    }
}

TechnicalSeoSettings.propTypes = {
    token: PropTypes.any
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}

export default withTranslation()(connect(mapStateToProps, {
    saveReportSettings,
    saveReportAction
})(TechnicalSeoSettings))
