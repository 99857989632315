import React, {Component} from 'react'
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import FormAlert from '../../FormAlert'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import {Row, Col} from 'reactstrap'
import {componentFormInitAction, componentFormOnSubmitAction, accountProfileAction, accountProfileUpdateAction} from 'app.cms.react/store/actions'
import ButtonLoading from '../../ButtonLoading'

class FormProfile extends Component {
    constructor(props) {
        super(props)

        this.props.accountProfileAction()
        this.props.componentFormInitAction()
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        this.props.accountProfileUpdateAction({
            id: this.props.data.id,
            first_name: values.first_name,
            last_name: values.last_name,
            company_name: values.company_name,
        }, this.props.history)

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType='notification' />
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="first_name"
                                value={this.props.data.first_name}
                                label="First Name"
                                className="form-control"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 11}}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="last_name"
                                value={this.props.data.last_name}
                                label="Last Name"
                                className="form-control"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 11}}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <div className="row form-group">
                                <label className="col-md-1 col-form-label">Email</label>
                                <div className="col-md-10">
                                    <label className="col-md-2 col-form-label">{this.props.data.email}</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <AvField
                                name="company_name"
                                value={this.props.data.company_name}
                                label="Company Name"
                                className="form-control"
                                type="text"
                                required
                                disabled={this.props.disableFields}
                                grid={{md: 11}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonLoading formLoading={this.props.formLoading} />
                        </Col>
                    </Row>
                </AvForm>
            </React.Fragment>
        )
    }
}

FormProfile.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        response,
        alertType,
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        data
    } = state.AccountProfile

    return {
        data,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    accountProfileAction,
    accountProfileUpdateAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(FormProfile))