import {
    PASSWORD_RESET,
} from './actionTypes'

const INIT_STATE = {}

const passwordReset = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case PASSWORD_RESET:
            return { ...state }
    }

    return { ...state };
}

export default passwordReset