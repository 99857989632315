import React, {Component}      from 'react'
import {connect}               from "react-redux";
import {Card, Col, Container, Row} from 'reactstrap'
import { Element }             from 'react-scroll'
import Cover                   from '../../components/Report/Sections/Cover'
import TableOfContent          from '../../components/Report/Sections/TableOfContent'
import IndexingAndCrawlability from '../../components/Report/Sections/IndexingAndCrawlability/Main'
import TechnicalSeo            from '../../components/Report/Sections/TechnicalSeo/Main'
import OnPageSeo               from '../../components/Report/Sections/OnPageSeo/Main'
import OffPageSeo              from '../../components/Report/Sections/OffPageSeo/Main'
import ReputationSignals       from '../../components/Report/Sections/ReputationSignals/Main'
import SchemaMarkup            from '../../components/Report/Sections/SchemaMarkup'
import Summary                 from '../../components/Report/Sections/Summary'
import Recommendations         from '../../components/Report/Sections/Recommendations'
import { getPropTraverse }     from '../../app.cms.react/helpers/util'
import { withTranslation }     from "react-i18next"


class Report extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const websiteOptimization = getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == 'true' || getPropTraverse(this.props.reportSettings, ['websiteOptimization']) == true
        const organicSeo          = getPropTraverse(this.props.reportSettings, ['organicSeo']) == 'true' || getPropTraverse(this.props.reportSettings, ['organicSeo']) == true
        const pageBreak = {
            pageBreakAfter: 'always'
        }
        return (
            <React.Fragment>
                <Element name="cover">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <Cover />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="summarySection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <Summary />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="indexingAndCrawlabilitySection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <IndexingAndCrawlability />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="technicalSeoSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <TechnicalSeo />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="onPageSeoSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <OnPageSeo />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="offPageSeoSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <OffPageSeo />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="reputationSignalsSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <ReputationSignals />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                <Element name="schemaMarkupSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <SchemaMarkup />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>

                { (websiteOptimization || organicSeo) && (
                <Element name="recommendationSection">
                    <Container className='p-0'>
                        <Card>
                            <Row className='g-0'>
                                <Col xl={12}>
                                    <div className='p-md-5 p-4'>
                                        <div className='w-100'>
                                            <Recommendations />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Element>) }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSettings
    } = state.Reports

    return {
        reportSettings
    }
}

export default withTranslation()(connect(mapStateToProps)(Report))
