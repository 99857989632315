import React, {Component} from 'react'
import {connect} from "react-redux";
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import ReportIcon          from '../../Common/ReportIcon'
import SummaryStatus from "./Summary/Status";


class Summary extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const reportSummary = this.props.reportSummary || []
        const danger        = getPropTraverse(reportSummary, ['danger']) || {}
        const warning       = getPropTraverse(reportSummary, ['warning']) || {}
        const success       = getPropTraverse(reportSummary, ['success']) || {}

        return (
            <React.Fragment>
                <React.Fragment>
                    <div className='mb-4 mb-md-5'>
                        <h2 className='mb-0'>{ this.props.t('Summary') }</h2>
                    </div>

                    { Object.keys(danger).length > 0 && (
                        <SummaryStatus key={'critical'} title={ this.props.t('Critical') } items={danger} icon="danger"/>
                    )}

                    { Object.keys(warning).length > 0 && (
                        <SummaryStatus key={'warning'} title={ this.props.t('Caution Areas') } items={warning} icon="warning"/>
                    )}

                    { Object.keys(success).length > 0 && (
                        <SummaryStatus key={'success'} title={ this.props.t('Looking Good') } items={success} icon="success"/>
                    )}

                </React.Fragment>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportSummary
    } = state.Reports

    return {
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps)(Summary))
