import React, {Component} from 'react'
import {connect} from "react-redux";
import sanitizeHtml from 'sanitize-html';

import MetaTags from 'react-meta-tags';
import {Link} from 'react-router-dom'
import {Card, CardBody, Button, Col, Container, Row, Spinner} from 'reactstrap'
import { Element } from 'react-scroll'
import { withTranslation } from "react-i18next"
import { getPropTraverse } from '../../../app.cms.react/helpers/util'
import { saveReportSummary } from '../../../store/reports/actions'
import ReportIcon from '../../Common/ReportIcon'
import { isEqual } from 'lodash'


class SchemaMarkup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasScrapedData : null,
            message        : null,
            settingSchema  : null,
            settingCount   : null
        }
    }

    componentDidUpdate() {
        const setting = getPropTraverse(this.props, ['reportSettings', 'jsonLdSchema']) || '';

        if (this.state.settingSchema != null && isEqual(this.state.settingSchema, setting)) {
            this.updateReportSummary()
        }
    }

    componentDidMount() {
        this.updateReportSummary()
    }

    updateReportSummary() {
        const sectionData = getPropTraverse(this.props.reportData, ['report', 'onPageSeo', 'sitePage', 'schema']) || false
        const setting     = getPropTraverse(this.props, ['reportSettings', 'jsonLdSchema']) || ''
        let reportSummary = this.props.reportSummary
        let status        = 'danger'
        let message       = 'Error: LocalBusiness schema not found'
        let settingSchema = getPropTraverse(sectionData, ['jsonld', 'item']) || []
        let settingCount  = getPropTraverse(sectionData, ['jsonld', 'count']) || 0

        if (setting && setting !== '') {
            settingSchema = JSON.parse(setting);
            settingCount  = settingSchema.length;
        }

        if (settingCount > 0) {
            status  = 'success'
            message = 'LocalBusiness schema found'
        }

        this.setState({
            hasScrapedData : (Object.keys(sectionData).length),
            message        : message,
            settingCount   : settingCount,
            settingSchema  : settingSchema
        })

        // remove from summary first
        if (typeof reportSummary['success'] != 'undefined') {
            delete reportSummary['success']['json_schema']
        }
        if (typeof reportSummary['warning'] != 'undefined') {
            delete reportSummary['warning']['json_schema']
        }
        if (typeof reportSummary['danger'] != 'undefined') {
            delete reportSummary['danger']['json_schema']
        }
       
        if (typeof reportSummary[status] == 'undefined') {
            reportSummary[status] = { json_schema : message }
        } else {
            reportSummary[status]['json_schema'] = message
        }

        this.props.saveReportSummary(reportSummary)
    }

    render() {
        return (
            <React.Fragment>
                <div className='mb-4 mb-md-5'>
                    <h2 className='mb-0'>{ this.props.t('JSON-LD Schema Markup') }</h2>
                </div>
                <div className='mt-4'>
                    <h4>{ this.props.t('Site-wide Schema Integration') }</h4>
                    <p>{ this.props.t('JavaScript Object Notation for Linked Data (JSON-LD) is the newest and simplest markup format: it allows embedding a block of JSON data inside a script tag anywhere in the HTML. JSON-LD is supported for all Knowledge Graph features and sitelink search boxes.') }</p>
                    { this.state.settingCount > 0 ? (
                        <div>
                        <p>We recommend a LocalBusiness schema below</p>
                        { this.state.settingSchema.map( (list, key) => (
                            <div key={key} className='bg-light bg-gradient p-3 mb-3 text-muted'>
                                &lt;script type=&quot;application/ld+json&quot;&gt; <br/>
                                <pre id="schemaMarkupPre">
                                    <div dangerouslySetInnerHTML={ {
                                        __html: sanitizeHtml(JSON.stringify(list, null, 4).replaceAll('@', '<span>@</span>'))
                                        }
                                    } />
                                </pre>
                                &lt;/script&gt;
                            </div>
                        ))}
                        </div>
                    ) : (
                        <table className='table mb-0 table-bordered align-middle'>
                            <tbody>
                                <tr>
                                    <th className="text-center fw-50"><ReportIcon type="danger" /></th>
                                    <td>{ this.state.message }</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {
        reportData,
        reportSettings,
        reportSummary
    } = state.Reports

    return {
        reportData,
        reportSettings,
        reportSummary
    }
}
export default withTranslation()(connect(mapStateToProps, {
    saveReportSummary
})(SchemaMarkup))
