import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
} from './actionTypes'

export const registerUserAction = (params, meta) => ({
    type: REGISTER_USER,
    payload: { params, meta },
})

export const registerUserSuccessAction = (params, meta) => ({
    type: REGISTER_USER_SUCCESS,
    payload: { params, meta },
})